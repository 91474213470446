import React, { useCallback, useEffect } from 'react'
import TikTokFIlters from '../TikTokFIlters/TikTokFIlters'
import TikTokNavbar from '../TikTokNavbar'
import { useDispatch, useSelector } from 'react-redux';
import { tiktokUserActions, fetchExcel, fetchSubcultures, fetchTags, fetchTikTokUsers, fetchTikTokUsersCount } from '../../../redux/tiktok/users/actions';
import TikTokUsersItem from './TikTokUsersItem';
import Pagination from '../../Pagination/Pagination';
import { RootStateType } from '../../../redux/reducers';
import _ from 'lodash';

const { clearFilters, setKeyword, setSubculture, setTag, setTikTokUsersLoading, setTikTokUsersPage, setTikTokUsersPerPage } = tiktokUserActions

const TikTokUsers: React.FC = () => {
    const dispatch = useDispatch();
    const { loaded, loading, users, filters, count, excel, subcultures, tags } = useSelector((state: RootStateType) => state.tikTokUsersReducer);
    const { subculture, tag, keyword } = filters;
    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setTikTokUsersPage(1))
        dispatch(fetchTikTokUsers());
        dispatch(fetchTikTokUsersCount());
        dispatch(fetchExcel());
    }, 500), [])

    const handlerApplyFilters = (): void => {
        dispatch(setTikTokUsersPage(1))
        dispatch(fetchTikTokUsers());
        dispatch(fetchTikTokUsersCount());
        dispatch(fetchExcel());
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
    }

    const onChangePage = (page: number): void => {
        dispatch(setTikTokUsersPage(page));
        dispatch(fetchTikTokUsers());
    }

    const handlerChangeUsersPerPage = (usersPerPage: number): void => {
        dispatch(setTikTokUsersPerPage(usersPerPage));
        dispatch(setTikTokUsersPage(1));
        dispatch(fetchTikTokUsers());
        dispatch(fetchTikTokUsersCount());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(fetchTikTokUsers());
            dispatch(fetchTikTokUsersCount());
            dispatch(setTikTokUsersLoading(true));
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
        }
    });

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <TikTokNavbar />
                    <TikTokFIlters tag={tag} keyword={keyword} setSubculture={(subculture) => dispatch(setSubculture(subculture))}
                        setTag={(tag) => dispatch(setTag(tag))} subculture={subculture} setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters}
                        clearFilters={handlerClearFilters} excel={excel} subcultures={subcultures} tags={tags} />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content active" data-name="users">
                            <table className="tbl tiktok-users-tbl">
                                <tr className="tbl__row_header">
                                    <th className="tbl__header_cell">Никнейм</th>
                                    <th className="tbl__header_cell" hide-order="3">Кол-во подписчиков</th>
                                    <th className="tbl__header_cell" hide-order="2">Кол-во подписок</th>
                                    <th className="tbl__header_cell" hide-order="2">Кол-во просмотров</th>
                                    <th className="tbl__header_cell" hide-order="1">Субкультура</th>
                                    <th className="tbl__header_cell" hide-order="1">Теги</th>
                                    <th className="tbl__header_cell" hide-order="2">Проверено администратором</th>
                                    <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                </tr>
                                {users.map((item, index) => <TikTokUsersItem data={item} key={index} />)}
                            </table>
                        </div>
                    </div>
                    <Pagination count={count} itemsPerPage={filters.usersPerPage} activePage={filters.page} handlerChangeItemsPerPage={handlerChangeUsersPerPage} onChangePage={onChangePage} />
                </div>

            </div>
        </div>
    )
}

export default TikTokUsers
