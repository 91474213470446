import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useRef } from "react";
import { fetchAccountInfo, accountActions, setLastVisit } from "./redux/app/account/actions";
import AppNavBar from "./components/AppNavBar/AppNavBar";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useLocation } from "react-router";
import Registration from "./components/Registration/Registration";
import AppFooter from "./components/AppFooter/AppFooter";
import GeneralPage from "./components/GeneralPage/GeneralPage";
import EditorPanel from "./components/EditorPanel/EditorPanel";
import $ from "jquery";
import VkTab from "./components/VK/VkTab/VkTab";
import InstTab from "./components/Inst/InstTab/InstTab";
import Images from "./components/Images/Images/Images";
import TelegramTab from "./components/Telegram/TelegramTab/TelegramTab";
import TikTokTab from "./components/TikTok/TikTokTab/TikTokTab";
import YoutubeTab from "./components/Youtube/YoutubeTab/YoutubeTab";
import SubnTagsTab from "./components/SubnTags/SubnTagsTab/SubnTagsTab";
import EditorUser from "./components/EditorPanel/EditorUser/EditorUser";
import { RootStateType } from "./redux/reducers";
import Sidepanel from "./components/Sidepanel/Sidepanel";
import { sidepanelActions } from './redux/sidepanel/actions';
import classNames from "classnames";
import { useToggleSidepanel } from "./hooks/useToggleSidepanel";

const { setAuthorized, setAuthToken, setLoaded } = accountActions

function getCookie(name: string): string {
    let matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line no-useless-escape
        "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return decodeURIComponent(matches![1]);
}

function tableAdapt() {
    $('.tbl').each(function () {
        $(this).find(".tbl__header_cell").removeClass("d-none");
        $(this).find(".tbl__cell").removeClass("d-none");
        $(this).find(".tbl__row-mob").remove();
        $(this).find('.showmore-mob').css('display', 'none');
        // @ts-ignore
        while ($(this).width() > $(this).parent().width()) {
            var order = $(this).find('.tbl__header_cell[hide-order]').not('.d-none').map(function () {
                return $(this).attr("hide-order");
            }).get();
            if (order.length == 0) break;
            // @ts-ignore
            order = Math.min.apply(Math, order);

            let cols = $(this).find('.tbl__header_cell').not('.d-none').length;
            if (cols <= 1) break;
            // @ts-ignore

            var headers = [];
            // @ts-ignore
            var cells = [];
            let lastTh = $(this).find('.tbl__header_cell[hide-order=' + order + ']').not('.d-none');
            lastTh.each(function (index) {
                headers[index] = $(this).html();
            });
            lastTh.addClass('d-none');

            $(this).find('.tbl__row').each(function () {
                let lastCell = $(this).children('.tbl__cell[hide-order=' + order + ']').not('.d-none');

                lastCell.each(function (index) {
                    cells[index] = $(this).html();
                });
                lastCell.addClass('d-none');
                if (!$(this).next().hasClass('tbl__row-mob')) {
                    let html = '<tr class="tbl__row-mob"><td colspan="99"><div class="tbl__cell-mob"></div></td></tr>';
                    $(this).after(html);
                }
                let mobCell = $(this).next('.tbl__row-mob').find('.tbl__cell-mob');
                let html = '';
                for (let i = 0; i < headers.length; i++) {
                    // @ts-ignore
                    html = '<div class="cell-mob__outer"><div class="cell-mob__header">' + headers[i] + '</div><div class="cell-mob__content">' + (cells[i] == '' ? '-' : cells[i]) + '</div></div>';
                    mobCell.append(html);
                }
            });


            $(this).find('.showmore-mob').css('display', 'table-cell');

        }
    });
}


const AppContainer = () => {
    const dispatch = useDispatch();
    const authorized = useSelector((state: RootStateType) => state.accountReducer.authorized);
    const role = useSelector((state: RootStateType) => state.accountReducer.role);
    const loaded = useSelector((state: RootStateType) => state.accountReducer.loaded);
    const { isSidepanelActive, toggleTimer } = useSelector((state: RootStateType) => state.sidepanelReducer)
    const toggleSidepanel = useToggleSidepanel()

    const sidepanelRef = useRef<HTMLDivElement>(null)
    const isSidepanelActiveRef = useRef<boolean>()
    const location = useLocation()
    console.log(location)

    useEffect(() => {
        isSidepanelActiveRef.current = isSidepanelActive
    })

    useEffect(() => {
        const documentClickHandler = (e: MouseEvent) => {
            if (!isSidepanelActiveRef.current || !sidepanelRef.current) {
                return
            }

            if (!e.composedPath().includes(sidepanelRef.current)) {
                toggleSidepanel(false, null)
            }
        }

        document.addEventListener("click", documentClickHandler)

        return () => {
            document.removeEventListener("click", documentClickHandler)
        }
    }, [])

    useEffect(() => {
        if (document.cookie.includes("jwt") && !loaded) {
            dispatch(setAuthToken(getCookie("jwt")))
            dispatch(setAuthorized(true));
            dispatch(setLoaded(true));
            dispatch(fetchAccountInfo());

            window.addEventListener("beforeunload", () => {
                dispatch(setLastVisit(+new Date()));
            });
        }
    })

    useEffect(() => {
        tableAdapt();

        $(window).resize(tableAdapt);
        $(document).ready(tableAdapt);

        return () => {
            // @ts-ignore
            $(window).off("resize", window, tableAdapt);
            // @ts-ignore
            $(document).off("ready", document, tableAdapt);
        }
    });

    if (document.cookie.includes("jwt") && !loaded) {
        return (
            <></>
        )
    }

    return (
        <>
            <AppNavBar />
            <section className={classNames("page side-padding-main", { "page_white": location.pathname !== "/registration" })}>
                <div className="container-fluid px-0">
                    <Sidepanel ref={sidepanelRef} />
                    <Switch>
                        <Route exact path="/">
                            {authorized ? <Redirect to="/vk" /> : <GeneralPage />}
                        </Route>
                        <Route path="/vk">
                            {authorized ? <VkTab /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/inst">
                            {authorized ? <InstTab /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/registration">
                            {!authorized ? <Registration /> : <Redirect to="/vk" />}
                        </Route>
                        <Route path="/images">
                            {authorized ? <Images /> : <Redirect to="/" />}
                        </Route>
                        <Route exact path="/editor">
                            {authorized && ["ROLE_EDITOR", "ROLE_ADMIN"].includes(role) ? <EditorPanel /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/editor/:id">
                            {authorized ? <EditorUser /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/telegram">
                            {authorized ? <TelegramTab /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/tik-tok">
                            {authorized ? <TikTokTab /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/youtube">
                            {authorized ? <YoutubeTab /> : <Redirect to="/" />}
                        </Route>
                        <Route path="/subntags">
                            {authorized ? <SubnTagsTab /> : <Redirect to="/" />}
                        </Route>
                    </Switch>
                </div>
            </section>
            <AppFooter />
        </>
    )
}

export default AppContainer