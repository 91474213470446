import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/reducers';
import { subcultureActions, fetchFiltersSubcultures, saveSubculture } from '../../redux/subcultures/actions';

const { setNewSubcultureAbout, setNewSubcultureName } = subcultureActions

const SubculturePanel: React.FC = () => {
    const { subculture, about } = useSelector((state: RootStateType) => state.subculturesReducer.newSubculture);
    const dispatch = useDispatch();
    return (
        <div className="sidepanel__items_item d-block" data-item="form-add-subculture">
            <h3 className="sidepanel_header">Добавление субкультуры</h3>
            <form action="" className="form" onSubmit={e => {
                e.preventDefault()
                return false
            }}>
                <div className="form__block mb-5">
                    <div className="form__block_label">Название</div>
                    <input type="text" className="form__block_input" placeholder="Введите субкультуру" value={subculture} onChange={e => dispatch(setNewSubcultureName(e.target.value))} />
                </div>
                <div className="form__block mb-5">
                    <div className="form__block_label">Описание</div>
                    <textarea className="form__block_textarea" placeholder="Введите описание" value={about} onChange={e => dispatch(setNewSubcultureAbout(e.target.value))}></textarea>
                </div>
                <div className="form__block">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <button type="submit" className="button button_darkgreen button_fwidth" onClick={() => {
                                if (subculture.length == 0) {
                                    return false;
                                }
                                dispatch(saveSubculture())
                                dispatch(fetchFiltersSubcultures())
                            }}>Добавить</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SubculturePanel
