import React from 'react';
import { Link } from 'react-router-dom';
import { useToggleSidepanel } from '../../hooks/useToggleSidepanel';
import screenImg from "./../../assets/images/screen.jpg";

const GeneralPage: React.FC = () => {
    const toggleSidepanel = useToggleSidepanel()

    return (
        <div className="row align-items-center no-gutters">
            <div className="col-12 col-md-7 col-lg-6 mt-5 mb-5">
                <h1 className="h h_large mb-5">
                    Деструктиву.нет
                </h1>
                <ul className="list mb-5">
                    <li className="list__item list-item-checked">Система разработана в рамках проекта “Интернет без угроз”</li>
                    <li className="list__item list-item-checked">Проект реализован в 2021 г. Воронежским институтом высоких технологий</li>
                </ul>
                <div className="signup">
                    <div className="singup__inner_top">
                        <a href="#" className="button button_darkgreen button_large sidepanel-toggle" data-target="login-form" onClick={e => toggleSidepanel(true, "login")}>Войти в систему</a>
                        <Link to="/registration" className="button button_transparent button_large">Регистрация</Link>
                    </div>
                    <div className="signup__inner_bottom">
                        <span className="signup__forgot_link mr-3">Забыли пароль?</span>
                        <a href="javascript:;" className="signup__restore_link sidepanel-toggle" onClick={e => toggleSidepanel(true, "recovery")}>Восстановить доступ</a>
                    </div>

                </div>
            </div>
            <div className="col-12 col-md-5 col-lg-6">
                <div className="ellipsesbg flex-horizontal-right flex-vertical-center">
                    <img className="ellipsesbg__image" src={screenImg} alt="" />
                </div>
            </div>
        </div>
    )
}

export default GeneralPage
