import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import YoutubeVideos from '../YoutubeVideos/YoutubeVideos';
import YoutubeInfoprahics from '../YoutubeInfoprahics/YoutubeInfoprahics';

const YoutubeTab: React.FC = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/videos`}>
                <YoutubeVideos />
            </Route>
            <Route path={`${match.path}/infographics`}>
                <YoutubeInfoprahics />
            </Route>
            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/videos`} />
            </Route>
        </Switch>
    )

}

export default YoutubeTab
