import React, { useState } from 'react'
import { TiktokPostType } from '../../../types/tiktok';

type PropsType = {
    data: TiktokPostType
}

const TikTokVideosItem: React.FC<PropsType> = ({ data,  }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)
    const { imgUrl, description, totalViews, totalLikes, postId, isChecked, totalComments, status, tags, subcultures } = data;
    return (
        <tr className="tbl__row">
            <td className="tbl__cell thumb-column">
                <a href={`https://tiktok.com/@${postId}`} target="_blank">
                    <img className="table__thumb" src={"/img/tiktokPostsImages/" + imgUrl} alt="" />
                </a>
            </td>
            <td className="tbl__cell"><div className="lineclamp-2">{description.length > 25 ? description.slice(0, 26) + "..." : description}</div></td>
            <td className="tbl__cell" hide-order="2">{status ? "Новое" : "Старое"}</td>
            <td className="tbl__cell" hide-order="3"><span className="number-format">{totalViews}</span> | <span className="text_success">+<span className="number-format">0</span></span></td>
            <td className="tbl__cell" hide-order="3"><span className="number-format">{totalLikes}</span> | <span className="text_success">+<span className="number-format">0</span></span></td>
            <td className="tbl__cell" hide-order="3"><span className="number-format">{totalComments}</span> | <span className="text_success">+<span className="number-format">0</span></span></td>
            <td className="tbl__cell" hide-order="1">{subcultures}</td>
            <td className="tbl__cell" hide-order="1">{tags}</td>
            <td className="tbl__cell" hide-order="1">{isChecked}</td>
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default TikTokVideosItem
