import React, { useEffect } from 'react'
import TelegramNavbar from '../TelegramNavbar'
import { Bar, Line } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from '../../../redux/reducers'
import { fetchInfographics } from '../../../redux/telegram/infographics/actions'
import { getBarChartConfig, getLineChartConfig } from '../../../utils/charts'

const TelegramInfoprahics: React.FC = () => {
    const dispatch = useDispatch()
    const { channels, posts, loaded } = useSelector((state: RootStateType) => state.telegramInfographicsReducer)

    useEffect(() => {
        if (!loaded) {
            dispatch(fetchInfographics())
        }
    }, [loaded])

    const channelsStaticData = Object.entries(channels.static)
    const postsStaticData = Object.entries(posts.static)

    const channelsStaticChart = getBarChartConfig(
        channelsStaticData.map((channelData) => channelData[0]),
        channelsStaticData.map((channelData) => channelData[1]),
        "Количество каналов по субкультурам")

    const postsStaticChart = getBarChartConfig(
        postsStaticData.map((postData) => postData[0]),
        postsStaticData.map((postData) => postData[1]),
        "Количество записей по субкультурам")


    const postsDynamicChart = getLineChartConfig(posts.dynamic.labels, posts.dynamic.datasets, "Динамика записей по субкульутрам")
    const channelsDynamicChart = getLineChartConfig(channels.dynamic.labels, channels.dynamic.datasets, "Динамика каналов по субкульутрам")

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <TelegramNavbar />
                    {/* <TelegramFilters /> */}
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="infographics">
                            <div className="vk-infograph-charts">
                                <div className="row mt-4 mb-4">
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Bar height={260} data={channelsStaticChart.data} options={channelsStaticChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Bar height={260} data={postsStaticChart.data} options={postsStaticChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Line height={260} data={channelsDynamicChart.data} options={channelsDynamicChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Line height={260} data={postsDynamicChart.data} options={postsDynamicChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TelegramInfoprahics
