import React from 'react';
import Login from "./Login/Login";
import Recovery from './Recovery';
import Menu from './Menu';
import TagPanel from './TagPanel';
import SubculturePanel from './SubculturePanel';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/reducers';
import classNames from 'classnames';

const Sidepanel = React.forwardRef<HTMLDivElement>((props, ref) => {
    const { isSidepanelActive, currentSidepanelTab } = useSelector((state: RootStateType) => state.sidepanelReducer)

    return (
        <div ref={ref} className={classNames("sidepanel", { "sidepanel_active": isSidepanelActive })}>
            <div className="sidepanel__items">
                {currentSidepanelTab === "login" && <Login />}
                {currentSidepanelTab === "recovery" && <Recovery />}
                {currentSidepanelTab === "menu" && <Menu />}
                {currentSidepanelTab === "tag" && <TagPanel />}
                {currentSidepanelTab === "subculture" && <SubculturePanel />}
            </div>
        </div>
    )
})

export default Sidepanel
