import { SocialCountsType } from './../../types/socials.d';
import { TagType } from "../../types/tags";
import {
    TagActionTypes
} from "./actions";

const defaultState = {
    tags: {
        tags: [] as Array<TagType>,
        count: {} as SocialCountsType,
        loaded: false,
        loading: false,
    },
    filters: {
        page: 1,
        subculture: "-",
        tag: "-",
        keyword: "",
        tagsPerPage: 8,
    },
    count: 0,
    excel: null as File | null,
    filtersSubcultures: [] as Array<string>,
    filtersTags: [] as Array<string>,
    newTag: {
        about: "",
        subculture: "",
        tag: ""
    }
}

type tagsStateType = typeof defaultState

export const tagsReducer = (state = defaultState, action: TagActionTypes): tagsStateType => {
    switch (action.type) {
        case "TAGS_SET_TAGS":
            return {
                ...state,
                tags: {
                    ...state.tags,
                    tags: action.tags
                }
            }
        case "TAGS_SET_LOADED":
            return {
                ...state,
                tags: {
                    ...state.tags,
                    loaded: action.loaded
                }
            }
        case "TAGS_SET_LOADING":
            return {
                ...state,
                tags: {
                    ...state.tags,
                    loading: action.loading
                }
            }
        case "TAGS_SET_TAGS_COUNT": {
            return {
                ...state,
                count: action.count
            }
        }
        case "TAGS_SET_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: action.page
                }
            }
        }
        case "TAGS_SET_TAGS_PER_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    tagsPerPage: action.tagsPerPage
                }
            }
        }
        case "TAGS_SET_FILTERS_TAG": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    tag: action.tag
                }
            }
        }
        case "TAGS_SET_FILTERS_SUBCULTURE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: action.subculture
                }
            }
        }
        case "TAGS_SET_KEYWORD": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    keyword: action.keyword
                }
            }
        }
        case "TAGS_CLEAR_FILTERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: "-",
                    tag: "-",
                    keyword: ""
                }
            }
        }
        case "TAGS_SET_EXCEL": {
            return {
                ...state,
                excel: action.blob
            }
        }
        case "TAGS_SET_FILTERS_SUBCULTURES": {
            return {
                ...state,
                filtersSubcultures: action.subcultures
            }
        }
        case "TAGS_SET_FILTERS_TAGS": {
            return {
                ...state,
                filtersTags: action.tags
            }
        }
        case "TAGS_SET_SOC_COUNT": {
            return {
                ...state,
                tags: {
                    ...state.tags,
                    count: action.count
                }
            }
        }
        case "TAGS_NEW_TAG_CLEAR_TAG_FORM": {
            return {
                ...state,
                newTag: {
                    subculture: "",
                    tag: "",
                    about: ""
                }
            }
        }
        case "TAGS_SET_NEW_TAG_ABOUT": {
            return {
                ...state,
                newTag: {
                    ...state.newTag,
                    about: action.about
                }
            }
        }
        case "TAGS_SET_NEW_TAG_SUBCULTURE": {
            return {
                ...state,
                newTag: {
                    ...state.newTag,
                    subculture: action.subculture
                }
            }
        }
        case "TAGS_SET_NEW_TAG_TAG": {
            return {
                ...state,
                newTag: {
                    ...state.newTag,
                    tag: action.tag
                }
            }
        }
        default:
            return state;
    }
}