import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToggleSidepanel } from '../../../hooks/useToggleSidepanel'
import { loginActions, auth } from '../../../redux/app/login/actions'
import { RootStateType } from '../../../redux/reducers'

const { setEmail, setPassword } = loginActions

const Login: React.FC = () => {
    const dispatch = useDispatch()
    const { email, password, loading } = useSelector((state: RootStateType) => state.loginReducer)
    const authorized = useSelector((state: RootStateType) => state.accountReducer.authorized)
    const toggleSidepanel = useToggleSidepanel()

    useEffect(() => {
        if (authorized) {
            toggleSidepanel(false, null)
        }
    }, [authorized])

    return (
        <div className="sidepanel__items_item d-block">
            <h3 className="sidepanel_header">Вход в систему</h3>
            <form action="" className="form form_login">
                <div className="form__block mb-5">
                    <div className="form__block_label">E-mail</div>
                    <input value={email} onChange={e => dispatch(setEmail(e.target.value))} type="email" className="form__block_input" placeholder="Введите адрес электронной почты" />
                </div>
                <div className="form__block mb-3">
                    <div className="form__block_label">Пароль</div>
                    <input value={password} onChange={e => dispatch(setPassword(e.target.value))} type="password" className="form__block_input" placeholder="Введите пароль" />
                </div>
                <div className="form__block mb-5">
                    <a href="javascript:;" className="form__forgot_link sidepanel-toggle" data-target="forgotpass-form">Забыли пароль</a>
                </div>
                <div className="form__block">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <a onClick={() => {
                                dispatch(auth());
                            }} href="javascript:;" className="button button_darkgreen button_fwidth">Войти</a>
                        </div>
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <Link to="/registration" className="button button_white button_fwidth">Регистрация</Link>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Login
