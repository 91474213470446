import React, { useCallback, useEffect } from 'react'
import VkNavBar from '../VkNavBar/VkNavBar'
import Pagination from "../../Pagination/Pagination";
import VkFilters from '../VkFilters/VkFilters';
import VkAdminsItem from './VkAdminsItem';
import { useDispatch, useSelector } from 'react-redux';
import { vkAdminActions, fetchCities, fetchCountries, fetchExcel, fetchRegions, fetchSubcultures, fetchTags, fetchVkAdmins, fetchVkAdminsCount } from '../../../redux/vk/admins/actions';
import { RootStateType } from '../../../redux/reducers';
import _ from 'lodash';

const { clearFilters, setCity, setCountry, setKeyword, setMaxSubscribers, setMinSubscribers, setRegion, setSubculture, setTag, setVkAdminsLoading, setVkAdminsPage, setVkAdminsPerPage } = vkAdminActions

const VkAdmins: React.FC = () => {
    const dispatch = useDispatch();
    const { loaded, loading, admins, filters, count, countries, cities, regions, excel, subcultures, tags } = useSelector((state: RootStateType) => state.vkAdminsReducer);

    const { subculture, tag, country, region, city, membersCountMin, membersCountMax, name } = filters

    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setVkAdminsPage(1))
        dispatch(fetchVkAdmins());
        dispatch(fetchVkAdminsCount());
        dispatch(fetchExcel());
    }, 500), [])

    const handlerApplyFilters = (): void => {
        dispatch(setVkAdminsPage(1))
        dispatch(fetchVkAdmins());
        dispatch(fetchVkAdminsCount());
        dispatch(fetchExcel());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
        setSubculture("-");
        setTag("-");
        setCountry("-");
        setRegion("-");
        setCity("-");
        setMinSubscribers(0);
        setMaxSubscribers(500000);
    }

    const onChangePage = (page: number): void => {
        dispatch(setVkAdminsPage(page));
        dispatch(fetchVkAdmins());
    }

    const handlerChangeAdminsPerPage = (adminsPerPage: number): void => {
        dispatch(setVkAdminsPerPage(adminsPerPage));
        dispatch(setVkAdminsPage(1));
        dispatch(fetchVkAdmins());
        dispatch(fetchVkAdminsCount());
    }

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(fetchVkAdmins());
            dispatch(fetchVkAdminsCount());
            dispatch(setVkAdminsLoading(true));
            dispatch(fetchCities());
            dispatch(fetchCountries());
            dispatch(fetchRegions());
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
        }
    });

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <VkNavBar />
                    <VkFilters subcultures={subcultures} tags={tags} cities={cities} countries={countries} regions={regions}
                        subculture={subculture} tag={tag} country={country} city={city}
                        region={region} maxSubscribers={membersCountMax} minSubscribers={membersCountMin}
                        keyword={name} setSubculture={(subculture) => dispatch(setSubculture(subculture))} setTag={(tag) => dispatch(setTag(tag))}
                        setCountry={(country) => dispatch(setCountry(country))} setCity={() => dispatch(setCity(city))} setRegion={(region) => dispatch(setRegion(region))}
                        setMaxSubscribers={(maxSubscribers) => dispatch(setMaxSubscribers(maxSubscribers))} setMinSubscribers={(minSubscribers) => dispatch(setMinSubscribers(minSubscribers))}
                        setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters} clearFilters={handlerClearFilters} excel={excel} />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="administrators">
                            <table className="tbl vk-community-tbl">
                                <tr className="tbl__row_header">
                                    <th className="tbl__header_cell" colSpan={2}>ФИО</th>
                                    <th className="tbl__header_cell" hide-order="1">Страна</th>
                                    <th className="tbl__header_cell" hide-order="1">Регион</th>
                                    <th className="tbl__header_cell" hide-order="1">Город</th>
                                    <th className="tbl__header_cell" hide-order="4">Кол-вл групп</th>
                                    <th className="tbl__header_cell" hide-order="4">Кол-во подписчиков</th>
                                    <th className="tbl__header_cell" hide-order="3">Список групп</th>
                                    <th className="tbl__header_cell" hide-order="2">Субкультура</th>
                                    <th className="tbl__header_cell" hide-order="2">Проверено администратором</th>
                                    <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                </tr>
                                {admins.map((item, index) => <VkAdminsItem key={index} data={item} />)}
                            </table>
                        </div>
                    </div>
                </div>

                <Pagination itemsPerPage={filters.adminsPerPage} handlerChangeItemsPerPage={handlerChangeAdminsPerPage} onChangePage={onChangePage} activePage={filters.page} count={count} />
            </div>
        </div>

    )
}

export default VkAdmins
