import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import Sub from '../Sub/Sub';
import Tags from '../Tags/Tags';

const SubnTagsTab: React.FC = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/subcultures`}>
                <Sub />
            </Route>
            <Route path={`${match.path}/tags`}>
                <Tags />
            </Route>
            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/subcultures`} />
            </Route>
        </Switch>
    )

}

export default SubnTagsTab
