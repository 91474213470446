import { TelegramChannelType } from './../../../types/telegram';
import {
    TelegramChannelActionTypes
} from "./actions";

const defaultState = {
    filters: {
        page: 1,
        subculture: "-",
        tag: "-",
        keyword: "",
        channelsPerPage: 8,
    },
    loaded: false,
    loading: false,
    channels: [] as Array<TelegramChannelType>,
    count: 0,
    excel: null as File | null,
    subcultures: [] as Array<string>,
    tags: [] as Array<string>
};

type TelegramChannelsStateType = typeof defaultState

export const telegramChannelsReducer = (state = defaultState, action: TelegramChannelActionTypes): TelegramChannelsStateType => {
    switch (action.type) {
        case "TELEGRAM_CHANNELS_SET_LOADED": {
            return {
                ...state,
                loaded: action.loaded
            }
        }
        case "TELEGRAM_CHANNELS_SET_CHANNELS": {
            return {
                ...state,
                channels: action.channels
            }
        }
        case "TELEGRAM_CHANNELS_SET_LOADING": {
            return {
                ...state,
                loading: action.loading
            }
        }
        case "TELEGRAM_CHANNELS_SET_CHANNELS_COUNT": {
            return {
                ...state,
                count: action.count
            }
        }
        case "TELEGRAM_CHANNELS_SET_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: action.page
                }
            }
        }
        case "TELEGRAM_CHANNELS_SET_CHANNELS_PER_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    channelsPerPage: action.channelsPerPage
                }
            }
        }
        case "TELEGRAM_CHANNELS_SET_TAG": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    tag: action.tag
                }
            }
        }
        case "TELEGRAM_CHANNELS_SET_SUBCULTURE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: action.subculture
                }
            }
        }
        case "TELEGRAM_CHANNELS_SET_KEYWORD": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    keyword: action.keyword
                }
            }
        }
        case "TELEGRAM_CHANNELS_CLEAR_FILTERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: "-",
                    tag: "-",
                    keyword: ""
                }
            }
        }
        case "TELEGRAM_CHANNELS_SET_EXCEL": {
            return {
                ...state,
                excel: action.blob
            }
        }
        case "TELEGRAM_CHANNELS_SET_SUBCULTURES": {
            return {
                ...state,
                subcultures: action.subcultures
            }
        }
        case "TELEGRAM_CHANNELS_SET_TAGS": {
            return {
                ...state,
                tags: action.tags
            }
        }
        default:
            return state;
    }
};