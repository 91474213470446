import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { RootStateType } from '../redux/reducers';
import { SidepanelTabTypes } from '../types/sidepanel';
import { sidepanelActions } from '../redux/sidepanel/actions';

const { setIsSidepanelActive, setSidepanelCurrentTab, setToggleTimer } = sidepanelActions

export const useToggleSidepanel = () => {
    const dispatch = useDispatch()
    const toggleTimer = useSelector((state: RootStateType) => state.sidepanelReducer.toggleTimer)

    return (isActive: boolean, sidepanelTab: SidepanelTabTypes) => {
        if (!isActive) {
            dispatch(setIsSidepanelActive(false))
            dispatch(setToggleTimer(setTimeout(() => {
                dispatch(setSidepanelCurrentTab(null))
            }, 500)))
        } else {
            if (toggleTimer) {
                clearTimeout(toggleTimer)
                setToggleTimer(null)
            }

            dispatch(setSidepanelCurrentTab(sidepanelTab))
            dispatch(setIsSidepanelActive(true))
        }
    }
}