import React, { useEffect } from 'react'
import Sidepanel from '../../Sidepanel/Sidepanel'
import TikTokNavbar from '../TikTokNavbar'
import { Bar, Line } from 'react-chartjs-2'
import TikTokFIlters from '../TikTokFIlters/TikTokFIlters';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux/reducers';
import { fetchInfographics } from '../../../redux/tiktok/infographics/actions';
import { getBarChartConfig, getLineChartConfig } from '../../../utils/charts';

function TikTokInfographics() {
    const dispatch = useDispatch()
    const { users, posts, loaded } = useSelector((state: RootStateType) => state.tiktokInfographicsReducer)

    useEffect(() => {
        if (!loaded) {
            dispatch(fetchInfographics())
        }
    }, [loaded])

    const usersStaticData = Object.entries(users.static)
    const postsStaticData =  Object.entries(posts.static)

    const usersStaticChart = getBarChartConfig(
        usersStaticData.map((userData) => userData[0]),
        usersStaticData.map((userData) => userData[1]),
        "Количество пользователей по субкультурам")

    const postsStaticChart = getBarChartConfig(
        postsStaticData.map((postData) => postData[0]),
        postsStaticData.map((postData) => postData[1]),
        "Количество записей по субкультурам")

    const postsDynamicChart = getLineChartConfig(posts.dynamic.labels, posts.dynamic.datasets, "Динамика записей по субкульутрам")
    const usersDynamicChart = getLineChartConfig(users.dynamic.labels, users.dynamic.datasets, "Динамика пользователей по субкульутрам")


    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <TikTokNavbar />
                    {/* <TikTokFIlters /> */}
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="infographics">
                            <div className="vk-infograph-charts">
                                <div className="row mt-4 mb-4">
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Bar height={260} data={postsStaticChart.data} options={postsStaticChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Bar height={260} data={usersStaticChart.data} options={usersStaticChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Line height={260} data={usersDynamicChart.data} options={usersDynamicChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Line height={260} data={postsDynamicChart.data} options={postsDynamicChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TikTokInfographics
