import { getLineChartData } from './../../../utils/charts';
import { InfographicsDataType, StaticInfographicsDataType, DynamicInfographicsDataType } from './../../../types/infographics.d';
import axios from 'axios';
import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes, ThunkDispatchType } from '../../../types/common';

export const instagramInfographicsActions = {
    setStaticUsersInfographics: (staticUsersInfographics: StaticInfographicsDataType) => ({
        type: "INST_INFOGRAPHICS_SET_STATIC_USERS" as const,
        staticUsersInfographics
    }),
    setDynamicUsersInfographics: (dynamicUsersInfographics: DynamicInfographicsDataType) => ({
        type: "INST_INFOGRAPHICS_SET_DYNAMIC_USERS" as const,
        dynamicUsersInfographics
    }),
    setLoaded: (loaded: boolean) => ({
        type: "INST_INFOGRAPHICS_SET_LOADED" as const,
        loaded
    })
}

export const _fetchStaticInfographicsUsers = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=instagram&entity=group&last=true")
    dispatch(instagramInfographicsActions.setStaticUsersInfographics(data[0].subculturesStatistics))
}

export const _fetchDynamicInfographicsUsers = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=instagram&entity=group")
    dispatch(instagramInfographicsActions.setDynamicUsersInfographics(getLineChartData(data)))
}

export const fetchInfographics = (): ThunkActionType => async (dispatch) => {
    await Promise.all([
        _fetchStaticInfographicsUsers(dispatch),
        _fetchDynamicInfographicsUsers(dispatch)
    ])
    dispatch(instagramInfographicsActions.setLoaded(true))
}


type ThunkActionType = ThunkAction<void, RootStateType, unknown, InstagramInfographicsActionTypes>
export type InstagramInfographicsActionTypes = ReturnType<InferValueTypes<typeof instagramInfographicsActions>>
