import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { TiktokUserType } from './../../../types/tiktok';
import axios from "axios";
import { InferValueTypes } from '../../../types/common';


export const tiktokUserActions = {

    setTikTokUsersLoaded: (loaded: boolean) => ({
        type: "TIKTOK_USERS_SET_LOADED" as const,
        loaded
    }),

    setTikTokUsers: (users: Array<TiktokUserType>) => ({
        type: "TIKTOK_USERS_SET_USERS" as const,
        users
    }),

    setTikTokUsersLoading: (loading: boolean) => ({
        type: "TIKTOK_USERS_SET_LOADING" as const,
        loading
    }),

    setTikTokUsersCount: (count: number) => ({
        type: "TIKTOK_USERS_SET_USERS_COUNT" as const,
        count
    }),

    setTikTokUsersPage: (page: number) => ({
        type: "TIKTOK_USERS_SET_PAGE" as const,
        page
    }),

    setTikTokUsersPerPage: (usersPerPage: number) => ({
        type: "TIKTOK_USERS_SET_USERS_PER_PAGE" as const,
        usersPerPage
    }),

    setSubculture: (subculture: string) => ({
        type: "TIKTOK_USERS_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "TIKTOK_USERS_SET_TAG" as const,
        tag
    }),

    setKeyword: (keyword: string) => ({
        type: "TIKTOK_USERS_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "TIKTOK_USERS_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "TIKTOK_USERS_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "TIKTOK_USERS_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "TIKTOK_USERS_SET_TAGS" as const,
        tags
    })
}



export const fetchTikTokUsers = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tikTokUsersReducer.filters;

    axios.post<Array<TiktokUserType>>('/api/tiktok/users/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(tiktokUserActions.setTikTokUsers(data))
        dispatch(tiktokUserActions.setTikTokUsersLoaded(true))
        dispatch(tiktokUserActions.setTikTokUsersLoading(false))
    })
};

export const fetchTikTokUsersCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tikTokUsersReducer.filters;

    axios.post<number>("/api/tiktok/users/get-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(tiktokUserActions.setTikTokUsersCount(data))
    })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tikTokUsersReducer.filters;
    axios.request<File>({url: "/api/tiktok/users/create-excel", method: "post", data: JSON.stringify(filters)})
        .then(response => {
            
            dispatch(tiktokUserActions.setExcel(response.data))
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(tiktokUserActions.setSubcultures(data))
    })
};

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/tiktok/users/get-tags").then(({ data }) => {
        dispatch(tiktokUserActions.setTags(data))
    })
}

export type TiktokUserActionTypes = ReturnType<InferValueTypes<typeof tiktokUserActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, TiktokUserActionTypes>