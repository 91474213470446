import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from '../../redux/reducers';
import { tagActions, sendTagRequest } from '../../redux/tags/actions';

const {  setNewTagAbout, setNewTagSubculture, setNewTagTag } = tagActions

const TagPanel: React.FC = () => {
    const { tag, subculture, about } = useSelector((state: RootStateType) => state.tagsReducer.newTag);
    const { filtersSubcultures } = useSelector((state: RootStateType) => state.tagsReducer);
    const dispatch = useDispatch();
    return (
        <div className="sidepanel__items_item" data-item="form-add-tag">
            <h3 className="sidepanel_header">Добавление тега</h3>
            <form action="" className="form" onSubmit={(e) => {
                e.preventDefault();
                return false;
            }}>
                <div className="form__block mb-5">
                    <div className="form__block_label">Название</div>
                    <input type="text" className="form__block_input" placeholder="Введите тег" value={tag} onChange={e => dispatch(setNewTagTag(e.target.value))} />
                </div>
                <div className="form__block mb-5">
                    <div className="form__block_label">Субкультура</div>
                    <select className="form__block_select" value={subculture} onChange={e => dispatch(setNewTagSubculture(e.target.value))}>
                        {filtersSubcultures.map(item => <option key={item} value={item}>{item}</option>)}
                    </select>
                </div>
                <div className="form__block mb-5">
                    <div className="form__block_label">Описание</div>
                    <textarea className="form__block_textarea" placeholder="Введите описание" value={about} onChange={e => dispatch(setNewTagAbout(e.target.value))} />
                </div>
                <div className="form__block">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <button type="submit" className="button button_darkgreen button_fwidth" onClick={e => {
                                if (tag.length == 0) {
                                    return false;
                                }
                                dispatch(sendTagRequest())
                            }}>Добавить</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default TagPanel
