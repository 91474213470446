import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SubnTagsFilters from '../SubnTagsFilters/SubnTagsFilters'
import SubnTagsNavbar from '../SubnTagsNavbar'
import SubItem from "./SubItem";
import Pagination from "../../Pagination/Pagination";
import {
    subcultureActions, fetchExcel, fetchFiltersSubcultures,
    fetchFiltersTags, fetchSubcultures, fetchSubculturesCount
} from '../../../redux/subcultures/actions'
import { RootStateType } from '../../../redux/reducers'
import { TimerType } from '../../../types/common'
import { useToggleSidepanel } from '../../../hooks/useToggleSidepanel'
import _ from 'lodash'

const { clearFilters, setKeyword, setLoading, setSubculture, setSubculturesPage, setSubculturesPerPage, setTag } = subcultureActions

const Sub: React.FC = () => {
    const dispatch = useDispatch()
    const { filters, count, excel, filtersSubcultures, filtersTags, subcultures } = useSelector((state: RootStateType) => state.subculturesReducer);
    const { role } = useSelector((state: RootStateType) => state.accountReducer);
    const { subculture, tag, keyword } = filters;
    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(fetchSubcultures());
        dispatch(fetchSubculturesCount());
        dispatch(fetchExcel());
    }, 500), [])

    const toggleSidepanel = useToggleSidepanel()

    const handlerApplyFilters = (): void => {
        dispatch(setTag(tag));
        dispatch(setSubculture(subculture));
        dispatch(setSubculturesPage(1))
        dispatch(fetchSubcultures());
        dispatch(fetchSubculturesCount());
        dispatch(fetchExcel());
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
    }

    const onChangePage = (page: number): void => {
        dispatch(setSubculturesPage(page));
        dispatch(fetchSubcultures());
    }

    const handlerChangeSubculturesPerPage = (subculturesPerPage: number): void => {
        dispatch(setSubculturesPerPage(subculturesPerPage));
        dispatch(setSubculturesPage(1));
        dispatch(fetchSubcultures());
        dispatch(fetchSubculturesCount());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    useEffect(() => {
        if (subcultures.loaded === false && subcultures.loading === false) {
            dispatch(setLoading(true))
            dispatch(fetchSubcultures())
            dispatch(fetchSubculturesCount());
            dispatch(fetchFiltersTags());
            dispatch(fetchFiltersSubcultures());
            dispatch(fetchExcel());
        }
    })

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <SubnTagsNavbar />
                    <SubnTagsFilters tag={tag} keyword={keyword} setSubculture={(subculture) => dispatch(setSubculture(subculture))}
                        setTag={(tag) => dispatch(setTag(tag))}
                        subculture={subculture} setKeyword={handlerChangeKeyword}
                        applyFilters={handlerApplyFilters} clearFilters={handlerClearFilters} excel={excel}
                        subcultures={filtersSubcultures} tags={filtersTags} />

                    <div className="control_panel__body">
                        <table className="tbl">
                            <tr className="tbl__row_header">
                                <th className="tbl__header_cell">Субкультура</th>
                                <th className="tbl__header_cell" hide-order="1">Описание субкультуры</th>
                                <th className="tbl__header_cell" hide-order="2">Кол-во публикаций в Телеграме</th>
                                <th className="tbl__header_cell" hide-order="2">Кол-во сообществ Вконтакте</th>
                                <th className="tbl__header_cell" hide-order="2">Кол-во пользователей Instagram</th>
                                <th className="tbl__header_cell" hide-order="2">Кол-во публикаций в TikTok</th>
                                {role === "ROLE_ADMIN" &&
                                    <th className="tbl__header_cell" hide-order="1">Удалить</th>
                                }
                                <th className="tbl__header_cell showmore-column showmore-mob"></th>
                            </tr>
                            {subcultures.subcultures.map(item => <SubItem role={role} data={item} dispatch={dispatch} count={subcultures.count[item.subcultureName]} />)}
                        </table>
                        <div className="tabbed-panel__content_footer">
                            <a href="javascript:;" className="button button_darkgreen sidepanel-toggle" data-target="form-add-subculture" onClick={() => toggleSidepanel(true, "subculture")}>Добавить субкультуру</a>
                        </div>
                    </div>
                </div>
                <Pagination onChangePage={onChangePage} handlerChangeItemsPerPage={handlerChangeSubculturesPerPage} activePage={filters.page} itemsPerPage={filters.subculturesPerPage} count={count} />

            </div>
        </div>

    )
}

export default Sub
