import React, { useState } from 'react'
import { TiktokUserType } from '../../../types/tiktok';

type PropsType = {
    data: TiktokUserType
}

const TikTokUsersItem: React.FC<PropsType> = ({ data, }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)
    const { userId, username, isChecked, totalSubscribers, totalFollowing, totalViews, subscribersGrowth, followingGrowth, viewsGrowth, tags, subcultures } = data;
    return (
        <tr className="tbl__row">
            <td className="tbl__cell"><a target="_blank" href={`https://tiktok.com/@${userId}`} className="control-panel__link">{username}</a></td>
            <td className="tbl__cell" hide-order="3">
                <span className="number-format">{totalSubscribers}</span> | <span className="text_success">+<span className="number-format">{subscribersGrowth}</span></span>
            </td>
            <td className="tbl__cell" hide-order="2">
                <span className="number-format">{totalFollowing}</span> | <span className="text_success">+<span className="number-format">{followingGrowth}</span></span>
            </td>
            <td className="tbl__cell" hide-order="2">
                <span className="number-format">{totalViews}</span> | <span className="text_success">+<span className="number-format">{viewsGrowth}</span></span>
            </td>
            <td className="tbl__cell" hide-order="1">{subcultures}</td>
            <td className="tbl__cell" hide-order="1">{tags}</td>
            <td className="tbl__cell" hide-order="2">
                {isChecked}
            </td>
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default TikTokUsersItem
