import React, { useEffect } from 'react'
import InstNavBar from '../InstNavBar'
import { Bar, Line } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInfographics } from '../../../redux/instagram/infographics/actions'
import { RootStateType } from '../../../redux/reducers'
import { getBarChartConfig, getLineChartConfig } from '../../../utils/charts'

const InstInfographics: React.FC = () => {
    const dispatch = useDispatch()
    const { users, loaded } = useSelector((state: RootStateType) => state.instagramInfographicsReducer)

    useEffect(() => {
        if (!loaded) {
            dispatch(fetchInfographics())
        }
    }, [loaded])

    const usersStaticData = Object.entries(users.static)

    const usersStaticChart = getBarChartConfig(
        usersStaticData.map((userData) => userData[0]),
        usersStaticData.map((userData) => userData[1]),
        "Количество пользователей по субкультурам")
    const usersDynamicChart = getLineChartConfig(users.dynamic.labels, users.dynamic.datasets, "Динамика пользователей по субкульутрам")

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <InstNavBar />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="infographics">
                            <div className="vk-infograph-charts">
                                <div className="row mt-4 mb-4">
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Bar height={260} data={usersStaticChart.data} options={usersStaticChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Line height={260} data={usersDynamicChart.data} options={usersDynamicChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default InstInfographics
