import { ImageType } from './../../types/images';

import {
    ImageActionTypes
} from "./actions";

const defaultState = {
    images: [] as Array<ImageType>,
    loaded: false,
    loading: false,
    filters: {
        page: 1,
        imagesPerPage: 8,
        name: "",
        tag: "-",
        subculture: "-"
    },
    count: 0,
    excel: null as File | null,
    subcultures: [] as Array<string>,
    tags: [] as Array<string>
}

type ImagesStateType = typeof defaultState

export const imagesReducer = (state = defaultState, action: ImageActionTypes): ImagesStateType => {
    switch (action.type) {
        case "IMAGES_SET_LOADED": {
            return {
                ...state,
                loaded: action.loaded
            }
        }
        case "IMAGES_SET_IMAGES": {
            return {
                ...state,
                images: action.images
            }
        }
        case "IMAGES_SET_LOADING": {
            return {
                ...state,
                loading: action.loading
            }
        }
        case "IMAGES_SET_IMAGES_COUNT": {
            return {
                ...state,
                count: action.count
            }
        }
        case "IMAGES_SET_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: action.page
                }
            }
        }
        case "IMAGES_SET_IMAGES_PER_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    imagesPerPage: action.imagesPerPage
                }
            }
        }
        case "IMAGES_SET_TAG": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    tag: action.tag
                }
            }
        }
        case "IMAGES_SET_SUBCULTURE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: action.subculture
                }
            }
        }
        case "IMAGES_SET_KEYWORD": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    name: action.keyword
                }
            }
        }
        case "IMAGES_CLEAR_FILTERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: "-",
                    tag: "-",
                    name: ""
                }
            }
        }
        case "IMAGES_SET_EXCEL": {
            return {
                ...state,
                excel: action.blob
            }
        }
        case "IMAGES_SET_TAGS": {
            return {
                ...state,
                tags: action.tags
            }
        }
        case "IMAGES_SET_SUBCULTURES": {
            return {
                ...state,
                subcultures: action.subcultures
            }
        }
        default:
            return state;
    }
}