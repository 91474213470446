import { getLineChartData } from './../../../utils/charts';
import axios from 'axios';
import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes, ThunkDispatchType } from '../../../types/common';
import { StaticInfographicsDataType, DynamicInfographicsDataType, InfographicsDataType } from './../../../types/infographics.d';
import _ from 'lodash';

export const telegramInfographicsActions = {
    setStaticChannelsInfographics: (staticChannelsInfographics: StaticInfographicsDataType) => ({
        type: "TELEGRAM_INFOGRAPHICS_SET_STATIC_CHANNELS" as const,
        staticChannelsInfographics
    }),
    setStaticPostsInfographics: (staticPostsInfographics: StaticInfographicsDataType) => ({
        type: "TELEGRAM_INFOGRAPHICS_SET_STATIC_POSTS" as const,
        staticPostsInfographics
    }),
    setDynamicChannelsInfographics: (dynamicChannelsInfographics: DynamicInfographicsDataType) => ({
        type: "TELEGRAM_INFOGRAPHICS_SET_DYNAMIC_CHANNELS" as const,
        dynamicChannelsInfographics
    }),
    setDynamicPostsInfographics: (dynamicPostsInfographics: DynamicInfographicsDataType) => ({
        type: "TELEGRAM_INFOGRAPHICS_SET_DYNAMIC_POSTS" as const,
        dynamicPostsInfographics
    }),
    setLoaded: (loaded: boolean) => ({
        type: "TELEGRAM_INFOGRAPHICS_SET_LOADED" as const,
        loaded
    })
}

export const _fetchStaticInfographicsChannels = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=telegram&entity=channel&last=true")
    dispatch(telegramInfographicsActions.setStaticChannelsInfographics(data[0].subculturesStatistics))
}

export const _fetchStaticInfographicsPosts = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=telegram&entity=post&last=true")
    dispatch(telegramInfographicsActions.setStaticPostsInfographics(data[0].subculturesStatistics)) 
}

export const _fetchDynamicInfographicsChannels = async (dispatch: ThunkDispatchType) => {
    const { data: dynamicData } = await axios.get<InfographicsDataType>("/api/social-statistics?social=telegram&entity=channel")
    dispatch(telegramInfographicsActions.setDynamicChannelsInfographics(getLineChartData(dynamicData)))
}

export const _fetchDynamicInfographicsPosts = async (dispatch: ThunkDispatchType) => {
    const { data: dynamicData } = await axios.get<InfographicsDataType>("/api/social-statistics?social=telegram&entity=post")
    dispatch(telegramInfographicsActions.setDynamicPostsInfographics(getLineChartData(dynamicData)))
}

export const fetchInfographics = (): ThunkActionType => async (dispatch) => {
    await Promise.all([
        _fetchStaticInfographicsChannels(dispatch),
        _fetchStaticInfographicsPosts(dispatch),
        _fetchDynamicInfographicsChannels(dispatch),
        _fetchDynamicInfographicsPosts(dispatch)
    ])
    dispatch(telegramInfographicsActions.setLoaded(true))
}


type ThunkActionType = ThunkAction<void, RootStateType, unknown, telegramInfographicsActionTypes>
export type telegramInfographicsActionTypes = ReturnType<InferValueTypes<typeof telegramInfographicsActions>>