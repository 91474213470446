import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import TikTokInfographics from '../TikTokInfographics/TikTokInfographics';
import TikTokUsers from '../TikTokUsers/TikTokUsers';
import TikTokVideos from '../TikTokVideos/TikTokVideos';

const TikTokTab: React.FC = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/users`}>
                <TikTokUsers />
            </Route>
            <Route path={`${match.path}/videos`}>
                <TikTokVideos />
            </Route>
            <Route path={`${match.path}/infographics`}>
                <TikTokInfographics />
            </Route>
            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/users`} />
            </Route>
        </Switch>
    )

}

export default TikTokTab
