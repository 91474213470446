import VkNavBar from '../VkNavBar/VkNavBar'
import { Bar, Line } from 'react-chartjs-2';
import { useEffect } from 'react';
import { fetchInfographics } from '../../../redux/vk/infographics/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux/reducers';
import { getBarChartConfig, getLineChartConfig } from '../../../utils/charts';

function VkInfographics() {
    const dispatch = useDispatch()
    const { groups, users, admins, loaded } = useSelector((state: RootStateType) => state.vkInfographicsReducer)

    useEffect(() => {
        if (!loaded) {
            dispatch(fetchInfographics())
        }
    }, [loaded])

    const groupsStaticData = Object.entries(groups.static)
    const usersStaticData = Object.entries(users.static)
    const adminsStaticData = Object.entries(admins.static)

    const groupsStaticChart = getBarChartConfig(
        groupsStaticData.map((groupData) => groupData[0]),
        groupsStaticData.map((groupData) => groupData[1]),
        "Количество сообществ по субкультурам")

    const usersStaticChart = getBarChartConfig(
        usersStaticData.map((userData) => userData[0]),
        usersStaticData.map((userData) => userData[1]),
        "Количество пользователей по субкультурам")

    const adminsStaticChart = getBarChartConfig(
        adminsStaticData.map((adminData) => adminData[0]),
        adminsStaticData.map((adminData) => adminData[1]),
        "Количество администраторов по субкультурам")

    const groupsDynamicChart = getLineChartConfig(groups.dynamic.labels, groups.dynamic.datasets, "Динамика сообществ по субкульутрам")
    const usersDynamicChart = getLineChartConfig(users.dynamic.labels, users.dynamic.datasets, "Динамика пользователей по субкульутрам")
    const adminsDynamicChart = getLineChartConfig(admins.dynamic.labels, admins.dynamic.datasets, "Динамика администраторов по субкульутрам")

    return (

        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <VkNavBar />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="infographics">
                            <div className="vk-infograph-charts">
                                <div className="row mt-4 mb-4">
                                    <div className="col-12 col-md-6 col-xl-4 mb-3">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Bar height={260} data={groupsStaticChart.data} options={groupsStaticChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Bar height={260} data={usersStaticChart.data} options={usersStaticChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Bar height={260} data={adminsStaticChart.data} options={adminsStaticChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Line height={260} data={groupsDynamicChart.data} options={groupsDynamicChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Line height={260} data={usersDynamicChart.data} options={usersDynamicChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4">
                                        <div className="control-panel">
                                            <div className="control_panel__body">
                                                <Line height={260} data={adminsDynamicChart.data} options={adminsDynamicChart.options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default VkInfographics
