import { TiktokPostType } from './../../../types/tiktok';
import {
    TiktokPostActionTypes
} from "./actions";

const defaultState = {
    filters: {
        page: 1,
        subculture: "-",
        tag: "-",
        keyword: "",
        postsPerPage: 8,
    },
    loaded: false,
    loading: false,
    posts: [] as Array<TiktokPostType>,
    count: 0,
    excel: null as File | null,
    subcultures: [] as Array<string>,
    tags: [] as Array<string>
};

type TiktokPostsStateType = typeof defaultState

export const tikTokPostsReducer = (state = defaultState, action: TiktokPostActionTypes): TiktokPostsStateType => {
    switch (action.type) {
        case "TIKTOK_POSTS_SET_LOADED": {
            return {
                ...state,
                loaded: action.loaded
            }
        }
        case "TIKTOK_POSTS_SET_POSTS": {
            return {
                ...state,
                posts: action.posts
            }
        }
        case "TIKTOK_POSTS_SET_LOADING": {
            return {
                ...state,
                loading: action.loading
            }
        }
        case "TIKTOK_POSTS_SET_POSTS_COUNT": {
            return {
                ...state,
                count: action.count
            }
        }
        case "TIKTOK_POSTS_SET_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: action.page
                }
            }
        }
        case "TIKTOK_POSTS_SET_POSTS_PER_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    postsPerPage: action.postsPerPage
                }
            }
        }
        case "TIKTOK_POSTS_SET_TAG": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    tag: action.tag
                }
            }
        }
        case "TIKTOK_POSTS_SET_SUBCULTURE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: action.subculture
                }
            }
        }
        case "TIKTOK_POSTS_SET_KEYWORD": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    keyword: action.keyword
                }
            }
        }
        case "TIKTOK_POSTS_CLEAR_FILTERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: "-",
                    tag: "-",
                    keyword: ""
                }
            }
        }
        case "TIKTOK_POSTS_SET_EXCEL": {
            return {
                ...state,
                excel: action.blob
            }
        }
        case "TIKTOK_POSTS_SET_SUBCULTURES": {
            return {
                ...state,
                subcultures: action.subcultures
            }
        }
        case "TIKTOK_POSTS_SET_TAGS": {
            return {
                ...state,
                tags: action.tags
            }
        }
        default:
            return state;
    }
};