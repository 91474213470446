import { randomUUID } from 'crypto';
import _ from 'lodash';
import React, { useState } from 'react'
import { VkAdminType } from '../../../types/vk';

type PropsType = {
    data: VkAdminType
}

const VkAdminsItem: React.FC<PropsType> = ({ data, }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)
    const { firstName, lastName, thirdName, imgUrl, cityName, isChecked, regionName, countryName, membersCount, groupsCount, subcultureNames, id, groups } = data;
    return (
        <tr className="tbl__row">
            <td className="tbl__cell  avatar-column">
                <img className="user-list__avatar" src={imgUrl} alt="" />
            </td>
            <td className={`tbl__cell ${firstName || lastName || thirdName ? "" : "empty-cell"}`}><a href={`https://vk.com/id${id}`} className="control-panel__link bolder">{firstName} {lastName} {thirdName}</a></td>
            <td className={`tbl__cell ${countryName ? "" : "empty-cell"}`} hide-order="1">{countryName}</td>
            <td className={`tbl__cell ${regionName ? "" : "empty-cell"}`} hide-order="1">{regionName}</td>
            <td className={`tbl__cell ${cityName ? "" : "empty-cell"}`} hide-order="1">{cityName}</td>
            <td className={`tbl__cell ${groupsCount ? "" : "empty-cell"}`} hide-order="4">{groupsCount} | <span className="text_success">+0</span> | <span className="text_success">+0</span></td>
            <td className={`tbl__cell ${membersCount ? "" : "empty-cell"}`} hide-order="4"><span className="number-format">{membersCount}</span> | <span className="text_success">+<span className="number-format">0</span></span> | <span className="text_success">+<span className="number-format">0</span></span></td>
            <td className="tbl__cell" hide-order="3">{groups.map<React.ReactNode>(group => <a className="control-panel__link" key={group} href={`https://vk.com/club${group}`}>{group}</a>).reduce((prev, curr) => [prev, ", ", curr])}</td> {/* refactor join method */}
            <td className={`tbl__cell ${subcultureNames ? "" : "empty-cell"}`} hide-order="2">{subcultureNames.join(",")}</td>
            <td className="tbl__cell" hide-order="2">{isChecked}</td>
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>

    )
}

export default VkAdminsItem
