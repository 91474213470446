import React, { useCallback } from 'react'
import SubnTagsNavbar from '../SubnTagsNavbar'
import { tagActions } from "../../../redux/tags/actions";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import TagsItem from "./TagsItem";
import SubnTagsFilters from '../SubnTagsFilters/SubnTagsFilters';
import Pagination from "../../Pagination/Pagination";
import { fetchExcel, fetchFiltersSubcultures, fetchFiltersTags, fetchTags, fetchTagsCount } from "../../../redux/tags/actions"
import { RootStateType } from '../../../redux/reducers';
import { useToggleSidepanel } from '../../../hooks/useToggleSidepanel';
import _ from 'lodash';

const { clearFilters, setKeyword, setLoading, setSubculture, setTag, setTagsPage, setTagsPerPage } = tagActions

const Tags: React.FC = () => {
    const dispatch = useDispatch()
    const { filters, count, excel, filtersSubcultures, filtersTags, tags } = useSelector((state: RootStateType) => state.tagsReducer);
    const { role } = useSelector((state: RootStateType) => state.accountReducer);
    const { subculture, tag, keyword } = filters;

    const toggleSidepanel = useToggleSidepanel()
    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setTagsPage(1));
        dispatch(fetchTags());
        dispatch(fetchTagsCount());
        dispatch(fetchExcel());
    }, 500), [])

    const handlerApplyFilters = (): void => {
        dispatch(setTag(tag));
        dispatch(setSubculture(subculture));
        dispatch(setTagsPage(1))
        dispatch(fetchTags());
        dispatch(fetchTagsCount());
        dispatch(fetchExcel());
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
    }

    const onChangePage = (page: number) => {
        dispatch(setTagsPage(page));
        dispatch(fetchTags());
    }

    const handlerChangeTagsPerPage = (tagsPerPage: number) => {
        dispatch(setTagsPerPage(tagsPerPage));
        dispatch(setTagsPage(1));
        dispatch(fetchTags());
        dispatch(fetchTagsCount());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    useEffect(() => {
        if (tags.loaded === false && tags.loading === false) {
            dispatch(setLoading(true))
            dispatch(fetchTags())
            dispatch(fetchTagsCount());
            dispatch(fetchFiltersTags());
            dispatch(fetchFiltersSubcultures());
            dispatch(fetchExcel());
        }
    })
    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <SubnTagsNavbar />
                    <SubnTagsFilters tag={tag} keyword={keyword} setSubculture={(subculture) => dispatch(setSubculture(subculture))} setTag={(tag) => dispatch(setTag(tag))}
                        subculture={subculture} setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters} clearFilters={handlerClearFilters} excel={excel} subcultures={filtersSubcultures} tags={filtersTags} />

                    <div className="control_panel__body">
                        <table className="tbl">
                            <tr className="tbl__row_header">
                                <th className="tbl__header_cell">Тег</th>
                                <th className="tbl__header_cell" hide-order="1">Описание тега</th>
                                <th className="tbl__header_cell" hide-order="2">Кол-во публикаций в Телеграме</th>
                                <th className="tbl__header_cell" hide-order="2">Кол-во публикаций в Вконтакте</th>
                                <th className="tbl__header_cell" hide-order="2">Кол-во публикаций в Instagram</th>
                                <th className="tbl__header_cell" hide-order="2">Кол-во публикаций в TikTok</th>
                                {role === "ROLE_ADMIN" &&
                                    <th className="tbl__header_cell" hide-order="1">Удалить</th>
                                }
                                <th className="tbl__header_cell showmore-column showmore-mob"></th>
                            </tr>
                            {
                                tags.tags.map((tag, i) => {
                                    return (
                                        <TagsItem dispatch={dispatch} key={tag.id} data={tag} role={role} count={tags.count[tag.tag]} />
                                    )
                                })
                            }
                        </table>
                        <div className="tabbed-panel__content_footer">
                            <a href="javascript:;" className="button button_darkgreen sidepanel-toggle" data-target="form-add-tag" onClick={() => toggleSidepanel(true, "tag")}>Добавить тег</a>
                        </div>
                    </div>
                </div>
                <Pagination onChangePage={onChangePage} handlerChangeItemsPerPage={handlerChangeTagsPerPage} activePage={filters.page} itemsPerPage={filters.tagsPerPage} count={count} />
            </div>
        </div>

    )
}

export default Tags
