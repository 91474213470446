import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { editorActions, deleteUser, setCheckedUserDB, fetchUsersCount, changeUserRole, fetchUsers } from "../../../redux/editor/actions";
import Pagination from "../../Pagination/Pagination";
import EditorUsersTableItem from './EditorUsersTableItem';
import EditorUsersTableFilters from './EditorUsersTableFilters';
import { RootStateType } from '../../../redux/reducers';
import { uniqueId } from 'lodash';

const { setUsersLoading, setUserRole, setChecked, setUsersPerPage, setActivePage } = editorActions

const EditorUsers: React.FC = () => {
    const dispatch = useDispatch();
    const { loaded, loading, users, count, filters } = useSelector((state: RootStateType) => state.editorReducer.users);
    const role = useSelector((state: RootStateType) => state.accountReducer.role);
    const [checkboxValue, setCheckboxValue] = useState(false);

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(setUsersLoading(true));
            dispatch(fetchUsers());
            dispatch(fetchUsersCount());
        }
    });

    const handlerDeleteUser = (userId: number): void => {
        dispatch(deleteUser(userId))
    }

    const onChangePage = (page: number): void => {
        dispatch(setActivePage(page));
        dispatch(fetchUsers());
    }

    const handlerChangeUsersPerPage = (usersPerPage: number) => {
        dispatch(setUsersPerPage(usersPerPage));
        dispatch(setActivePage(1));
        dispatch(fetchUsers());
        dispatch(fetchUsersCount());
    }


    const onChangeRole = (role: string, index: number) => {
        dispatch(setUserRole(role, index));
        dispatch(changeUserRole(index));
    }

    const onCheck = (index: number) => {
        dispatch(setChecked(true, index));
        dispatch(setCheckedUserDB(index));
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel user-list mt-5">
                    <EditorUsersTableFilters />
                    <div className="control_panel__body">
                        <table className="tbl">
                            <tr className="tbl__row_header">
                                <th className="tbl__header_cell checkbox-column">
                                    <div className="form__checkbox_wrap">
                                        <input checked={checkboxValue ? true : false} type="checkbox" className="form__checkbox_input grey-checkbox no-margin" />
                                        <label onClick={() => setCheckboxValue(!checkboxValue)} className="form__checkbox_input_mark" htmlFor={`c${uniqueId()}`}></label>
                                    </div>
                                </th>
                                <th className="tbl__header_cell avatar-column"></th>
                                <th className="tbl__header_cell">ФИО</th>
                                <th className="tbl__header_cell" hide-order="2">Регион</th>
                                <th className="tbl__header_cell" hide-order="2">Город</th>
                                <th className="tbl__header_cell" hide-order="1">Место работы</th>
                                <th className="tbl__header_cell" hide-order="1">Должность</th>
                                <th className="tbl__header_cell" hide-order="4">E-mail</th>
                                <th className="tbl__header_cell" hide-order="3">Роль</th>
                                <th className="tbl__header_cell" hide-order="3">Статус</th>
                                <th className="tbl__header_cell" hide-order="3">Удалить пользователя</th>
                                <th className="tbl__header_cell showmore-column"></th>
                            </tr>
                            {users.map((user, index) => <EditorUsersTableItem accountRole={role} checkboxParentValue={checkboxValue} onCheck={onCheck} index={index} onChangeRole={onChangeRole} key={index} data={user} deleteUser={handlerDeleteUser} />)}
                        </table>
                    </div>
                </div>
                <Pagination itemsPerPage={filters.usersPerPage} handlerChangeItemsPerPage={handlerChangeUsersPerPage} onChangePage={onChangePage} activePage={filters.activePage} count={count} />
            </div>
        </div>

    );
}


export default EditorUsers;
