import React, { useCallback, useEffect, useState } from 'react'
import TelegramNavbar from '../TelegramNavbar';
import TelegramFilters from '../TelegramFilters/TelegramFilters';
import { useDispatch, useSelector } from 'react-redux';
import { telegramChannelActions, fetchExcel, fetchSubcultures, fetchTags, fetchTelegramChannels, fetchTelegramChannelsCount } from '../../../redux/telegram/channels/actions';
import TelegramChannelsItem from './TelegramChannelsItem';
import Pagination from "../../Pagination/Pagination";
import { RootStateType } from '../../../redux/reducers';
import _ from 'lodash';

const { clearFilters, setKeyword, setSubculture, setTag, setTelegramChannelsLoading, setTelegramChannelsPage, setTelegramChannelsPerPage } = telegramChannelActions

const TelegramChannels: React.FC = () => {
    const dispatch = useDispatch();
    const { loaded, loading, channels, filters, count, excel, subcultures, tags } = useSelector((state: RootStateType) => state.telegramChannelsReducer);
    const { subculture, tag, keyword } = filters;
    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setTelegramChannelsPage(1));
        dispatch(fetchTelegramChannels());
        dispatch(fetchTelegramChannelsCount());
        dispatch(fetchExcel());
    }, 500), [])

    const handlerApplyFilters = (): void => {
        dispatch(setTag(tag));
        dispatch(setSubculture(subculture));
        dispatch(setTelegramChannelsPage(1))
        dispatch(fetchTelegramChannels());
        dispatch(fetchTelegramChannelsCount());
        dispatch(fetchExcel());
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
    }

    const onChangePage = (page: number): void => {
        dispatch(setTelegramChannelsPage(page));
        dispatch(fetchTelegramChannels());
    }

    const handlerChangeChannelsPerPage = (channelsPerPage: number): void => {
        dispatch(setTelegramChannelsPerPage(channelsPerPage));
        dispatch(setTelegramChannelsPage(1));
        dispatch(fetchTelegramChannels());
        dispatch(fetchTelegramChannelsCount());
    }

    const handlerChangeKeyword = (keyword: string) => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(fetchTelegramChannels());
            dispatch(fetchTelegramChannelsCount());
            dispatch(setTelegramChannelsLoading(true));
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
        }
    });



    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <TelegramNavbar />
                    <TelegramFilters tag={tag} keyword={keyword} setSubculture={(subculture) => dispatch(setSubculture(subculture))}
                        setTag={(tag) => dispatch(setTag(tag))}
                        subculture={subculture} setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters}
                        clearFilters={handlerClearFilters} excel={excel} subcultures={subcultures} tags={tags} />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="channels">
                            <table className="tbl">
                                <thead>
                                    <tr className="tbl__row_header">
                                        <th className="tbl__header_cell" colSpan={2}>Название</th>
                                        <th className="tbl__header_cell" hide-order="1">Краткое описание</th>
                                        <th className="tbl__header_cell" hide-order="2">Кол-во подпичиков</th>
                                        <th className="tbl__header_cell" hide-order="2">Кол-во публикаций</th>
                                        <th className="tbl__header_cell" hide-order="2">Кол-во просмотров</th>
                                        <th className="tbl__header_cell" hide-order="3">Теги</th>
                                        <th className="tbl__header_cell" hide-order="3">Субкультура</th>
                                        <th className="tbl__header_cell" hide-order="3">Проверено администратором</th>
                                        <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {channels.map((data, index) => <TelegramChannelsItem data={data} key={index} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination count={count} onChangePage={onChangePage} itemsPerPage={filters.channelsPerPage} handlerChangeItemsPerPage={handlerChangeChannelsPerPage} activePage={filters.page} />
                </div>
            </div>
        </div>

    )
}

export default TelegramChannels
