import axios from "axios"

import { render } from 'react-dom';
import App from './App';

// Importing CSS
import "./assets/css/app.css";

axios.defaults.headers.post["Content-Type"] = "application/json"

render(<App />, document.getElementById('root'));

