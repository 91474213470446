import { DynamicInfographicsDataType, StaticInfographicsDataType } from './../../../types/infographics.d';
import { TiktokInfographicsActionTypes } from './actions';
const defaultState = {
    users: {
        static: {} as StaticInfographicsDataType,
        dynamic: {
            datasets: {},
            labels: []
        } as DynamicInfographicsDataType
    },
    posts: {
        static: {} as StaticInfographicsDataType,
        dynamic: {
            datasets: {},
            labels: []
        } as DynamicInfographicsDataType
    },
    loaded: false,
}

type TiktokInfographicsStateType = typeof defaultState

export const tiktokInfographicsReducer = (state = defaultState, action: TiktokInfographicsActionTypes): TiktokInfographicsStateType => {
    switch (action.type) {
        case "TIKTOK_INFOGRAPHICS_SET_STATIC_USERS":
            return {
                ...state,
                users: {
                    ...state.users,
                    static: action.staticUsersInfographics
                }
            }
        case "TIKTOK_INFOGRAPHICS_SET_STATIC_POSTS":
            return {
                ...state,
                posts: {
                    ...state.posts,
                    static: action.staticPostsInfographics
                }
            }
        case "TIKTOK_INFOGRAPHICS_SET_DYNAMIC_USERS":
            return {
                ...state,
                users: {
                    ...state.users,
                    dynamic: action.dynamicUsersInfographics
                }
            }
        case "TIKTOK_INFOGRAPHICS_SET_DYNAMIC_POSTS":
            return {
                ...state,
                posts: {
                    ...state.users,
                    dynamic: action.dynamicPostsInfographics
                }
            }
        case "TIKTOK_INFOGRAPHICS_SET_LOADED":
            return {
                ...state,
                loaded: action.loaded

            }

        default:
            return state
    }
}