import React, { useEffect, useState } from 'react'
import $ from "jquery"
import Calendar from 'react-calendar';

type PropsType = {
    subculture: string
    tag: string
    keyword: string
    excel: File | null
    subcultures: Array<string>
    tags: Array<string>
    setSubculture: (subculture: string) => void
    setTag: (tag: string) => void
    setKeyword: (keyword: string) => void
    applyFilters: () => void
    clearFilters: () => void
}

const TikTokFilters: React.FC<PropsType> = ({ subculture, tag, keyword, setSubculture, setTag,
    setKeyword, applyFilters, clearFilters, excel, subcultures, tags }) => {
    const [firstDate, setFirstDate] = useState<Date | null>(null);
    const [secondDate, setSecondDate] = useState<Date | null>(null);
    const [isFirstCalendarOpen, toggleFirstCalendar] = useState<boolean>(false);
    const [isSecondCalendarOpen, toggleSecondCalendar] = useState<boolean>(false);
    const [isFiltersActive, setIsFiltersActive] = useState<boolean>(false)

    const onDownoloadXls = () => {
        if (!excel) {
            return false;
        }
        const a = document.createElement("a");
        a.download = "excel.xlsx";
        a.href = URL.createObjectURL(excel);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    return (
        <div className="control-panel__header">
            <form className="form form_row" onSubmit={e => {
                e.preventDefault();
                return false;
            }}>
                <input type="text" className="form__row_item form__row_input search-input flexgrow-3" placeholder="Поиск по ключевому слову или фразе" value={keyword} onChange={e => setKeyword(e.target.value)} />
                <div className="form__row_item control-panel__header_filter-wrap grow-0">
                    <button onClick={() => setIsFiltersActive(!isFiltersActive)} type="button" className="button control-panel__header_filter-button button_icon tiktok"><span className="d-none d-md-inline">Фильтры</span></button>
                    {isFiltersActive &&
                        <div className="control-panel__header_filter-body">
                            <div className="form__block mb-3">
                                <div className="form__block_label light-style">Период мониторинга</div>
                                <div className="row">
                                    <div className="col-6"><input type="text" onClick={e => {
                                        toggleFirstCalendar(!isFirstCalendarOpen)
                                        toggleSecondCalendar(false)
                                    }} value={firstDate?.toLocaleDateString()} className="form__block_input light-style" placeholder="От" /></div>
                                    <div className="col-6"><input type="text" onClick={e => {
                                        toggleSecondCalendar(!isSecondCalendarOpen)
                                        toggleFirstCalendar(false)
                                    }} value={secondDate?.toLocaleDateString()} className="form__block_input light-style" placeholder="До" /></div>
                                </div>
                            </div>
                            {isFirstCalendarOpen && !isSecondCalendarOpen ?
                                <div className="form__block mb-3">
                                    <Calendar value={firstDate} onChange={(e: Date) => {
                                        toggleFirstCalendar(false)
                                        if (secondDate && e > secondDate) {
                                            return false;
                                        }
                                        setFirstDate(e)
                                    }} />
                                </div> : null}
                            {isSecondCalendarOpen && !isFirstCalendarOpen ?
                                <div className="form__block mb-3">
                                    <Calendar value={secondDate} onChange={(e: Date) => {
                                        toggleSecondCalendar(false)
                                        if (firstDate && e < firstDate) {
                                            return false;
                                        }
                                        setSecondDate(e)
                                    }} />
                                </div> : null}
                            <div className="form__block mb-3">
                                <div className="form__block_label light-style">Субкультура</div>
                                <select className="form__block_select light-style" onChange={(e) => setSubculture(e.target.value)} value={subculture} placeholder="Введите вашу фамилию">
                                    <option value="-"></option>
                                    {subcultures?.map(item => <option value={item} key={item}>{item}</option>)}
                                </select>
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label light-style">Тег</div>
                                <select value={tag} onChange={e => setTag(e.target.value)} className="form__block_select light-style" placeholder="Введите вашу фамилию">
                                    <option value="-"></option>
                                    {tags?.map(item => <option value={item} key={item}>{item}</option>)}
                                </select>
                            </div>

                            <div className="form__block mb-4" onClick={applyFilters}>
                                <a href="javascript:;" className="button button_darkgreen button_fwidth">Применить фильтры</a>
                            </div>
                            <div className="form__block" onClick={clearFilters}>
                                <a href="javascript:;" className="button button_white button_fwidth">Сбросить фильтры</a>
                            </div>

                        </div>
                    }
                </div>
                {excel ?
                    <div className="form__row_item grow-0">
                        <button onMouseDown={onDownoloadXls} type="submit" className="button button_darkgreen button_icon icon-download"><span className="d-none d-md-inline-block">Сохранить Excel файл</span></button>
                    </div>
                    :
                    null}
            </form>
        </div>
    )
}

export default TikTokFilters
