import { SocialCountsType } from './../../types/socials.d';
import { SubcultureType } from './../../types/subcultures';
import { TagActionTypes } from './../tags/actions';
import { RootStateType } from './../reducers';
import { ThunkAction } from 'redux-thunk';
import axios from "axios";
import { InferValueTypes } from "../../types/common";
import { tagActions } from "../tags/actions";
const { setLoaded: setLoadedTags, setLoading: setLoadingTags } = tagActions


export const subcultureActions = {
    setSubcultures: (subcultures: Array<SubcultureType>) => ({
        type: "SUBCULTURES_SET_SUBCULTURES" as const,
        subcultures
    }),

    setLoaded: (loaded: boolean) => ({
        type: "SUBCULTURES_SET_LOADED" as const,
        loaded
    }),
    setLoading: (loading: boolean) => ({
        type: "SUBCULTURES_SET_LOADING" as const,
        loading
    }),
    setSubculturesCount: (count: number) => ({
        type: "SUBCULTURES_SET_SUBCULTURES_COUNT" as const,
        count
    }),

    setSubculturesPage: (page: number) => ({
        type: "SUBCULTURES_SET_PAGE" as const,
        page
    }),

    setSubculturesPerPage: (adminsPerPage: number) => ({
        type: "SUBCULTURES_SET_SUBCULTURES_PER_PAGE" as const,
        adminsPerPage
    }),

    setSubculture: (subculture: string) => ({
        type: "SUBCULTURES_SET_FILTERS_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "SUBCULTURES_SET_FILTERS_TAG" as const,
        tag
    }),

    setKeyword: (keyword: string) => ({
        type: "SUBCULTURES_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "SUBCULTURES_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "SUBCULTURES_SET_EXCEL" as const,
        blob
    }),

    setFiltersSubcultures: (subcultures: Array<string>) => ({
        type: "SUBCULTURES_SET_FILTERS_SUBCULTURES" as const,
        subcultures
    }),

    setFiltersTags: (tags: Array<string>) => ({
        type: "SUBCULTURES_SET_FILTERS_TAGS" as const,
        tags
    }),

    setSubculturesSocCount: (count: SocialCountsType) => ({
        type: "SUBCULTURES_SET_COUNT" as const,
        count
    }),

    setNewSubcultureAbout: (about: string) => ({
        type: "SUBCULTURES_SET_NEW_SUBCULTURE_ABOUT" as const,
        about
    }),

    setNewSubcultureName: (subcultureName: string) => ({
        type: "SUBCULTURES_SET_NEW_SUBCULTURE_NAME" as const,
        subcultureName
    }),
    clearSubcultureForm: () => ({
        type: "SUBCULTURES_NEW_SUBCULTURE_CLEAR_SUBCULTURE_FORM" as const
    })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {

    const filters = getState().subculturesReducer.filters;

    axios.post<Array<SubcultureType>>('/api/subcultures/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(subcultureActions.setSubcultures(data))
        dispatch(subcultureActions.setLoaded(true))
        dispatch(subcultureActions.setLoading(false))
        axios.get<SocialCountsType>("/api/subcultures/social-counts").then(({ data }) => {
            dispatch(subcultureActions.setSubculturesSocCount(data));
        })
    })
}

export const fetchSubculturesCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().subculturesReducer.filters;

    axios.post<number>("/api/subcultures/get-subcultures-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(subcultureActions.setSubculturesCount(data));
    })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().subculturesReducer.filters;
    axios.request<File>({url: "/api/subcultures/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob"})
        .then(response => {
            ;
            dispatch(subcultureActions.setExcel(response.data));
        })
}

export const fetchFiltersSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(subcultureActions.setFiltersSubcultures(data));
    })
};

export const fetchFiltersTags = (): ThunkActionType => (dispatch, getState) => {
    // axios.post("/api/subcultures/get-subcultures").then(({ data }) => {
    //     const subcultures = data.map(item => item.tag);
    //     dispatch(setFiltersTags(subcultures));
    // })
}

export const saveSubculture = (): ThunkActionType => (dispatch, getState) => {
    const data = {
        subcultureName: getState().subculturesReducer.newSubculture.subculture,
        subcultureDescription: getState().subculturesReducer.newSubculture.about
    }

    axios.post('/api/subcultures/save-subculture', JSON.stringify(data)).then(() => {
        dispatch(subcultureActions.clearSubcultureForm())
        dispatch(subcultureActions.setLoading(true))
        dispatch(fetchSubcultures())
        dispatch(fetchSubculturesCount());
        dispatch(fetchFiltersTags());
        dispatch(fetchFiltersSubcultures());
        dispatch(fetchExcel());
        dispatch(setLoadingTags(false))
        dispatch(setLoadedTags(false))
    })
}

export const deleteSubculture = (subculture: SubcultureType): ThunkActionType => (dispatch) => {
    axios.post("/api/subcultures/delete-subculture", JSON.stringify(subculture)).then(() => {
        dispatch(subcultureActions.setLoading(true))
        dispatch(subcultureActions.setSubculturesPage(1))
        dispatch(fetchSubcultures())
        dispatch(fetchSubculturesCount())
        dispatch(fetchExcel())
        dispatch(fetchFiltersSubcultures())
        dispatch(setLoadingTags(false))
        dispatch(setLoadedTags(false))

    })
}

export type SubcultureActionTypes = ReturnType<InferValueTypes<typeof subcultureActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, SubcultureActionTypes | TagActionTypes>