import React, { useState } from 'react'
import { deleteTag } from "../../../redux/tags/actions";
import { ThunkDispatchType } from '../../../types/common';
import { SocialTypes } from '../../../types/socials';
import { TagType } from '../../../types/tags';

type PropsType = {
    data: TagType
    role: string
    count: {
        [key in SocialTypes]: number
    }
    dispatch: ThunkDispatchType
}

const TagsItem: React.FC<PropsType> = ({ data, role, count, dispatch }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)

    return (
        <tr className="tbl__row">
            <td className={`tbl__cell ${data.tag ? "" : "empty-cell"}`} >
                {data.tag}
            </td>
            <td className={`tbl__cell ${data.about ? "" : "empty-cell"}`} hide-order="1">
                <div className="lineclamp-3">
                    {data.about}
                </div>
            </td>
            <td className="tbl__cell" hide-order="2">
                <div className="number-format">{count && count["telegram"]}</div>
            </td>
            <td className="tbl__cell" hide-order="2">
                <div className="number-format">{count && count["vk"]}</div>
            </td>

            <td className="tbl__cell" hide-order="2">
                <div className="number-format">{count && count["instagram"]}</div>
            </td>
            <td className="tbl__cell" hide-order="2">
                <div className="number-format">{count && count["tiktok"]}</div>
            </td>
            {role === "ROLE_ADMIN" &&
                <td className="tbl__cell" hide-order="1">
                    <button onClick={() => dispatch(deleteTag(data))} className="btn btn-danger">Удалить</button>
                </td>
            }
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default TagsItem
