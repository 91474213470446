import React, { useEffect } from 'react'

type PropsType = {
    count: number
    activePage: number
    itemsPerPage: number
    handlerChangeItemsPerPage: (itemsPerPage: number) => void
    onChangePage: (page: number) => void
}

function tableAdapt() {
    $('.tbl').each(function () {
        $(this).find(".tbl__header_cell").removeClass("d-none");
        $(this).find(".tbl__cell").removeClass("d-none");
        $(this).find(".tbl__row-mob").remove();
        $(this).find('.showmore-mob').css('display', 'none');
        // @ts-ignore
        while ($(this).width() > $(this).parent().width()) {
            var order = $(this).find('.tbl__header_cell[hide-order]').not('.d-none').map(function () {
                return $(this).attr("hide-order");
            }).get();
            if (order.length == 0) break;
            // @ts-ignore
            order = Math.min.apply(Math, order);

            let cols = $(this).find('.tbl__header_cell').not('.d-none').length;
            if (cols <= 1) break;
            // @ts-ignore

            var headers = [];
            // @ts-ignore
            var cells = [];
            let lastTh = $(this).find('.tbl__header_cell[hide-order=' + order + ']').not('.d-none');
            lastTh.each(function (index) {
                headers[index] = $(this).html();
            });
            lastTh.addClass('d-none');

            $(this).find('.tbl__row').each(function () {
                let lastCell = $(this).children('.tbl__cell[hide-order=' + order + ']').not('.d-none');

                lastCell.each(function (index) {
                    cells[index] = $(this).html();
                });
                lastCell.addClass('d-none');
                if (!$(this).next().hasClass('tbl__row-mob')) {
                    let html = '<tr class="tbl__row-mob"><td colspan="99"><div class="tbl__cell-mob"></div></td></tr>';
                    $(this).after(html);
                }
                let mobCell = $(this).next('.tbl__row-mob').find('.tbl__cell-mob');
                let html = '';
                for (let i = 0; i < headers.length; i++) {
                    // @ts-ignore
                    html = '<div class="cell-mob__outer"><div class="cell-mob__header">' + headers[i] + '</div><div class="cell-mob__content">' + (cells[i] == '' ? '-' : cells[i]) + '</div></div>';
                    mobCell.append(html);
                }
            });


            $(this).find('.showmore-mob').css('display', 'table-cell');

        }
    });
}

const Pagination: React.FC<PropsType> = ({ count, onChangePage, activePage, handlerChangeItemsPerPage, itemsPerPage }) => {
    const pagesCount = Math.ceil(count / +itemsPerPage);
    useEffect(() => {
        tableAdapt()
    })

    return (
        <div className="paging">
            <div className="row align-items-center">
                <div className="col-9 col-md-3 col-lg-4">
                    <div className="paging__stat">
                        1-{itemsPerPage > count ? count : itemsPerPage} из {count}
                    </div>
                    <div className="paging__settings">
                        <span>Количество строк</span>
                        <select value={itemsPerPage} onChange={e => handlerChangeItemsPerPage(+e.target.value)} name="" id="" className="pagination__select">
                            <option value="2">2</option>
                            <option value="8">8</option>
                            <option value="16">16</option>
                            <option value="24">24</option>
                            <option value="32">32</option>
                        </select>
                    </div>
                </div>
                <div className="col-3 col-md-9 col-lg-auto">
                    <div className="paging__page_links">
                        <a href="#" className="paging__prevlink" onClick={() => {
                            if (activePage - 1 > 0) {
                                onChangePage(activePage - 1)
                            }
                        }
                        }>
                            <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7071 1.70711C11.0976 1.31658 11.0976 0.683417 10.7071 0.292893C10.3166 -0.0976311 9.68342 -0.0976311 9.29289 0.292893L10.7071 1.70711ZM1 10L0.292893 9.29289C-0.0976311 9.68342 -0.0976311 10.3166 0.292893 10.7071L1 10ZM9.29289 19.7071C9.68342 20.0976 10.3166 20.0976 10.7071 19.7071C11.0976 19.3166 11.0976 18.6834 10.7071 18.2929L9.29289 19.7071ZM9.29289 0.292893L0.292893 9.29289L1.70711 10.7071L10.7071 1.70711L9.29289 0.292893ZM0.292893 10.7071L9.29289 19.7071L10.7071 18.2929L1.70711 9.29289L0.292893 10.7071Z" fill="#036B84" />
                            </svg>
                        </a>
                        <a href="javascript:;" onClick={e => onChangePage(1)} className={`paging__pagelink ${activePage == 1 ? "active" : null}`}>1</a>
                        {pagesCount > 1
                            ?
                            <a href="javascript:;" onClick={e => onChangePage(2)} className={`paging__pagelink ${activePage == 2 ? "active" : null}`}>2</a>
                            :
                            null
                        }
                        {activePage - 2 > 2
                            ?
                            <span className="pagelink_dots">...</span>
                            :
                            null
                        }
                        {activePage > 3 && activePage < pagesCount
                            ?
                            <a href="javascript:;" onClick={e => onChangePage(activePage - 1)} className="paging__pagelink">{activePage - 1}</a>
                            :
                            null
                        }
                        {activePage > 2 && activePage < pagesCount - 1
                            ?
                            <a href="javascript:;" onClick={e => onChangePage(activePage)} className="paging__pagelink active">{activePage}</a>
                            :
                            null
                        }
                        {activePage < pagesCount - 2 && activePage > 1
                            ?
                            <a href="javascript:;" onClick={e => onChangePage(activePage + 1)} className="paging__pagelink">{activePage + 1}</a>
                            :
                            null
                        }
                        {activePage + 2 < pagesCount - 1
                            ?
                            <span className="pagelink_dots">...</span>
                            :
                            null
                        }

                        {pagesCount > 3
                            ?
                            <a href="javascript:;" onClick={e => onChangePage(pagesCount - 1)} className={`paging__pagelink ${activePage == pagesCount - 1 ? "active" : ""}`}>{pagesCount - 1}</a>
                            :
                            null
                        }
                        {pagesCount > 2
                            ?
                            <a href="javascript:;" onClick={e => onChangePage(pagesCount)} className={`paging__pagelink ${activePage == pagesCount ? "active" : ""}`}>{pagesCount}</a>
                            :
                            null
                        }

                        <a href="javascript:;" className="paging__nextlink" onClick={() => {
                            if (activePage + 1 <= pagesCount) {
                                onChangePage(activePage + 1)
                            }
                        }
                        }>
                            <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L0.292893 1.70711ZM10 10L10.7071 9.29289C11.0976 9.68342 11.0976 10.3166 10.7071 10.7071L10 10ZM1.70711 19.7071C1.31658 20.0976 0.683417 20.0976 0.292893 19.7071C-0.0976315 19.3166 -0.0976315 18.6834 0.292893 18.2929L1.70711 19.7071ZM1.70711 0.292893L10.7071 9.29289L9.29289 10.7071L0.292893 1.70711L1.70711 0.292893ZM10.7071 10.7071L1.70711 19.7071L0.292893 18.2929L9.29289 9.29289L10.7071 10.7071Z" fill="#036B84" />
                            </svg>

                        </a>
                    </div>
                </div>
                <div className="col-4"></div>
            </div>
        </div >
    )
}

export default Pagination

