import { StaticInfographicsDataType, DynamicInfographicsDataType } from './../../../types/infographics.d';
import { telegramInfographicsActionTypes } from './actions';
const defaultState = {
    channels: {
        static: {} as StaticInfographicsDataType,
        dynamic: {
            datasets: {},
            labels: []
        } as DynamicInfographicsDataType
    },
    posts: {
        static: {} as StaticInfographicsDataType,
        dynamic: {
            datasets: {},
            labels: []
        } as DynamicInfographicsDataType
    },
    loaded: false,
}

type TelegramInfographicsStateType = typeof defaultState

export const telegramInfographicsReducer = (state = defaultState, action: telegramInfographicsActionTypes): TelegramInfographicsStateType => {
    switch (action.type) {
        case "TELEGRAM_INFOGRAPHICS_SET_STATIC_CHANNELS":
            return {
                ...state,
                channels: {
                    ...state.channels,
                    static: action.staticChannelsInfographics
                }
            }
        case "TELEGRAM_INFOGRAPHICS_SET_STATIC_POSTS":
            return {
                ...state,
                posts: {
                    ...state.posts,
                    static: action.staticPostsInfographics
                }
            }
        case "TELEGRAM_INFOGRAPHICS_SET_DYNAMIC_CHANNELS":
            return {
                ...state,
                channels: {
                    ...state.channels,
                    dynamic: action.dynamicChannelsInfographics
                }
            }
        case "TELEGRAM_INFOGRAPHICS_SET_DYNAMIC_POSTS":
            return {
                ...state,
                posts: {
                    ...state.posts,
                    dynamic: action.dynamicPostsInfographics
                }
            }
        case "TELEGRAM_INFOGRAPHICS_SET_LOADED":
            return {
                ...state,
                loaded: action.loaded

            }

        default:
            return state
    }
}