import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import exitImg from "./../../assets/images/menu_icon__exit.svg";
import moderImg from "./../../assets/images/menu_icon__moder.svg";
import imagesImg from "./../../assets/images/menu_icon__images.svg";
import instagramImg from "./../../assets/images/menu_icon__instagram.svg";
import tikTokImg from "./../../assets/images/menu_icon__tiktok.svg";
import vkImg from "./../../assets/images/menu_icon__vk.svg";
import telegramImg from "./../../assets/images/menu_icon__telegram.svg";
import youtubeImg from "./../../assets/images/menu_icon__youtube.svg";
import subntagsImg from "./../../assets/images/menu_icon__subntags.svg";
import { accountActions } from '../../redux/app/account/actions';
import { Link } from 'react-router-dom';
import { RootStateType } from '../../redux/reducers';
import { useToggleSidepanel } from '../../hooks/useToggleSidepanel';

const { setUnlogin } = accountActions

const Menu: React.FC = () => {
    const dispatch = useDispatch();
    const { role: roleFullName, lastName, firstName, imgUrl, accountId } = useSelector((state: RootStateType) => state.accountReducer);
    const toggleSidepanel = useToggleSidepanel()
    const role = ["Пользователь", "Модератор", "Администратор"][["ROLE_USER", "ROLE_EDITOR", "ROLE_ADMIN"].findIndex(item => item === roleFullName)] || "Неопознанная роль";

    return (
        <div className="sidepanel__items_item d-block">
            <nav className="leftmenu">
                {["ROLE_EDITOR", "ROLE_ADMIN"].includes(roleFullName) ?
                    <>
                        <Link to="/editor" className="leftmenu__item" onClick={() => toggleSidepanel(false, null)}>
                            <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${moderImg})` }}></span>
                            <span className="leftmenu__item_name">Модерация</span>
                        </Link>
                        <div className="leftmenu__separator"></div>

                    </>
                    :
                    null}
                <Link to="/vk" className="leftmenu__item" onClick={() => toggleSidepanel(false, null)}>
                    <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${vkImg})` }}></span>
                    <span className="leftmenu__item_name">Vkontakte</span>
                </Link>
                <Link to="/inst" className="leftmenu__item" onClick={() => toggleSidepanel(false, null)}>
                    <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${instagramImg})` }}></span>
                    <span className="leftmenu__item_name">Instagram</span>
                </Link>
                <Link to="/images" className="leftmenu__item" onClick={() => toggleSidepanel(false, null)}>
                    <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${imagesImg})` }}></span>
                    <span className="leftmenu__item_name">Изображения</span>
                </Link>
                <Link to="/tik-tok" className="leftmenu__item" onClick={() => toggleSidepanel(false, null)}>
                    <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${tikTokImg})` }}></span>
                    <span className="leftmenu__item_name">TikTok</span>
                </Link>
                <Link to="/youtube" className="leftmenu__item" onClick={() => toggleSidepanel(false, null)}>
                    <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${youtubeImg})` }}></span>
                    <span className="leftmenu__item_name">Youtube</span>
                </Link>
                <Link to="/telegram" className="leftmenu__item" onClick={() => toggleSidepanel(false, null)}>
                    <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${telegramImg})` }}></span>
                    <span className="leftmenu__item_name">Telegram</span>
                </Link>
                <div className="leftmenu__separator"></div>
                <Link to="/subntags" className="leftmenu__item" onClick={() => toggleSidepanel(false, null)}>
                    <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${subntagsImg})` }}></span>
                    <span className="leftmenu__item_name">Субкультуры и теги</span>
                </Link>
                <Link to={`/editor/${accountId}`} onClick={() => toggleSidepanel(false, null)}>
                    <div className="leftmenu__profile">
                        <div className="leftmenu__profile_photo" style={{ backgroundImage: `url(${window.location.origin}/img/account/${imgUrl})` }}></div>
                        <div className="leftmenu__profile_description">
                            <div className="leftmenu__yourname">{firstName} {lastName}</div>
                            <div className="leftmenu__yourrole">{role}</div>
                        </div>
                    </div>
                </Link>
                <a href="#" onClick={() => {
                    toggleSidepanel(false, null)
                    dispatch(setUnlogin());
                    deleteCookie("id");
                    deleteCookie("jwt");
                }} className="leftmenu__item">
                    <span className="leftmenu__item_icon" style={{ backgroundImage: `url(${exitImg})` }}></span>
                    <span className="leftmenu__item_name">Выход</span>
                </a>
            </nav>
        </div>
    )
}

const deleteCookie = (name: string): void => {
    console.log(name);
    document.cookie = [
        name, '=',
        '; expires=' + new Date(0).toUTCString() + ";path=/"
    ].join('');
}

export default Menu
