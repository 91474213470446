import { SocialCountsType } from './../../types/socials.d';
import { InferValueTypes } from './../../types/common';
import { TagType } from './../../types/tags';
import { RootStateType } from "../reducers";
import axios from "axios";
import { ThunkAction } from "redux-thunk"

let headers = {
    'Content-Type': 'application/json'
}

export const tagActions = {
    setTags: (tags: Array<TagType>) => ({
        type: "TAGS_SET_TAGS" as const,
        tags
    }),
    setLoaded: (loaded: boolean) => ({
        type: "TAGS_SET_LOADED" as const,
        loaded
    }),
    setLoading: (loading: boolean) => ({
        type: "TAGS_SET_LOADING" as const,
        loading
    }),
    setTagsCount: (count: number) => ({
        type: "TAGS_SET_TAGS_COUNT" as const,
        count
    }),
    setTagsPage: (page: number) => ({
        type: "TAGS_SET_PAGE" as const,
        page
    }),
    setTagsPerPage: (tagsPerPage: number) => ({
        type: "TAGS_SET_TAGS_PER_PAGE" as const,
        tagsPerPage
    }),
    setSubculture: (subculture: string) => ({
        type: "TAGS_SET_FILTERS_SUBCULTURE" as const,
        subculture
    }),
    setTag: (tag: string) => ({
        type: "TAGS_SET_FILTERS_TAG" as const,
        tag
    }),
    setKeyword: (keyword: string) => ({
        type: "TAGS_SET_KEYWORD" as const,
        keyword
    }),
    clearFilters: () => ({
        type: "TAGS_CLEAR_FILTERS" as const
    }),
    setExcel: (blob: File) => ({
        type: "TAGS_SET_EXCEL" as const,
        blob
    }),
    setFiltersSubcultures: (subcultures: string[]) => ({
        type: "TAGS_SET_FILTERS_SUBCULTURES" as const,
        subcultures
    }),
    setFiltersTags: (tags: Array<string>) => ({
        type: "TAGS_SET_FILTERS_TAGS" as const,
        tags
    }),
    setTagsSocCount: (count: SocialCountsType) => ({
        type: "TAGS_SET_SOC_COUNT" as const,
        count
    }),
    setNewTagAbout: (about: string) => ({
        type: "TAGS_SET_NEW_TAG_ABOUT" as const,
        about
    }),
    setNewTagTag: (tag: string) => ({
        type: "TAGS_SET_NEW_TAG_TAG" as const,
        tag
    }),
    setNewTagSubculture: (subculture: string) => ({
        type: "TAGS_SET_NEW_TAG_SUBCULTURE" as const,
        subculture
    }),
    clearTagForm: () => ({
        type: "TAGS_NEW_TAG_CLEAR_TAG_FORM" as const
    })
}

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tagsReducer.filters;

    axios.post<Array<TagType>>('/api/tags/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(tagActions.setTags(data))
        dispatch(tagActions.setLoaded(true))
        dispatch(tagActions.setLoading(false))
        axios.get<SocialCountsType>("/api/tags/social-counts").then(({ data }) => {
            dispatch(tagActions.setTagsSocCount(data));
        })
    })
}

export const saveTag = (tag: string): ThunkActionType => (dispatch, getState) => {
    axios.post<number>('/editor/save-tags', JSON.stringify(tag)).then(({ data }) => {
        if (data === 200) {
            alert("Успешно сохранено!")
        } else {
            alert("Ошибка!")
        }

        dispatch(tagActions.setLoaded(false))
        dispatch(tagActions.setLoading(false))
    }).catch(({ body }) => {
        alert("Ошибка!")
    })
}

export const fetchTagsCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tagsReducer.filters;

    axios.post<number>("/api/tags/get-tags-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(tagActions.setTagsCount(data));
    })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tagsReducer.filters;
    axios.request<File>({ url: "/api/tags/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob" })
        .then(response => {
            dispatch(tagActions.setExcel(response.data));
        })
}

export const fetchFiltersSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(tagActions.setFiltersSubcultures(data));
        dispatch(tagActions.setNewTagSubculture(data[0]))
    })
};

export const fetchFiltersTags = (): ThunkActionType => (dispatch, getState) => {
    axios.post<Array<TagType>>("/api/tags/get-tags").then(({ data }) => {
        const tags = data.map(item => item.tag);
        dispatch(tagActions.setFiltersTags(tags));
    })
}

export const sendTagRequest = (): ThunkActionType => (dispatch, getState) => {
    const data = {
        subculture: getState().tagsReducer.newTag.subculture,
        tag: getState().tagsReducer.newTag.tag,
        about: getState().tagsReducer.newTag.about,
        ownerId: getState().accountReducer.accountId
    }


    axios.get('/api/subculture-tag/new-tag-request?subculture=' + data.subculture + "&tag=" + data.tag + "&about=" + data.about + "&owner-id=" + data.ownerId).then(() => {
        dispatch(tagActions.clearTagForm())
        dispatch(tagActions.setLoading(true))
        dispatch(fetchTags())
        dispatch(fetchTagsCount());
        dispatch(fetchFiltersTags());
        dispatch(fetchFiltersSubcultures());
        dispatch(fetchExcel());
    })
}

export const deleteTag = (tag: TagType): ThunkActionType => (dispatch) => {
    axios.post("/api/tags/delete-tag", JSON.stringify(tag), {
        headers,
    }).then(() => {
        dispatch(tagActions.setLoading(true))
        dispatch(tagActions.setTagsPage(1))
        dispatch(fetchTags())
        dispatch(fetchTagsCount())
        dispatch(fetchExcel())
        dispatch(fetchFiltersTags())
    })
}

type ThunkActionType = ThunkAction<void, RootStateType, unknown, TagActionTypes>
export type TagActionTypes = ReturnType<InferValueTypes<typeof tagActions>>