import React, { useState } from 'react'
import { VkUserType } from '../../../types/vk';

type PropsType = {
    data: VkUserType
}

const VkInterestsItem: React.FC<PropsType> = ({ data }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)
    const { firstName, lastName, thirdName, sex, age, cityName, imgUrl, regionName, countryName, groups, url, subcultureName } = data;
    const lastVisit = null;
    return (
        <tr className="tbl__row">
            <td className="tbl__cell  avatar-column">
                <img src={imgUrl} className="user-list__avatar" alt="" />
            </td>
            <td className={`tbl__cell ${firstName || lastName || thirdName ? "" : "empty-cell"}`}><a href={url} className="control-panel__link bolder">{firstName} {lastName} {thirdName}</a></td>
            <td className="tbl__cell" hide-order="2">{sex == 1 ? "М" : "Ж"}</td>
            <td className={`tbl__cell ${age ? "" : "empty-cell"}`} hide-order="2">{age ? age + " лет" : null}</td>
            <td className={`tbl__cell ${countryName ? "" : "empty-cell"}`} hide-order="1">{countryName}</td>
            <td className={`tbl__cell ${cityName ? "" : "empty-cell"}`} hide-order="1">{cityName}</td>
            <td className={`tbl__cell ${regionName ? "" : "empty-cell"}`} hide-order="1">{regionName}</td>
            <td className="tbl__cell" hide-order="4">{subcultureName}</td>
            <td className="tbl__cell" hide-order="3"><span className="number-format">{groups.length}</span> | <span className="text_success">+<span className="number-format">0</span></span> | <span className="text_success">+<span className="number-format">0</span></span></td>
            {/* <td className={`tbl__cell ${lastVisit ? "" : "empty-cell"}`} hide-order="1">{lastVisit}</td> */}
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default VkInterestsItem
