import { InfographicsDataType } from './../../../types/infographics.d';
import { SocCountType } from './../../../types/vk.d';
import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes } from './../../../types/common';
import { VkGroupType } from './../../../types/vk';
import axios from "axios";

export const vkSubscriberActions = {

    setVkGroupsLoaded: (loaded: boolean) => ({
        type: "VK_SUBSCRIBERS_SET_LOADED" as const,
        loaded
    }),

    setVkGroups: (groups: Array<VkGroupType>) => ({
        type: "VK_SUBSCRIBERS_SET_SUBSCRIBERS" as const,
        groups
    }),

    setVkGroupsLoading: (loading: boolean) => ({
        type: "VK_SUBSCRIBERS_SET_LOADING" as const,
        loading
    }),

    setVkGroupsCount: (count: number) => ({
        type: "VK_SUBSCRIBERS_SET_SUBSCRIBERS_COUNT" as const,
        count
    }),

    setVkGroupsPage: (page: number) => ({
        type: "VK_SUBSCRIBERS_SET_PAGE" as const,
        page
    }),

    setVkGroupsPerPage: (groupsPerPage: number) => ({
        type: "VK_SUBSCRIBERS_SET_SUBSCRIBERS_PER_PAGE" as const,
        groupsPerPage
    }),

    setCountries: (countries: Array<string>) => ({
        type: "VK_SUBSCRIBERS_SET_COUNTRIES" as const,
        countries
    }),

    setCities: (cities: Array<string>) => ({
        type: "VK_SUBSCRIBERS_SET_CITIES" as const,
        cities
    }),

    setRegions: (regions: Array<string>) => ({
        type: "VK_SUBSCRIBERS_SET_REGIONS" as const,
        regions
    }),

    setRegion: (region: string) => ({
        type: "VK_SUBSCRIBERS_SET_REGION" as const,
        region
    }),

    setCountry: (country: string) => ({
        type: "VK_SUBSCRIBERS_SET_COUNTRY" as const,
        country
    }),

    setCity: (city: string) => ({
        type: "VK_SUBSCRIBERS_SET_CITY" as const,
        city
    }),

    setSubculture: (subculture: string) => ({
        type: "VK_SUBSCRIBERS_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "VK_SUBSCRIBERS_SET_TAG" as const,
        tag
    }),

    setMinSubscribers: (minSubscribers: number) => ({
        type: "VK_SUBSCRIBERS_SET_MIN_SUBSCRIBERS" as const,
        minSubscribers
    }),

    setMaxSubscribers: (maxSubscribers: number) => ({
        type: "VK_SUBSCRIBERS_SET_MAX_SUBSCRIBERS" as const,
        maxSubscribers
    }),

    setKeyword: (keyword: string) => ({
        type: "VK_SUBSCRIBERS_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "VK_SUBSCRIBERS_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "VK_SUBSCRIBERS_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "VK_SUBSCRIBERS_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "VK_SUBSCRIBERS_SET_TAGS" as const,
        tags
    }),

    setSocCount: (socCount: SocCountType) => ({
        type: "VK_SUBSCRIBERS_SET_SOC_COUNT" as const,
        socCount
    })
}

export const fetchVkGroups = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkSubscribersReducer.filters;

    axios.post<Array<VkGroupType>>('/api/vk/subscribers/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(vkSubscriberActions.setVkGroups(data));
        dispatch(vkSubscriberActions.setVkGroupsLoaded(true));
        dispatch(vkSubscriberActions.setVkGroupsLoading(false));
    })
};

export const fetchVkGroupsCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkSubscribersReducer.filters;

    axios.post<number>("/api/vk/subscribers/get-pages-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(vkSubscriberActions.setVkGroupsCount(data));
    })
};

export const fetchCities = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/subscribers/get-cities")
        .then(({ data }) => {
            dispatch(vkSubscriberActions.setCities(data));
        })
}

export const fetchCountries = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/subscribers/get-countries")
        .then(({ data }) => {
            dispatch(vkSubscriberActions.setCountries(data));
        })
}

export const fetchRegions = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/subscribers/get-regions")
        .then(({ data }) => {
            dispatch(vkSubscriberActions.setRegions(data));
        })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkSubscribersReducer.filters;
    axios.request<File>({ url: "/api/vk/subscribers/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob" })
        .then(response => {
            ;
            dispatch(vkSubscriberActions.setExcel(response.data));
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(vkSubscriberActions.setSubcultures(data));
    })
};

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/subscribers/get-tags").then(({ data }) => {
        dispatch(vkSubscriberActions.setTags(data));
    })
}

export const fetchSocCount = (): ThunkActionType => (dispatch, getState) => {
    axios.get<InfographicsDataType>("/api/social-statistics?social=vk&entity=group&last=true").then(({ data }) => {
        dispatch(vkSubscriberActions.setSocCount({ subculturesStatistics: data[0].subculturesStatistics, date: new Date(data[0].date).toLocaleDateString("ru-RU").slice(0, 10) }))
    })
}

export type VkSubscriberActionTypes = ReturnType<InferValueTypes<typeof vkSubscriberActions>> 
type ThunkActionType = ThunkAction<void, RootStateType, unknown, VkSubscriberActionTypes>