import { getCookie } from './../../../utils/cookie';
import { AccountActionTypes } from './../account/actions';
import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes } from './../../../types/common';
import axios from "axios";
import { fetchAccountInfo, accountActions } from "../account/actions";

const { setAuthorized, setAuthToken } = accountActions

export const loginActions = {
    setEmail: (email: string) => ({
        type: "LOGIN_SET_EMAIL" as const,
        email
    }),

    setPassword: (password: string) => ({
        type: "LOGIN_SET_PASSWORD" as const,
        password
    }),

    setLoading: (loading: boolean) => ({
        type: "LOGIN_SET_LOADING" as const,
        loading
    })
}

export const changePassword = (email: string): ThunkActionType => (dispatch, getState) => {
    axios.post<number>('/api/account/reset-password', email).then(({ data }) => {
        if (data === 200) {
            alert("Пароль изменён. Новый пароль отправлен вам на почту. " +
                "Войдите в личный кабинет при помощи нового пароля и " +
                "там же его измените в разделе 'Сменить пароль'")
        } else {
            alert("Введён не верный Email")
        }

    })

}

export const auth = (): ThunkActionType => (dispatch, getState) => {
    const data = {
        username: getState().loginReducer.email.trim(),
        password: getState().loginReducer.password,
    };

    dispatch(loginActions.setLoading(true))

    axios.post<{ jwt: string }>('/api/account/login', JSON.stringify(data)).then(({ data }) => {
        dispatch(setAuthToken(data.jwt))
        dispatch(loginActions.setLoading(false))
        dispatch(setAuthorized(true))
        dispatch(loginActions.setEmail(""))
        dispatch(loginActions.setPassword(""))
        console.log("Auth");
        document.cookie = "jwt=" + data.jwt + ";max-age=" + 3600 * 24 * 31 + ";path=/";
        axios.defaults.headers.common.Authorization = "Bearer " + getCookie("jwt")
        dispatch(fetchAccountInfo());
    }).catch(err => {
        console.log(err);
        dispatch(loginActions.setLoading(false))
        dispatch(loginActions.setPassword(""))
        alert("Ошибка входа! Проверьте введённые данные или же ваша учётная запись не активирована! Для активации " +
            "необходимо пройти по ссылке в сообщении, присланом вам на почту.")
    })
}

export type LoginActionTypes = ReturnType<InferValueTypes<typeof loginActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, LoginActionTypes | AccountActionTypes>
