import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { VkAdminType } from './../../../types/vk';
import axios from "axios";
import { InferValueTypes } from '../../../types/common';


export const vkAdminActions = {

    setVkAdminsLoaded: (loaded: boolean) => ({
        type: "VK_ADMINS_SET_LOADED" as const,
        loaded
    }),

    setVkAdmins: (admins: Array<VkAdminType>) => ({
        type: "VK_ADMINS_SET_ADMINS" as const,
        admins
    }),

    setVkAdminsLoading: (loading: boolean) => ({
        type: "VK_ADMINS_SET_LOADING" as const,
        loading
    }),

    setVkAdminsCount: (count: number) => ({
        type: "VK_ADMINS_SET_ADMINS_COUNT" as const,
        count
    }),

    setVkAdminsPage: (page: number) => ({
        type: "VK_ADMINS_SET_PAGE" as const,
        page
    }),

    setVkAdminsPerPage: (adminsPerPage: number) => ({
        type: "VK_ADMINS_SET_ADMINS_PER_PAGE" as const,
        adminsPerPage
    }),

    setCountries: (countries: Array<string>) => ({
        type: "VK_ADMINS_SET_COUNTRIES" as const,
        countries
    }),

    setCities: (cities: Array<string>) => ({
        type: "VK_ADMINS_SET_CITIES" as const,
        cities
    }),

    setRegions: (regions: Array<string>) => ({
        type: "VK_ADMINS_SET_REGIONS" as const,
        regions
    }),

    setRegion: (region: string) => ({
        type: "VK_ADMINS_SET_REGION" as const,
        region
    }),

    setCountry: (country: string) => ({
        type: "VK_ADMINS_SET_COUNTRY" as const,
        country
    }),

    setCity: (city: string) => ({
        type: "VK_ADMINS_SET_CITY" as const,
        city
    }),

    setSubculture: (subculture: string) => ({
        type: "VK_ADMINS_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "VK_ADMINS_SET_TAG" as const,
        tag
    }),

    setMinSubscribers: (minSubscribers: number) => ({
        type: "VK_ADMINS_SET_MIN_SUBSCRIBERS" as const,
        minSubscribers
    }),

    setMaxSubscribers: (maxSubscribers: number) => ({
        type: "VK_ADMINS_SET_MAX_SUBSCRIBERS" as const,
        maxSubscribers
    }),

    setKeyword: (keyword: string) => ({
        type: "VK_ADMINS_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "VK_ADMINS_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "VK_ADMINS_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "VK_ADMINS_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "VK_ADMINS_SET_TAGS" as const,
        tags
    })
}

export const fetchVkAdmins = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkAdminsReducer.filters;

    axios.post<Array<VkAdminType>>('/api/vk/admins/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(vkAdminActions.setVkAdmins(data))
        dispatch(vkAdminActions.setVkAdminsLoaded(true))
        dispatch(vkAdminActions.setVkAdminsLoading(false))
    })
};

export const fetchVkAdminsCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkAdminsReducer.filters;

    axios.post<number>("/api/vk/admins/get-pages-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(vkAdminActions.setVkAdminsCount(data))
    })
}

export const fetchCities = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-cities")
        .then(({ data }) => {
            dispatch(vkAdminActions.setCities(data))
        })
}

export const fetchCountries = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-countries")
        .then(({ data }) => {
            dispatch(vkAdminActions.setCountries(data))
        })
}

export const fetchRegions = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-regions")
        .then(({ data }) => {
            dispatch(vkAdminActions.setRegions(data))
        })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkAdminsReducer.filters;
    axios.request<File>({
        url: "/api/vk/admins/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob"
    })
        .then(response => {
            dispatch(vkAdminActions.setExcel(response.data))
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(vkAdminActions.setSubcultures(data))
    })
};

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/admins/get-tags").then(({ data }) => {
        dispatch(vkAdminActions.setTags(data))
    })
}

export type VkAdminActionTypes = ReturnType<InferValueTypes<typeof vkAdminActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, VkAdminActionTypes>