import { InfographicsDataType, DynamicInfographicsDataType,  StaticInfographicsDataType } from './../../../types/infographics.d';
import { getLineChartData } from './../../../utils/charts';
import axios from 'axios';
import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes, ThunkDispatchType } from '../../../types/common';
import _ from 'lodash';

export const tiktokInfographicsActions = {
    setStaticUsersInfographics: (staticUsersInfographics: StaticInfographicsDataType) => ({
        type: "TIKTOK_INFOGRAPHICS_SET_STATIC_USERS" as const,
        staticUsersInfographics
    }),
    setStaticPostsInfographics: (staticPostsInfographics: StaticInfographicsDataType) => ({
        type: "TIKTOK_INFOGRAPHICS_SET_STATIC_POSTS" as const,
        staticPostsInfographics
    }),
    setDynamicPostsInfographics: (dynamicPostsInfographics: DynamicInfographicsDataType) => ({
        type: "TIKTOK_INFOGRAPHICS_SET_DYNAMIC_POSTS" as const,
        dynamicPostsInfographics
    }),
    setDynamicUsersInfographics: (dynamicUsersInfographics: DynamicInfographicsDataType) => ({
        type: "TIKTOK_INFOGRAPHICS_SET_DYNAMIC_USERS" as const,
        dynamicUsersInfographics
    }),
    setLoaded: (loaded: boolean) => ({
        type: "TIKTOK_INFOGRAPHICS_SET_LOADED" as const,
        loaded
    })
}

export const _fetchStaticInfographicsUsers = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=tiktok&entity=user&last=true")
    dispatch(tiktokInfographicsActions.setStaticUsersInfographics(data[0].subculturesStatistics))
}

export const _fetchStaticInfographicsPosts = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=tiktok&entity=post&last=true")
    dispatch(tiktokInfographicsActions.setStaticPostsInfographics(data[0].subculturesStatistics))
}

export const _fetchDynamicInfographicsUsers = async (dispatch: ThunkDispatchType) => {
    const { data: dynamicData } = await axios.get<InfographicsDataType>("/api/social-statistics?social=tiktok&entity=user")
    dispatch(tiktokInfographicsActions.setDynamicUsersInfographics(getLineChartData(dynamicData)))
}

export const _fetchDynamicInfographicsPosts = async (dispatch: ThunkDispatchType) => {
    const { data: dynamicData } = await axios.get<InfographicsDataType>("/api/social-statistics?social=tiktok&entity=post")
    dispatch(tiktokInfographicsActions.setDynamicPostsInfographics(getLineChartData(dynamicData)))
}

export const fetchInfographics = (): ThunkActionType => async (dispatch) => {
    await Promise.all([
        _fetchStaticInfographicsUsers(dispatch),
        _fetchStaticInfographicsPosts(dispatch),
        _fetchDynamicInfographicsPosts(dispatch),
        _fetchDynamicInfographicsUsers(dispatch)
    ])
    dispatch(tiktokInfographicsActions.setLoaded(true))
}


type ThunkActionType = ThunkAction<void, RootStateType, unknown, TiktokInfographicsActionTypes>
export type TiktokInfographicsActionTypes = ReturnType<InferValueTypes<typeof tiktokInfographicsActions>>