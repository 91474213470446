import React from 'react'
import { Link } from 'react-router-dom'

const AppFooter: React.FC = () => {
    return (
        <footer className="footer side-padding-main">
            <div className="footer__left">
                <Link to="/" className="footer__logo footer_logo_main">
                    Destruct analysis
                </Link>
            </div>
            <div className="footer__right">
            </div>
        </footer>
    )
}

export default AppFooter
