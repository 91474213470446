import React, { useState } from 'react'

type PropsType = {
    data: {
        fileName: string
        tags: string
        about: string
    }
}

const ImagesItem: React.FC<PropsType> = ({ data }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)

    return (
        <tr className="tbl__row">
            <td className="tbl__cell thumb-column">
                <img className="table__thumb-69x46" src={`img/subculture/${data.fileName}`} alt="" />
            </td>
            <td className="tbl__cell">
                <div className="lineclamp-3">{data.about}</div>
            </td>
            <td className={`tbl__cell ${data.tags ? "" : "empty-cell"}`} hide-order="1">{data.tags}</td>
            <td className="tbl__cell" hide-order="1">Оффники</td>
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default ImagesItem
