import { RecoveryActionTypes } from "./actions"

const defaultState = {
    email: "",
    status: false
};

type RecoveryStateType = typeof defaultState

export const recoveryReducer = (state = defaultState, action: RecoveryActionTypes): RecoveryStateType => {
    switch (action.type) {
        case "RECOVERY_SET_EMAIL":
            return {
                ...state,
                email: action.email
            }
        case "RECOVERY_SET_STATUS":
            return {
                ...state,
                status: action.status
            }
        default:
            return state;

    }
};