import React, { useCallback, useEffect, useState } from 'react'
import Sidepanel from '../../Sidepanel/Sidepanel'
import VkNavBar from '../VkNavBar/VkNavBar'
import Pagination from "../../Pagination/Pagination";
import VkFilters from '../VkFilters/VkFilters';
import VkSubscribersItem from './VkSubscribersItem';
import { useDispatch, useSelector } from 'react-redux';
import $ from "jquery";
import { vkSubscriberActions, fetchExcel, fetchSubcultures, fetchTags, fetchSocCount, fetchCities, fetchCountries, fetchRegions, fetchVkGroups, fetchVkGroupsCount } from '../../../redux/vk/subscribers/actions';
import { RootStateType } from '../../../redux/reducers';
import { TimerType } from '../../../types/common';
import _ from 'lodash';

const { setVkGroupsLoading, setVkGroupsPage, setVkGroupsPerPage, clearFilters, setCity, setCountry, setRegion, setKeyword, setTag, setSubculture, setMaxSubscribers, setMinSubscribers } = vkSubscriberActions

const VkSubscribers: React.FC = () => {
    const dispatch = useDispatch();
    const { loaded, loading, groups, filters, count, cities, regions, countries, excel, subcultures, tags, socCount } = useSelector((state: RootStateType) => state.vkSubscribersReducer);
    const { subculture, tag, country, region, city, membersCountMin, membersCountMax, name } = filters;
    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setVkGroupsPage(1))
        dispatch(fetchVkGroups());
        dispatch(fetchVkGroupsCount());
        dispatch(fetchExcel());
        dispatch(fetchSocCount());
    }, 500), [])

    const handlerApplyFilters = (): void => {

        dispatch(setVkGroupsPage(1))
        dispatch(fetchVkGroups());
        dispatch(fetchVkGroupsCount());
        dispatch(fetchExcel());
        dispatch(fetchSocCount());
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
        setSubculture("-");
        setTag("-");
        setCountry("-");
        setRegion("-");
        setCity("-");
        setMinSubscribers(0);
        setMaxSubscribers(500000);
    }

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(fetchVkGroups());
            dispatch(fetchVkGroupsCount());
            dispatch(setVkGroupsLoading(true));
            dispatch(fetchCountries());
            dispatch(fetchCities());
            dispatch(fetchRegions());
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
            dispatch(fetchSocCount());
        }
    });

    const onChangePage = (page: number): void => {
        dispatch(setVkGroupsPage(page));
        dispatch(fetchVkGroups());
    }

    const handlerChangeGroupsPerPage = (groupsPerPage: number): void => {
        dispatch(setVkGroupsPerPage(groupsPerPage));
        dispatch(setVkGroupsPage(1));
        dispatch(fetchVkGroups());
        dispatch(fetchVkGroupsCount());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <VkNavBar />
                    <VkFilters tags={tags} subcultures={subcultures} cities={cities} countries={countries} regions={regions}
                        subculture={subculture} tag={tag} country={country} city={city}
                        region={region} maxSubscribers={membersCountMax} minSubscribers={membersCountMin}
                        keyword={name} setSubculture={(subculture) => dispatch(setSubculture(subculture))} setTag={(tag) => dispatch(setTag(tag))}
                        setCountry={(country) => dispatch(setCountry(country))} setCity={(city) => dispatch(setCity(city))} setRegion={(region) => dispatch(setRegion(region))}
                        setMaxSubscribers={(maxSubscribers) => dispatch(setMaxSubscribers(maxSubscribers))} setMinSubscribers={(minSubscribers) => dispatch(setMinSubscribers(minSubscribers))}
                        setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters} clearFilters={handlerClearFilters} excel={excel} />                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="communities">
                            <div className="panel-counters">
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <div className="panel-counters__name">Количество сообществ по субкультурам</div>
                                        <div className="panel-counters__date">{socCount.date}</div>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className="panel-counters__counters-area">
                                            {Object.keys(socCount.subculturesStatistics).map(subculture => {
                                                return (
                                                    <div className="panel-counters__counter">
                                                        <div className="panel-counters__counter-name"><span className="dotted-text purple">{subculture}</span></div>
                                                        <div className="panel-counters__counter-number">
                                                            <span className="number-format">{socCount.subculturesStatistics[subculture]}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="tbl vk-community-tbl">
                                <thead>
                                    <tr className="tbl__row_header">
                                        <th className="tbl__header_cell" colSpan={2}>Наименование</th>
                                        <th className="tbl__header_cell" hide-order="1">Краткое описание</th>
                                        <th className="tbl__header_cell" hide-order="4">Кол-во подписчиков</th>
                                        <th className="tbl__header_cell" hide-order="4">Кол-во записей в сообществах</th>
                                        <th className="tbl__header_cell" hide-order="3">Страна</th>
                                        <th className="tbl__header_cell" hide-order="3">Город</th>
                                        <th className="tbl__header_cell" hide-order="3">Регион</th>
                                        <th className="tbl__header_cell" hide-order="2">Субкультура</th>
                                        <th className="tbl__header_cell" hide-order="2">Теги</th>
                                        <th className="tbl__header_cell" hide-order="5">Администраторы</th>
                                        <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groups.map(group => <VkSubscribersItem data={group} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Pagination itemsPerPage={filters.groupsPerPage} handlerChangeItemsPerPage={handlerChangeGroupsPerPage} onChangePage={onChangePage} activePage={filters.page} count={count} />
            </div>
        </div>

    )
}

export default VkSubscribers
