import React from 'react'
import { NavLink, useHistory } from 'react-router-dom';

const SubnTagsNavbar: React.FC = () => {
    const navTabs = [["subcultures", "Субкультура"],
    ["tags", "Теги"]];

    const history = useHistory();

    const onChangeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        history.push(`/subntags/${e.target.value}`)
    }

    return (
        <>
            <ul className="tabbed-panel__tabs d-none d-xl-flex">
                {navTabs.map((item, index) => <NavLink to={`/subntags/${item[0]}`} key={`${item}_${index}`} className={`tabbed-panel__tab`} data-target={item[0]}>{item[1]}</NavLink>)}
            </ul>
            <select className="tabbed-panel__mob_tabs d-inline-block d-xl-none" value={window.location.href.split("/")[window.location.href.split("/").length - 1]} onChange={onChangeSelectHandler}>
                {navTabs.map((item, index) => <option key={`${item}_${index}`} value={item[0]}>{item[1]}</option>)}
            </select>
        </>
    )
}

export default SubnTagsNavbar
