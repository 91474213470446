import { TimerType } from '../../types/common';
import { SidepanelTabTypes } from './../../types/sidepanel.d';
import { SidepanelActionTypes } from './actions';
const initialState = {
    isSidepanelActive: false,
    currentSidepanelTab: null as SidepanelTabTypes,
    toggleTimer: null as null | TimerType
}

type SidepanelStateType = typeof initialState

export const sidepanelReducer = (state = initialState, action: SidepanelActionTypes): SidepanelStateType => {
    switch (action.type) {
        case "SIDEPANEL_SET_IS_SIDEPANEL_ACTIVE":
            return {
                ...state,
                isSidepanelActive: action.isSidepanelActive
            }
        case "SIDEPANEL_SET_CURRENT_TAB":
            return {
                ...state,
                currentSidepanelTab: action.sidepanelTab
            }
        case "SIDEPANEL_SET_TOGGLE_TIMER":
            return {
                ...state,
                toggleTimer: action.toggleTimer
            }
        default:
            return state
    }
}