import { getLineChartData } from './../../../utils/charts';
import axios from 'axios';
import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes, ThunkDispatchType } from '../../../types/common';
import { DynamicInfographicsDataType, InfographicsDataType, StaticInfographicsDataType } from './../../../types/infographics.d';
import _ from 'lodash';

export const vkInfographicsActions = {
    setStaticGroupsInfographics: (staticGroupsInfographicsData: StaticInfographicsDataType) => ({
        type: "VK_INFOGRAPHICS_SET_STATIC_GROUPS" as const,
        staticGroupsInfographicsData
    }),
    setStaticUsersInfographics: (staticUsersInfographicsData: StaticInfographicsDataType) => ({
        type: "VK_INFOGRAPHICS_SET_STATIC_USERS" as const,
        staticUsersInfographicsData
    }),
    setStaticAdminsInfographics: (staticAdminsInfographicsData: StaticInfographicsDataType) => ({
        type: "VK_INFOGRAPHICS_SET_STATIC_ADMINS" as const,
        staticAdminsInfographicsData
    }),
    setDynamicGroupsInfographics: (dynamicGroupsInfographicsData: DynamicInfographicsDataType) => ({
        type: "VK_INFOGRAPHICS_SET_DYNAMYC_GROUPS" as const,
        dynamicGroupsInfographicsData
    }),
    setDynamicUsersInfographics: (dynamicUsersInfographicsData: DynamicInfographicsDataType) => ({
        type: "VK_INFOGRAPHICS_SET_DYNAMYC_USERS" as const,
        dynamicUsersInfographicsData
    }),
    setDynamicAdminsInfographics: (dynamicAdminsInfographicsData: DynamicInfographicsDataType) => ({
        type: "VK_INFOGRAPHICS_SET_DYNAMIC_ADMINS" as const,
        dynamicAdminsInfographicsData
    }),
    setLoaded: (loaded: boolean) => ({
        type: "VK_INFOGRAPHICS_SET_LOADED" as const,
        loaded
    })
}

export const _fetchStaticInfographicsAdmins = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=vk&entity=admin&last=true")
    dispatch(vkInfographicsActions.setStaticAdminsInfographics(data[0].subculturesStatistics))
}

export const _fetchStaticInfographicsGroups = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=vk&entity=group&last=true")
    dispatch(vkInfographicsActions.setStaticGroupsInfographics(data[0].subculturesStatistics))
}

export const _fetchStaticInfographicsUsers = async (dispatch: ThunkDispatchType) => {
    const { data } = await axios.get<InfographicsDataType>("/api/social-statistics?social=vk&entity=user&last=true")
    dispatch(vkInfographicsActions.setStaticUsersInfographics(data[0].subculturesStatistics))
}

export const _fetchDynamicInfographicsGroups = async (dispatch: ThunkDispatchType) => {
    const { data: dynamicData } = await axios.get<InfographicsDataType>("/api/social-statistics?social=vk&entity=group")

    dispatch(vkInfographicsActions.setDynamicGroupsInfographics(getLineChartData(dynamicData)))
}

export const _fetchDynamicInfographicsUsers = async (dispatch: ThunkDispatchType) => {
    const { data: dynamicData } = await axios.get<InfographicsDataType>("/api/social-statistics?social=vk&entity=user")

    dispatch(vkInfographicsActions.setDynamicUsersInfographics(getLineChartData(dynamicData)))
}

export const _fetchDynamicInfographicsAdmins = async (dispatch: ThunkDispatchType) => {
    const { data: dynamicData } = await axios.get<InfographicsDataType>("/api/social-statistics?social=vk&entity=admin")

    dispatch(vkInfographicsActions.setDynamicAdminsInfographics(getLineChartData(dynamicData)))
}

export const fetchInfographics = (): ThunkActionType => async (dispatch) => {
    await Promise.all([
        _fetchStaticInfographicsGroups(dispatch),
        _fetchStaticInfographicsUsers(dispatch),
        _fetchStaticInfographicsAdmins(dispatch),
        _fetchDynamicInfographicsGroups(dispatch),
        _fetchDynamicInfographicsUsers(dispatch),
        _fetchDynamicInfographicsAdmins(dispatch) 
    ])
    dispatch(vkInfographicsActions.setLoaded(true))
}


type ThunkActionType = ThunkAction<void, RootStateType, unknown, VkInfographicsActionTypes>
export type VkInfographicsActionTypes = ReturnType<InferValueTypes<typeof vkInfographicsActions>>
