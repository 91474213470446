import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes } from './../../../types/common';
import axios from "axios";

export const registrationActions = {

    setEmail: (email: string) => ({
        type: "REGISTRATION_SET_EMAIL" as const,
        email
    }),

    setFirstName: (firstName: string) => ({
        type: "REGISTRATION_SET_FIRST_NAME" as const,
        firstName
    }),

    setLastName: (lastName: string) => ({
        type: "REGISTRATION_SET_LAST_NAME" as const,
        lastName
    }),

    setGender: (gender: number) => ({
        type: "REGISTRATION_SET_GENDER" as const,
        gender
    }),

    setPassword: (password: string) => ({
        type: "REGISTRATION_SET_PASSWORD" as const,
        password
    }),


    setRepeatPassword: (repeatPassword: string) => ({
        type: "REGISTRATION_SET_REPEAT_PASSWORD" as const,
        repeatPassword
    }),

    setLoading: (loading: boolean) => ({
        type: "REGISTRATION_SET_LOADING" as const,
        loading
    }),

    setStatus: (status: string) => ({
        type: "REGISTRATION_SET_STATUS" as const,
        status
    }),

    setPosition: (position: string) => ({
        type: "REGISTRATION_SET_POSITION" as const,
        position
    }),

    setImgUrl: (imgUrl: string) => ({
        type: "REGISTRATION_SET_IMAGE_URL" as const,
        imgUrl
    }),

    setImgFile: (file: File | null) => ({
        type: "REGISTRATION_SET_IMAGE_FILE" as const,
        file
    }),

    setWorkplace: (workplace: string) => ({
        type: "REGISTRATION_SET_WORKPLACE" as const,
        workplace
    }),

    setPatronymic: (patronymic: string) => ({
        type: "REGISTRATION_SET_PATRONYMIC" as const,
        patronymic
    }),

    setCity: (city: string) => ({
        type: "REGISTRATION_SET_CITY" as const,
        city
    }),

    setDistrict: (district: string) => ({
        type: "REGISTRATION_SET_DISTRICT" as const,
        district
    }),

    setPosLoading: (loading: boolean) => ({
        type: "REGISTRATION_SET_POS_LOADING" as const,
        loading
    }),

    toggleCheckbox: (checkboxValue: boolean) => ({
        type: "REGISTRATION_TOGGLE_CHECKBOX" as const,
        checkboxValue
    }),

    setCaptcha: (captchaValue: string | null) => ({
        type: "REGISTRATION_SET_CAPTCHA" as const,
        captchaValue
    })
}

export const registration = (): ThunkActionType => (dispatch, getState) => {
    dispatch(registrationActions.setLoading(true))

    const registrationReducer = getState().registrationReducer;
    let body = {
        email: registrationReducer.email.trim(),
        firstName: registrationReducer.firstName,
        lastName: registrationReducer.lastName,
        gender: registrationReducer.gender,
        password: registrationReducer.password,
        repeatPassword: registrationReducer.repeatPassword,
        loading: registrationReducer.loading,
        status: registrationReducer.status,
        workplace: registrationReducer.workplace,
        position: registrationReducer.position,
        patronymic: registrationReducer.patronymic,
        imgUrl: registrationReducer.imgUrl,
        district: registrationReducer.district,
        city: registrationReducer.city
    };

    axios.post('/api/account/registration', JSON.stringify(body)).then(() => {
        if (registrationReducer.imageFile) {
            const newHeaders = {
                'Content-Type': 'multipart/form-data; boundary="--ABC123Boundary',
                'Authorization': 'Bearer ' + getState().accountReducer.authToken,
            };

            const formData = new FormData()
            formData.append("imageFile", registrationReducer.imageFile)
            formData.append("email", registrationReducer.email)

            axios.post("/api/account/upload_image", formData, {
                headers: newHeaders,
            }).catch((err) => {
                console.log(err)
            })
        }
        dispatch(registrationActions.setLoading(false))
        dispatch(registrationActions.setStatus("access"))
        alert("Ожидайте письмо о прохождении верификации модератором портала, после чего Вы сможете авторизоваться на сайте.")
    }).catch((err) => {
        dispatch(registrationActions.setStatus("Пользователь с таким Email уже зарегистрирован!"))
        dispatch(registrationActions.setLoading(false))
    })
}

export type RegistrationActionTypes = ReturnType<InferValueTypes<typeof registrationActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, RegistrationActionTypes>
