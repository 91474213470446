import React, { useState } from 'react'
import { VkGroupType } from '../../../types/vk';

type PropsType = {
    data: VkGroupType
}

const VkGroupsItem: React.FC<PropsType> = ({ data, }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)
    const { cityName, img, description, name, url, subcultureName, tag, regionName, isChecked, countryName, membersCount, contacts } = data;
    return (
        <tr className="tbl__row">
            <td className="tbl__cell  avatar-column">
                <img className="user-list__avatar" src={img} alt="" />
            </td>
            <td className="tbl__cell"><a href={url} className="control-panel__link bolder">{name}</a></td>
            <td className={`tbl__cell ${description ? "" : "empty-cell"}`} hide-order="1">
                <div className="vk-community-tbl__description lineclamp-3">{description}</div>
            </td>
            <td className={`tbl__cell ${membersCount ? "" : "empty-cell"}`} hide-order="4"><span className="number-format">{membersCount}</span></td>
            <td className={`tbl__cell ${countryName ? "" : "empty-cell"}`} hide-order="3">{countryName}</td>
            <td className={`tbl__cell ${cityName ? "" : "empty-cell"}`} hide-order="3">{cityName}</td>
            <td className={`tbl__cell ${regionName ? "" : "empty-cell"}`} hide-order="3">{regionName}</td>
            <td className={`tbl__cell ${subcultureName ? "" : "empty-cell"}`} hide-order="2">{subcultureName}</td>
            <td className={`tbl__cell ${tag ? "" : "empty-cell"}`} hide-order="2">{tag}</td>
            <td className={`tbl__cell ${contacts ? "" : "empty-cell"}`} hide-order="5">{
                contacts?.split(", ")
                    .map<React.ReactNode>(contact => <a href={`https://vk.com/id${contact}`} key={contact} className="control-panel__link">{contact}</a>)
                    .reduce((prev, curr) => [prev, ", ", curr])}</td>
            <td className="tbl__cell" hide-order="2">{isChecked}</td>

            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr >
    )
}

export default VkGroupsItem
