import React, { useCallback, useEffect, useState } from 'react'
import Sidepanel from '../../Sidepanel/Sidepanel'
import VkNavBar from '../VkNavBar/VkNavBar'
import VkFilters from "../VkFilters/VkFilters";
import Pagination from "../../Pagination/Pagination";
import VkInterestsItem from './VkInterestsItem';
import { useDispatch, useSelector } from 'react-redux';
import $ from "jquery";
import { vkInterestActions, fetchCities, fetchCountries, fetchExcel, fetchRegions, fetchSocCount, fetchSubcultures, fetchTags, fetchVkUsers, fetchVkUsersCount, } from '../../../redux/vk/interests/actions';
import { RootStateType } from '../../../redux/reducers';
import { TimerType } from '../../../types/common';
import _ from 'lodash';

const { clearFilters, setCity, setCountry, setKeyword, setMaxSubscribers, setMinSubscribers, setRegion, setSubculture, setTag, setVkUsersLoading, setVkUsersPage, setVkUsersPerPage } = vkInterestActions

const VkInterests: React.FC = () => {
    const dispatch = useDispatch();
    const { loaded, loading, users, filters, count, cities, countries, regions, excel, subcultures, tags, socCount } = useSelector((state: RootStateType) => state.vkInterestsReducer);

    const { subculture, tag, country, region, city, membersCountMin, membersCountMax, name } = filters;
    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setVkUsersPage(1))
        dispatch(fetchVkUsers());
        dispatch(fetchVkUsersCount());
        dispatch(fetchExcel());
        dispatch(fetchSocCount());
    }, 500), [])

    const handlerApplyFilters = (): void => {
        dispatch(setVkUsersPage(1))
        dispatch(fetchVkUsers());
        dispatch(fetchVkUsersCount());
        dispatch(fetchExcel());
        dispatch(fetchSocCount());
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
        setSubculture("-");
        setTag("-");
        setCountry("-");
        setRegion("-");
        setCity("-");
        setMinSubscribers(0);
        setMaxSubscribers(500000);

    }

    const onChangePage = (page: number): void => {
        dispatch(setVkUsersPage(page));
        dispatch(fetchVkUsers());
    }

    const handlerChangeUsersPerPage = (usersPerPage: number): void => {
        dispatch(setVkUsersPerPage(usersPerPage));
        dispatch(setVkUsersPage(1));
        dispatch(fetchVkUsers());
        dispatch(fetchVkUsersCount());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(fetchVkUsers());
            dispatch(fetchVkUsersCount());
            dispatch(setVkUsersLoading(true));
            dispatch(fetchCountries());
            dispatch(fetchCities());
            dispatch(fetchRegions());
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
            dispatch(fetchSocCount());

        }
    });
    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <VkNavBar />
                    <VkFilters tags={tags} subcultures={subcultures} cities={cities} countries={countries} regions={regions}
                        subculture={subculture} tag={tag} country={country} city={city}
                        region={region} maxSubscribers={membersCountMax} minSubscribers={membersCountMin}
                        keyword={name} setSubculture={(subculture) => dispatch(setSubculture(subculture))} setTag={(tag) => dispatch(setTag(tag))}
                        setCountry={(country) => dispatch(setCountry(country))} setCity={(city) => dispatch(setCity(city))} setRegion={(region) => dispatch(setRegion(region))}
                        setMaxSubscribers={(maxSubscribers) => dispatch(setMaxSubscribers(maxSubscribers))} setMinSubscribers={(minSubscribers) => dispatch(setMinSubscribers(minSubscribers))}
                        setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters} clearFilters={handlerClearFilters} excel={excel} />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content">
                            <div className="panel-counters">
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <div className="panel-counters__name">Количество подписчиков по субкультурам</div>
                                        <div className="panel-counters__date">{socCount.date}</div>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className="panel-counters__counters-area">
                                            {Object.keys(socCount.subculturesStatistics).map(subculture => {
                                                return (
                                                    <div className="panel-counters__counter">
                                                        <div className="panel-counters__counter-name"><span className="dotted-text purple">{subculture}</span></div>
                                                        <div className="panel-counters__counter-number">
                                                            <span className="number-format">{socCount.subculturesStatistics[subculture]}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="tbl vk-community-tbl">
                                <thead>
                                    <tr className="tbl__row_header">
                                        <th className="tbl__header_cell" colSpan={2}>ФИО</th>
                                        <th className="tbl__header_cell" hide-order="2">Пол</th>
                                        <th className="tbl__header_cell" hide-order="2">Возраст</th>
                                        <th className="tbl__header_cell" hide-order="1">Страна</th>
                                        <th className="tbl__header_cell" hide-order="1">Регион</th>
                                        <th className="tbl__header_cell" hide-order="1">Город</th>
                                        <th className="tbl__header_cell" hide-order="4">Субкультура</th>
                                        <th className="tbl__header_cell" hide-order="3">Кол-во групп | Подписки</th>
                                        {/* <th className="tbl__header_cell" hide-order="1">Последняя активность</th> */}
                                        <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => <VkInterestsItem data={user} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Pagination itemsPerPage={filters.usersPerPage} handlerChangeItemsPerPage={handlerChangeUsersPerPage} onChangePage={onChangePage} activePage={filters.page} count={count} />
            </div>
        </div>

    )
}

export default VkInterests
