import _ from "lodash"
import { DynamicInfographicsDatsetsType, InfographicsDataType, DynamicInfographicsDataType } from "../types/infographics.d"

type BarChartOptionsType = {
    responsive: boolean,
    plugins: {
        title: {
            display: boolean,
            text: string
        },
    },
    legend: {
        text: string,
    },
    interaction: {
        intersect: boolean,
    },

}

type BarChartDataType = {
    labels: Array<string>
    datasets: [{
        label: string
        data: Array<number>,
        barThickness: number,
        backgroundColor: Array<string>,
    }]
}

type BarChartConfigType = {
    data: BarChartDataType
    options: BarChartOptionsType
}

const randomRGBNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

const randomRGBA = () => `rgba(${randomRGBNum()}, ${randomRGBNum()}, ${randomRGBNum()}, 0.9)`;

export const getBarChartConfig = (labels: Array<string>, data: Array<number>, title: string): BarChartConfigType => {
    const dataChart: BarChartDataType = {
        labels,
        datasets: [{
            data,
            label: title,
            barThickness: 18,
            backgroundColor: new Array(labels.length).fill(null).map(() => randomRGBA())
        }]
    }
    const optionsChart: BarChartOptionsType = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: title
            },
        },
        legend: {
            text: '',
        },
        interaction: {
            intersect: false,
        },
    }

    return {
        data: dataChart,
        options: optionsChart
    }
}

export const getLineChartConfig = (labels: Array<String>, datasets: DynamicInfographicsDatsetsType, title: string) => {
    const dataChart = {
        labels,
        datasets: Object.keys(datasets).map(dataset => ({
            label: dataset,
            data: datasets[dataset],
            borderColor: randomRGBA(),
            fill: false,
            tension: 0.4,
        }))
    }

    const optionsChart = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: title
            },
        },
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                display: true,
                title: {
                    display: true
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: ''
                },
                suggestedMin: 0,
                //suggestedMax: 100
            }
        }
    }

    return {
        data: dataChart,
        options: optionsChart
    }
}

export const getLineChartData = (data: InfographicsDataType): DynamicInfographicsDataType => {
    const subcultures: Array<string> = _.uniq(data.map(dataItem => Object.keys(dataItem.subculturesStatistics)).flat(1))

    const datasets: DynamicInfographicsDatsetsType = {}

    subcultures.forEach((subculture) => {
        const subculturesStatistics = data.map((dataItem) => {
            const subcultureStatistics = dataItem.subculturesStatistics[subculture]
            return subcultureStatistics ? subcultureStatistics : 0
        })

        datasets[subculture] = subculturesStatistics
    })

    const labels = data.map(dataItem => new Date(dataItem.date).toLocaleDateString("ru-RU").slice(0, 10))
    return {
        datasets,
        labels
    }
}
