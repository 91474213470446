import React, { useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom';

const TelegramNavbar: React.FC = () => {
    const navTabs = [["posts", "Записи"], ["channels", "Каналы"],
    ["infographics", "Инфографика"]];

    const history = useHistory();

    const onChangeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        history.push(`/telegram/${e.target.value}`)
    }

    return (
        <>
            <ul className="tabbed-panel__tabs d-none d-xl-flex">
                {navTabs.map((item, index) => <NavLink to={`/telegram/${item[0]}`} key={`${item}_${index}`} className={`tabbed-panel__tab`} data-target={item[0]}>{item[1]}</NavLink>)}
            </ul>
            <select className="tabbed-panel__mob_tabs d-inline-block d-xl-none" value={window.location.href.split("/")[window.location.href.split("/").length - 1]} onChange={onChangeSelectHandler}>
                {navTabs.map((item, index) => <option key={`${item}_${index}`} value={item[0]}>{item[1]}</option>)}
            </select>
        </>
    )
}

export default TelegramNavbar
