import React from 'react'
import Pagination from "../../Pagination/Pagination";
import InstFilters from '../InstFilters';
import InstNavBar from '../InstNavBar';

const InstPublications: React.FC = () => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <InstNavBar />
                    {/* @ts-ignore */}
                    <InstFilters />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content">
                            <table className="tbl vk-community-tbl">

                                <tr className="tbl__row_header">
                                    <th className="tbl__header_cell" colSpan={2}>Публикация</th>
                                    <th className="tbl__header_cell" hide-order="3">Название аккаунта</th>
                                    <th className="tbl__header_cell" hide-order="1">Статус аккаунта</th>
                                    <th className="tbl__header_cell" hide-order="2">Тег</th>
                                    <th className="tbl__header_cell" hide-order="2">Субкультура</th>
                                    <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                {/* @ts-ignore */}
                <Pagination />
            </div>
        </div>
    )
}

export default InstPublications
