import React, { useState } from 'react'
import { InstagramUserType } from '../../../types/instagram';

type PropsType = {
    data: InstagramUserType
}

const InstUsersItem: React.FC<PropsType> = ({ data,  }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)
    const { is_private, username, subcultureTag, subcultureName, profile_pic_url } = data;
    return (
        <tr className="tbl__row">
            <td className="tbl__cell  avatar-column">
                <img className="user-list__avatar" src={"/img/instagram/users/" + profile_pic_url} alt="" />
            </td>
            <td className={`tbl__cell ${username ? "" : "empty-cell"}`}><a href={`https://www.instagram.com/${username}`} target="_blank" className="control-panel__link">{username}</a></td>
            <td className="tbl__cell" hide-order="1">{is_private ? "Закрытый" : "Открытый"}</td>
            <td className={`tbl__cell ${subcultureTag ? "" : "empty-cell"}`} hide-order="2">{subcultureTag}</td>
            <td className={`tbl__cell ${subcultureName ? "" : "empty-cell"}`} hide-order="3">{subcultureName}</td>
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default InstUsersItem
