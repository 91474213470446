import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import Sidepanel from '../../Sidepanel/Sidepanel';
import { Redirect, useParams } from "react-router";
import { fetchUserInfo, editorActions, updateUser } from '../../../redux/editor/actions';
import { RootStateType } from '../../../redux/reducers';
import { convertDate } from '../../../utils/date';

const { setChecked, setImageFile, setUserCity, setUserDistrict, setUserEmail, setUserFirstName, setUserLastName, setUserLoaded, setUserPassword, setUserPatronymic, setUserPosition, setUserRepeatPassword, setUserWorkpalce } = editorActions

type ParamTypes = {
    id: string
}

function EditorUser() {
    const { id } = useParams<ParamTypes>();

    const dispatch = useDispatch();
    const { userInfo, loaded } = useSelector((state: RootStateType) => state.editorReducer.user);
    const accountId = useSelector((state: RootStateType) => state.accountReducer.accountId);
    const role = useSelector((state: RootStateType) => state.accountReducer.role);

    const { checked, city, district, email, firstName, lastName,
        patronymic, workplace, position, imgUrl,
        firstNameTitle, lastNameTitle, checkedInput, patronymicTitle, imageFile, password, repeatPassword, lastVisit } = userInfo;

    useEffect(() => {
        if (!loaded) {
            dispatch(fetchUserInfo(+id));
            dispatch(setUserLoaded(true));
        }

        return () => {
            dispatch(setUserLoaded(false));
        }
    }, []);

    const updateUserHandler = () => {
        if (!(validEmail(email) || validFirstName(firstName)
            || validFirstName(lastName) || validPassword(password)
            || validRepeatPassword(password, repeatPassword)))
            dispatch(updateUser());
    }

    const r = Math.floor(Math.random() * 100000);


    const date = new Date(Number(lastVisit));

    const hours = convertDate(String(date.getHours()));
    const minutes = convertDate(String(date.getMinutes()));
    const seconds = convertDate(String(date.getSeconds()));
    const day = convertDate(String(date.getDate()));
    const month = convertDate(String(date.getMonth() + 1));
    const year = String(date.getFullYear());

    if (!(["ROLE_ADMIN", "ROLE_EDITOR"].includes(role) || accountId === +id)) {
        return <Redirect to="/" />
    }

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
                <div className="breadcrumbs">
                    {role === "ROLE_ADMIN" ?
                        <>
                            <Link to="/editor" className="breadcrumbs__link">Модерация</Link>
                            <span className="breadcrumbs__curlink">Редактирование данных пользователя</span>
                        </>
                        :
                        null}
                </div>
                <div className="control-panel userpanel">
                    <div className="control_panel__body">
                        <div className="userpanel__header">
                            <div className="userpanel__avatar">
                                <label htmlFor="file-input">
                                    <img className="userpanel__avatar" src={imageFile ? URL.createObjectURL(imageFile) : imgUrl ? window.location.origin + `/img/account/${imgUrl}` : "https://icon-library.com/images/no-user-image-icon/no-user-image-icon-27.jpg"} />
                                </label>

                                <input onChange={(e) => {
                                    if (!e.target.files) {
                                        return
                                    }

                                    dispatch(setImageFile(e.target.files[0]))
                                }} style={{ display: "none" }} id="file-input" type="file" />
                            </div>
                            <div className="userpanel__header_info">
                                <div className="userpanel__header_info_left">
                                    <div className="userpanel__username mb-2">
                                        {lastNameTitle} {firstNameTitle} {patronymicTitle}
                                    </div>
                                    <div className="userpanel__role-n-status mb-3">
                                        Пользователь <span className={`${checked ? "text_success" : "text_danger"} ml-0 ml-sm-3 d-block d-sm-inline-block`}>{checked ? "Одобрен" : "Не одобрен"}</span>
                                    </div>

                                    {!checked ?
                                        <div className="form__checkbox_wrap">
                                            <input type="checkbox" checked={checkedInput ? true : false} className="form__checkbox_input" />
                                            {/*TODO: refactor setChecked*/}
                                            <label onClick={() => dispatch(setChecked(!checkedInput, 0))} className="form__checkbox_input_mark" htmlFor={`c${r}`}></label>
                                            <div className="form__checkbox_label">Одобрить</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {Number(lastVisit) ?
                                        <div className="userpanel__lastvisit"><span className="mr-3">Посещал систему:</span> <span className="mr-0 mr-md-3">{hours}:{minutes}:{seconds}</span>&nbsp;<span>{day}.{month}.{year}</span></div>
                                        :
                                        null}
                                </div>
                                <div>
                                    <a href="#" className="userpanel__link_to_admin">Обратиться к администратору</a>
                                </div>
                            </div>

                        </div>
                        <form className="form" onSubmit={(e) => {
                            e.preventDefault();
                            return false;
                        }}>
                            <div className="control-panel__row">
                                <div className="control-panel__row_header">
                                    ФИО
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Имя</label>
                                        <input onChange={(e) => dispatch(setUserFirstName(e.target.value))} value={firstName} type="text" className="form__row_input" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Фамилия</label>
                                        <input onChange={(e) => dispatch(setUserLastName(e.target.value))} value={lastName} type="text" className="form__row_input" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Отчество</label>
                                        <input onChange={(e) => dispatch(setUserPatronymic(e.target.value))} value={patronymic} type="text" className="form__row_input" />
                                    </div>
                                </div>
                            </div>
                            <div className="control-panel__row">
                                <div className="control-panel__row_header">
                                    Место проживание и должность
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Регион</label>
                                        <input onChange={(e) => dispatch(setUserDistrict(e.target.value))} value={district} type="text" className="form__row_input" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Город</label>
                                        <input onChange={(e) => dispatch(setUserCity(e.target.value))} value={city} type="text" className="form__row_input" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Место работы</label>
                                        <input onChange={(e) => dispatch(setUserWorkpalce(e.target.value))} value={workplace} type="text" className="form__row_input" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Должность</label>
                                        <input onChange={(e) => dispatch(setUserPosition(e.target.value))} value={position} type="text" className="form__row_input" />
                                    </div>
                                </div>
                            </div>
                            <div className="control-panel__row">
                                <div className="control-panel__row_header">
                                    Почта и пароль
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">E-mail</label>
                                        <input onChange={(e) => dispatch(setUserEmail(e.target.value))} value={email} type="email" className="form__row_input" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Пароль</label>
                                        <input onChange={(e) => dispatch(setUserPassword(e.target.value))} type="password" className="form__row_input" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <label className="form__row_item_label">Повтор пароля</label>
                                        <input onChange={(e) => dispatch(setUserRepeatPassword(e.target.value))} type="password" className="form__row_input" />
                                    </div>
                                </div>
                            </div>
                            <div className="control-panel__row">
                                <button className="button button_darkgreen" onClick={updateUserHandler}>Сохранить</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div >
    )
}
const validEmail = (email: string): boolean => {
    if (!email.length) {
        return true;
    }

    var regEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    var validEmail = regEx.test(email);

    if (validEmail) {
        return false;
    }

    return true;
}

const validFirstName = (firstName: string): boolean => {
    if (firstName.length > 2) {
        return false
    } else {
        return true
    }
}

const validPassword = (password: string): boolean => {
    if (password.length === 0) {
        return false;
    }

    if (password.length > 6) {
        return false
    } else {
        return true
    }
}

const validRepeatPassword = (password: string, repeatPassword: string): boolean => {
    if (password.length === 0) {
        return false;
    }

    if (password === repeatPassword && !validPassword(password)) {
        return false
    } else {
        return true
    }
};

const vaildImage = (name: string): boolean => {
    if ([".jpg", ".jpeg", ".bmp", ".gif", ".png"].some((item) => name.endsWith(item))) {
        return true;
    }

    return false;
}

export default EditorUser
