import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import {
    registrationActions,
    registration,
} from "../../redux/app/registration/actions";
import { RootStateType } from "../../redux/reducers";
import Sidepanel from "../Sidepanel/Sidepanel";

const {
    setEmail,
    setFirstName, setGender,
    setImgUrl,
    setLastName, setLoading,
    setPassword,
    setPatronymic,
    setPosition,
    setRepeatPassword, setStatus, setWorkplace, setCity, setDistrict, setImgFile, toggleCheckbox, setCaptcha } = registrationActions

const Registration = () => {
    const dispatch = useDispatch();
    const registrationData = useSelector((state: RootStateType) => state.registrationReducer)

    const { email, password, loading, firstName,
        lastName, repeatPassword, gender,
        status, patronymic, city, district,
        workplace, position, imgUrl,
        imageFile, isChecked, captcha } = registrationData
    console.log(captcha)


    const r = Math.floor(Math.random() * 100000);
    return (
        <div className="row justify-content-center no-gutters">
            <div className="col-12 col-md-6">
                <div className="ellipsesbg ellipsesbg_center flex-horizontal-center">
                    <div className="ellipsesbg__content">
                        <h3 className="ellipses__content_header">Регистрация</h3>
                        <form action="" className="form form_login" encType="multipart/form-data">
                            <div className="form__block mb-4">
                                <p>* - обязательное поле для заполнение</p>
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Фамилия*</div>
                                <input onChange={e => dispatch(setLastName(e.target.value))} value={lastName} type="text" className="form__block_input" placeholder="Введите вашу фамилию" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Имя*</div>
                                <input onChange={e => dispatch(setFirstName(e.target.value))} value={firstName} type="text" className="form__block_input" placeholder="Введите ваше имя" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Отчество*</div>
                                <input onChange={e => dispatch(setPatronymic(e.target.value))} value={patronymic} type="text" className="form__block_input" placeholder="Введите ваше отчество" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Загрузите фото</div>
                                <label htmlFor="" className="form__block_dragndrop_outer">
                                    png, jpeg, IMG
                                    <input onChange={e => e.target.files && dispatch(setImgFile(e.target.files[0]))} type="file" className="form__block_dragndrop" />
                                </label>
                                {imageFile ? <img onError={() => dispatch(setImgFile(null))} className="form__block_img" src={URL.createObjectURL(imageFile)} /> : null}
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Регион</div>
                                <input onChange={e => dispatch(setDistrict(e.target.value))} value={district} type="text" className="form__block_input" placeholder="Регион вашего проживания" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Город*</div>
                                <input onChange={e => dispatch(setCity(e.target.value))} value={city} type="text" className="form__block_input" placeholder="Город вашего проживания" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Место работы</div>
                                <input onChange={e => dispatch(setWorkplace(e.target.value))} value={workplace} type="text" className="form__block_input" placeholder="Место вашей работы" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Должность</div>
                                <input onChange={e => dispatch(setPosition(e.target.value))} value={position} type="text" className="form__block_input" placeholder="Ваша должность" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">E-mail*</div>
                                <input onChange={e => dispatch(setEmail(e.target.value))} value={email} type="email" className="form__block_input" placeholder="Введите адрес электронной почты" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Пароль</div>
                                <input onChange={e => dispatch(setPassword(e.target.value))} value={password} type="password" className="form__block_input" placeholder="Введите пароль" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__block_label">Подтверждение пароля</div>
                                <input onChange={e => dispatch(setRepeatPassword(e.target.value))} value={repeatPassword} type="password" className="form__block_input" placeholder="Повторите пароль" />
                            </div>
                            <div className="form__block mb-4">
                                <div className="form__checkbox_wrap">
                                    <input checked={isChecked} type="checkbox" className="form__checkbox_input" />
                                    <label onClick={e => dispatch(toggleCheckbox(!isChecked))} id={"c" + r} className="form__checkbox_input_mark" htmlFor={"c" + r}></label>
                                    <div className="form__checkbox_label">Я даю согласие на <a href="#">обработку персональных данных</a></div>
                                </div>

                            </div>
                            <div className="form__block mb-5">
                                <ReCAPTCHA sitekey="6Len5dwdAAAAABe0tanu9-v2j7-4BIO_-s6XtU-F" onChange={(val) => dispatch(setCaptcha(val))} />
                            </div>
                            <div className="form__block">
                                <a onClick={(e) => {
                                    if (!(validEmail(email) || validFirstName(firstName)
                                        || validFirstName(lastName) || validPassword(password)
                                        || validRepeatPassword(password, repeatPassword)
                                        || !isChecked || !captcha)) {
                                        alert("Регистрация")
                                        dispatch(registration())
                                    }
                                }} href="javascript:;" className="button button_darkgreen button_fwidth">Войти</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const validEmail = (email: string): boolean => {
    if (!email.length) {
        return true;
    }

    var regEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    var validEmail = regEx.test(email);

    if (validEmail) {
        return false;
    }

    return true;
}

const validFirstName = (firstName: string): boolean => {
    if (firstName.length > 2) {
        return false
    } else {
        return true
    }
}

const validPassword = (password: string): boolean => {
    if (password.length > 6) {
        return false
    } else {
        return true
    }
}

const validRepeatPassword = (password: string, repeatPassword: string): boolean => {
    if (password === repeatPassword && !validPassword(password)) {
        return false
    } else {
        return true
    }
};

const vaildImage = (name: string): boolean => {
    if ([".jpg", ".jpeg", ".bmp", ".gif", ".png"].some((item) => name.endsWith(item))) {
        return true;
    }

    return false;
}

export default Registration;