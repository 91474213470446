import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes } from './../../../types/common';
import { TelegramChannelType } from './../../../types/telegram';
import axios from "axios";

export const telegramChannelActions = {
    setTelegramChannelsLoaded: (loaded: boolean) => ({
        type: "TELEGRAM_CHANNELS_SET_LOADED" as const,
        loaded
    }),

    setTelegramChannels: (channels: Array<TelegramChannelType>) => ({
        type: "TELEGRAM_CHANNELS_SET_CHANNELS" as const,
        channels
    }),

    setTelegramChannelsLoading: (loading: boolean) => ({
        type: "TELEGRAM_CHANNELS_SET_LOADING" as const,
        loading
    }),

    setTelegramChannelsCount: (count: number) => ({
        type: "TELEGRAM_CHANNELS_SET_CHANNELS_COUNT" as const,
        count
    }),

    setTelegramChannelsPage: (page: number) => ({
        type: "TELEGRAM_CHANNELS_SET_PAGE" as const,
        page
    }),

    setTelegramChannelsPerPage: (channelsPerPage: number) => ({
        type: "TELEGRAM_CHANNELS_SET_CHANNELS_PER_PAGE" as const,
        channelsPerPage
    }),

    setSubculture: (subculture: string) => ({
        type: "TELEGRAM_CHANNELS_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "TELEGRAM_CHANNELS_SET_TAG" as const,
        tag
    }),

    setKeyword: (keyword: string) => ({
        type: "TELEGRAM_CHANNELS_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "TELEGRAM_CHANNELS_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "TELEGRAM_CHANNELS_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "TELEGRAM_CHANNELS_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "TELEGRAM_CHANNELS_SET_TAGS" as const,
        tags
    })
}

export const fetchTelegramChannels = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().telegramChannelsReducer.filters;

    axios.post<Array<TelegramChannelType>>('/api/telegram/channels/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(telegramChannelActions.setTelegramChannels(data))
        dispatch(telegramChannelActions.setTelegramChannelsLoaded(true))
        dispatch(telegramChannelActions.setTelegramChannelsLoading(false))
    })
};

export const fetchTelegramChannelsCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().telegramChannelsReducer.filters;

    axios.post<number>("/api/telegram/channels/get-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(telegramChannelActions.setTelegramChannelsCount(data))
    })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().telegramChannelsReducer.filters;
    axios.request<File>({url: "/api/telegram/channels/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob"})
        .then(response => {
            
            dispatch(telegramChannelActions.setExcel(response.data))
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(telegramChannelActions.setSubcultures(data))
    })
};

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/telegram/channels/get-tags").then(({ data }) => {
        dispatch(telegramChannelActions.setTags(data))
    })
}

export type TelegramChannelActionTypes = ReturnType<InferValueTypes<typeof telegramChannelActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, TelegramChannelActionTypes>