import React, { useState } from 'react'
import { TelegramChannelType } from '../../../types/telegram';

type PropsType = {
    data: TelegramChannelType
}

const TelegramChannelsItem: React.FC<PropsType> = ({ data, }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)

    const { channelName, description, imgUrl, link, totalSubscribers, isChecked, totalPosts, totalViews, subscribersGrowth, postsGrowth, viewsGrowth, tags, subcultures } = data;
    return (
        <tr className="tbl__row">
            <td className="tbl__cell avatar-column">
                <a href={"https://t.me/" + link}><img src={"/img/telegram/" + imgUrl} className="user-list__avatar" alt="" /></a>
            </td>
            <td className="tbl__cell">
                <a href={"https://t.me/" + link}>
                    {channelName}
                </a>
            </td>
            <td className={`tbl__cell ${description ? "" : "empty-cell"}`} hide-order="1">
                <div className="lineclamp-3">
                    {description}
                </div>
            </td>
            <td className="tbl__cell" hide-order="2">
                <span className="number-format">{totalSubscribers}</span> | <span className="text_success">+<span className="number-format">{subscribersGrowth}</span></span>
            </td>
            <td className="tbl__cell" hide-order="2">
                <span className="number-format">{totalPosts}</span> | <span className="text_success">+<span className="number-format">{postsGrowth}</span></span>
            </td>
            <td className="tbl__cell" hide-order="2">
                <span className="number-format">{totalViews}</span> | <span className="text_success">+<span className="number-format">{viewsGrowth}</span></span>
            </td>
            <td className="tbl__cell" hide-order="3">{tags}</td>
            <td className="tbl__cell" hide-order="3">{subcultures}</td>
            <td className="tbl__cell" hide-order="3">{isChecked}</td>
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default TelegramChannelsItem
