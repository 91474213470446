import React, { useCallback, useEffect, useState } from 'react'
import Sidepanel from '../../Sidepanel/Sidepanel'
import TikTokFIlters from '../TikTokFIlters/TikTokFIlters'
import TikTokNavbar from '../TikTokNavbar'
import $ from "jquery";
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../Pagination/Pagination';
import TikTokVideosItem from "./TikTokVideosItem";
import { tiktokPostActions, fetchExcel, fetchSubcultures, fetchTags, fetchTikTokPosts, fetchTikTokPostsCount } from '../../../redux/tiktok/posts/actions';
import { RootStateType } from '../../../redux/reducers';
import { TimerType } from '../../../types/common';
import _ from 'lodash';

const { clearFilters, setKeyword, setSubculture, setTag, setTikTokPostsLoading, setTikTokPostsPage, setTikTokPostsPerPage } = tiktokPostActions

const TikTokVideos: React.FC = () => {
    const dispatch = useDispatch();
    const { loaded, loading, posts, filters, count, excel, subcultures, tags } = useSelector((state: RootStateType) => state.tikTokPostsReducer);
    const { subculture, tag, keyword } = filters;

    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setTikTokPostsPage(1))
        dispatch(fetchTikTokPosts());
        dispatch(fetchTikTokPostsCount());
        dispatch(fetchExcel());
    }, 500), [])


    const handlerApplyFilters = (): void => {
        dispatch(setTikTokPostsPage(1))
        dispatch(fetchTikTokPosts());
        dispatch(fetchTikTokPostsCount());
        dispatch(fetchExcel());
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
    }

    const onChangePage = (page: number): void => {
        dispatch(setTikTokPostsPage(page));
        dispatch(fetchTikTokPosts());
    }

    const handlerChangePostsPerPage = (postsPerPage: number): void => {
        dispatch(setTikTokPostsPerPage(postsPerPage));
        dispatch(setTikTokPostsPage(1));
        dispatch(fetchTikTokPosts());
        dispatch(fetchTikTokPostsCount());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(fetchTikTokPosts());
            dispatch(fetchTikTokPostsCount());
            dispatch(setTikTokPostsLoading(true));
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
        }
    });

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <TikTokNavbar />
                    <TikTokFIlters tag={tag} keyword={keyword} setSubculture={(subculture) => dispatch(setSubculture(subculture))}
                        setTag={(tag) => dispatch(setTag(tag))} subculture={subculture} setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters}
                        clearFilters={handlerClearFilters} excel={excel} subcultures={subcultures} tags={tags} />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="video">
                            <table className="tbl tiktok-video-tbl">
                                <tr className="tbl__row_header">
                                    <th className="tbl__header_cell">Превью</th>
                                    <th className="tbl__header_cell">Краткое описание</th>
                                    <th className="tbl__header_cell" hide-order="2">Статус</th>
                                    <th className="tbl__header_cell" hide-order="3">Кол-во просмотров</th>
                                    <th className="tbl__header_cell" hide-order="3">Кол-во лайков</th>
                                    <th className="tbl__header_cell" hide-order="3">Кол-во комментариев</th>
                                    <th className="tbl__header_cell" hide-order="1">Субкультура</th>
                                    <th className="tbl__header_cell" hide-order="1">Теги</th>
                                    <th className="tbl__header_cell" hide-order="1">Проверено администратором</th>
                                    <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                </tr>
                                {posts.map((item, index) => <TikTokVideosItem data={item} key={index} />)}
                            </table>
                        </div>
                    </div>
                </div>
                <Pagination count={count} itemsPerPage={filters.postsPerPage} activePage={filters.page} handlerChangeItemsPerPage={handlerChangePostsPerPage} onChangePage={onChangePage} />
            </div>
        </div>

    )
}

export default TikTokVideos
