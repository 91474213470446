import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios'
import { InferValueTypes } from '../../../types/common'

export const accountActions = {
    setAuthorized: (authorized: boolean) => ({
        type: "ACCOUNT_SET_AUTHORIZED" as const,
        authorized
    }),

    setAccountId: (accountId: number) => ({
        type: "ACCOUNT_SET_ACCOUNT_ID" as const,
        accountId
    }),

    setAdminRole: (adminRole: string) => ({
        type: "ACCOUNT_SET_ADMIN_ROLE" as const,
        adminRole
    }),
    setAdminEmail: (adminEmail: string) => ({
        type: "ACCOUNT_SET_ADMIN_EMAIL" as const,
        adminEmail
    }),

    setPassword: (password: string) => ({
        type: "ACCOUNT_SET_PASSWORD" as const,
        password
    }),

    setRepeatPassword: (repeatPassword: string) => ({
        type: "ACCOUNT_SET_REPEAT_PASSWORD" as const,
        repeatPassword
    }),

    setEmail: (email: string) => ({
        type: "ACCOUNT_SET_EMAIL" as const,
        email
    }),

    setLoaded: (loaded: boolean) => ({
        type: "ACCOUNT_SET_LOADED" as const,
        loaded
    }),

    setAccountImageModalOpen: (isOpen: boolean) => ({
        type: "ACCOUNT_SET_MODAL_OPEN" as const,
        isOpen
    }),

    setAccountImageModalFile: (file: File) => ({
        type: "ACCOUNT_SET_MODAL_FILE" as const,
        file
    }),

    setGender: (gender: number) => ({
        type: "ACCOUNT_SET_GENDER" as const,
        gender
    }),

    setRole: (role: string) => ({
        type: "ACCOUNT_SET_ROLE" as const,
        role
    }),

    setFirstName: (firstName: string) => ({
        type: "ACCOUNT_SET_FIRST_NAME" as const,
        firstName
    }),

    setLastName: (lastName: string) => ({
        type: "ACCOUNT_SET_LAST_NAME" as const,
        lastName
    }),

    setImgUrl: (imgUrl: string) => ({
        type: "ACCOUNT_SET_IMG_URL" as const,
        imgUrl
    }),

    setBdate: (bdate: string) => ({
        type: "ACCOUNT_SET_BDATE" as const,
        bdate
    }),

    setAuthToken: (authToken: string) => ({
        type: "ACCOUNT_SET_AUTH_TOKEN" as const,
        authToken
    }),
    setTag: (tag: string) => ({
        type: "ACCOUNT_SET_TAG_FORM_TAG" as const,
        tag
    }),

    setAbout: (about: string) => ({
        type: "ACCOUNT_SET_TAG_FORM_ABOUT" as const,
        about
    }),

    setTagFormSubculture: (subculture: string) => ({
        type: "ACCOUNT_SET_TAG_FORM_SUBCULTURE" as const,
        subculture
    }),

    clearTagForm: () => ({
        type: "ACCOUNT_CLEAR_TAG_FORM" as const
    }),

    setUnlogin: () => ({
        type: "ACCOUNT_UNLOGIN" as const
    }),

    setImageFile: (file: File) => ({
        type: "ACCOUNT_SET_IMAGE_FILE" as const,
        file
    })
}


export const fetchAccountInfo = (): ThunkActionType => (dispatch, getState) => {
    const data = {
        jwt: getState().accountReducer.authToken
    }

    if (data.jwt !== "") {
        //TODO: refactor account actions
        axios.post<any>('/api/account/get', JSON.stringify(data))
            .then(({ data }) => {
                dispatch(accountActions.setAccountId(data.id))
                console.log(new Date().getTime() + 3600 * 24 * 31 * 1000)
                if (!getCookie("id")) {
                    document.cookie = "id=" + data.id + ";max-age=" + 3600 * 24 * 31 + ";path=/";
                }
                dispatch(accountActions.setEmail(data.email))
                dispatch(accountActions.setBdate(data.bdate))
                dispatch(accountActions.setGender(data.gender))
                dispatch(accountActions.setFirstName(data.firstName))
                dispatch(accountActions.setLastName(data.lastName))
                dispatch(accountActions.setRole(data.roles))
                dispatch(accountActions.setImgUrl(data.imgUrl))
                return data;
            })
            .catch((err) => {
                console.log("fetchAccountInfo error!")
                dispatch(accountActions.setAuthorized(false))
                // deleteCookie("jwt")
                // deleteCookie("id")
            })
    }
}

export const fetchChangePassword = (): ThunkActionType => (dispatch, getState) => {
    const data = {
        email: getState().accountReducer.email,
        newPassword: getState().accountReducer.password
    }

    axios.post('/api/account/change-password', JSON.stringify(data)).then(() => {
        dispatch(accountActions.setPassword(""))
        dispatch(accountActions.setRepeatPassword(""))
        alert("Пароль успешно изменён!")
    })
}

export const uploadAccountImage = (): ThunkActionType => (dispatch, getState) => {
    const data = {
        imageFile: getState().accountReducer.imageUploadModalFile,
        email: getState().accountReducer.email
    }

    if (!data.imageFile) {
        return
    }

    const imgData = new FormData()
    imgData.append("imageFile", data.imageFile)
    imgData.append("email", data.email)


    axios.post<string>('/api/account/upload_image', imgData).then(({ data }) => {
        console.log(data)
        dispatch(accountActions.setAccountImageModalOpen(false))
        dispatch(accountActions.setImgUrl(data))
    }).catch(err => {
        console.log(err)
    })
}


export const fetchSetAdminRole = (): ThunkActionType => (dispatch, getState) => {
    let data = {
        "email": getState().accountReducer.adminEmail,
        "role": getState().accountReducer.adminRole
    }

    axios.post<number>('/admin/set-admin-role', JSON.stringify(data)).then(({ data }) => {
        if (data === 200) {
            dispatch(accountActions.setAdminEmail(""))
            alert("Права пользователя изменены!")
        } else {
            alert("Не верный Email!")
        }
    })
};

export const setLastVisit = (date: number): ThunkActionType => (dispatch, getState) => {
    const data = {
        id: getState().accountReducer.accountId,
        lastVisit: date
    }

    axios.post("/api/account/set-last-visit", JSON.stringify(data))
}

function getCookie(name: string): string | null {
    let matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line no-useless-escape
        "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
    ))
    return matches ? decodeURIComponent(matches[1]) : null;
}

export type AccountActionTypes = ReturnType<InferValueTypes<typeof accountActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, AccountActionTypes>