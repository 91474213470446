import React from 'react'
import Sidepanel from '../../Sidepanel/Sidepanel'
import YoutubeNavbar from '../YoutubeNavbar'

const YoutubeVideos: React.FC = () => {
    return (
        
            
                
                <div className="row">
                    <div className="col-12">
                        <div className="control-panel mt-5">
                            <YoutubeNavbar />
                            <div className="control_panel__body">
                            </div>
                        </div>

                    </div>
                </div>

    )
}

export default YoutubeVideos
