import {
    AccountActionTypes
} from "./actions";

const defaultState = {
    accountId: null as number | null,
    authorized: false,
    authToken: "",
    email: "",
    firstName: "",
    lastName: "",
    imgUrl: "",
    bdate: "",
    gender: 0,
    tag: "",
    about: "",
    subculture: "АУЕ",
    role: "ROLE_ADMIN",
    password: "",
    repeatPassword: "",
    adminRole: "",
    adminEmail: "",
    imageUploadModalFile: null as File | null,
    imageUploadModalOpen: false,
    loaded: false,
    imageFile: null as File | null,
    lastVisit: null
}

type AccountStateType = typeof defaultState

export const accountReducer = (state = defaultState, action: AccountActionTypes): AccountStateType => {
    switch (action.type) {
        case "ACCOUNT_SET_BDATE":
            return {
                ...state,
                bdate: action.bdate
            }
        case "ACCOUNT_SET_ADMIN_EMAIL":
            return {
                ...state,
                adminEmail: action.adminEmail
            }
        case "ACCOUNT_SET_ADMIN_ROLE":
            return {
                ...state,
                adminRole: action.adminRole
            }
        case "ACCOUNT_SET_PASSWORD":
            return {
                ...state,
                password: action.password
            }
        case "ACCOUNT_SET_REPEAT_PASSWORD":
            return {
                ...state,
                repeatPassword: action.repeatPassword
            }
        case "ACCOUNT_SET_FIRST_NAME":
            return {
                ...state,
                firstName: action.firstName
            }
        case "ACCOUNT_SET_LAST_NAME":
            return {
                ...state,
                lastName: action.lastName
            }
        case "ACCOUNT_SET_AUTH_TOKEN":
            return {
                ...state,
                authToken: action.authToken
            }
        case "ACCOUNT_SET_IMG_URL":
            return {
                ...state,
                imgUrl: action.imgUrl
            }
        case "ACCOUNT_SET_EMAIL":
            return {
                ...state,
                email: action.email
            }
        case "ACCOUNT_SET_AUTHORIZED":
            return {
                ...state,
                authorized: action.authorized
            }
        case "ACCOUNT_SET_GENDER":
            return {
                ...state,
                gender: action.gender
            }
        case "ACCOUNT_SET_ACCOUNT_ID":
            return {
                ...state,
                accountId: action.accountId
            }
        case "ACCOUNT_SET_TAG_FORM_TAG":
            return {
                ...state,
                tag: action.tag
            }
        case "ACCOUNT_SET_TAG_FORM_ABOUT":
            return {
                ...state,
                about: action.about
            }
        case "ACCOUNT_SET_TAG_FORM_SUBCULTURE":
            return {
                ...state,
                subculture: action.subculture
            }
        case "ACCOUNT_SET_ROLE":
            return {
                ...state,
                role: action.role
            }
        case "ACCOUNT_CLEAR_TAG_FORM":
            return {
                ...state,
                tag: "",
                about: "",
                subculture: ""
            }
        case "ACCOUNT_SET_MODAL_OPEN":
            return {
                ...state,
                imageUploadModalOpen: action.isOpen
            }
        case "ACCOUNT_SET_MODAL_FILE":
            return {
                ...state,
                imageUploadModalFile: action.file
            }
        case "ACCOUNT_UNLOGIN":
            return {
                ...state,
                accountId: null,
                authorized: false,
                authToken: "",
                email: "",
                firstName: "",
                lastName: "",
                imgUrl: "",
                bdate: "",
                gender: 0,
                tag: "",
                about: "",
                subculture: "АУЕ",
                role: "",
                password: "",
                repeatPassword: "",
                adminRole: "",
                adminEmail: "",
                imageUploadModalFile: null,
                imageUploadModalOpen: false
            }
        case "ACCOUNT_SET_LOADED":
            return {
                ...state,
                loaded: action.loaded
            }
        case "ACCOUNT_SET_IMAGE_FILE":
            return {
                ...state,
                imageFile: action.file
            }

        default:
            return state;
    }
}