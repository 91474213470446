import {
    RegistrationActionTypes
} from "./actions";

const defaultState = {
    email: "",
    firstName: "",
    lastName: "",
    gender: 0,
    password: "",
    repeatPassword: "",
    loading: false,
    status: "",
    workplace: "",
    position: "",
    patronymic: "",
    imgUrl: "",
    city: "",
    district: "",
    imageFile: null as File | null,
    isChecked: false,
    captcha: null as string | null
}

type RegistrationStateType = typeof defaultState

export const registrationReducer = (state = defaultState, action: RegistrationActionTypes): RegistrationStateType => {
    switch (action.type) {
        case "REGISTRATION_SET_EMAIL":
            return {
                ...state,
                email: action.email
            }
        case "REGISTRATION_SET_FIRST_NAME":
            return {
                ...state,
                firstName: action.firstName
            }
        case "REGISTRATION_SET_LAST_NAME":
            return {
                ...state,
                lastName: action.lastName
            }
        case "REGISTRATION_SET_GENDER":
            return {
                ...state,
                gender: action.gender
            }
        case "REGISTRATION_SET_PASSWORD":
            return {
                ...state,
                password: action.password
            }
        case "REGISTRATION_SET_REPEAT_PASSWORD":
            return {
                ...state,
                repeatPassword: action.repeatPassword
            }
        case "REGISTRATION_SET_LOADING":
            return {
                ...state,
                loading: action.loading
            }
        case "REGISTRATION_SET_STATUS":
            return {
                ...state,
                status: action.status
            }
        case "REGISTRATION_SET_WORKPLACE":
            return {
                ...state,
                workplace: action.workplace
            }
        case "REGISTRATION_SET_PATRONYMIC":
            return {
                ...state,
                patronymic: action.patronymic
            }
        case "REGISTRATION_SET_DISTRICT":
            return {
                ...state,
                district: action.district
            }
        case "REGISTRATION_SET_POSITION":
            return {
                ...state,
                position: action.position
            }
        case "REGISTRATION_SET_IMAGE_URL":
            return {
                ...state,
                imgUrl: action.imgUrl
            }
        case "REGISTRATION_SET_CITY":
            return {
                ...state,
                city: action.city
            }
        case "REGISTRATION_SET_IMAGE_FILE":
            return {
                ...state,
                imageFile: action.file
            }
        case "REGISTRATION_TOGGLE_CHECKBOX":
            return {
                ...state,
                isChecked: action.checkboxValue
            }
        case "REGISTRATION_SET_CAPTCHA": 
            return {
                ...state,
                captcha: action.captchaValue
            }

        default:
            return state;
    }
}