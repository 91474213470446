import React, { useState } from 'react'
import { TelegramPostType } from '../../../types/telegram';

type PropsType = {
    data: TelegramPostType
}

const TelegramPostsItem: React.FC<PropsType> = ({ data,  }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)
    const { channelName, imgUrl, postContent, postViews, postId, isChecked, tags, subcultures, totalSubscribers, subscribersGrowth, postViewsGrowth } = data;
    return (
        <tr className="tbl__row">
            <td className="tbl__cell avatar-column">
                <a href={"https://t.me/" + channelName + "/" + postId}>
                    <img className="user-list__avatar" src={"/img/telegram/" + imgUrl} alt="" />
                </a>
            </td>
            <td className="tbl__cell" hide-order="1">
                <div className="lineclamp-3">
                    {postContent.length > 40 ? postContent.slice(0, 40) + "..." : postContent}
                </div>
            </td>
            <td className="tbl__cell">
                <a href={"https://t.me/" + channelName}>
                    {channelName}
                </a>
            </td>
            <td className="tbl__cell" hide-order="2">
                <span className="number-format">{totalSubscribers}</span> | <span className="text_success">+<span className="number-format">{subscribersGrowth}</span></span>
            </td>
            <td className="tbl__cell" hide-order="2">
                <span className="number-format">{postViews}</span> | <span className="text_success">+<span className="number-format">{postViewsGrowth}</span></span>
            </td>
            <td className="tbl__cell" hide-order="3">{tags}</td>
            <td className="tbl__cell" hide-order="3">{subcultures}</td>
            <td className="tbl__cell" hide-order="3">{isChecked}</td>
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default TelegramPostsItem
