import { InferValueTypes, TimerType } from "../../types/common"
import { SidepanelTabTypes } from "../../types/sidepanel"

export const sidepanelActions = {
    setIsSidepanelActive: (isSidepanelActive: boolean) => ({
        type: "SIDEPANEL_SET_IS_SIDEPANEL_ACTIVE" as const,
        isSidepanelActive
    }),
    setSidepanelCurrentTab: (sidepanelTab: SidepanelTabTypes) => ({
        type: "SIDEPANEL_SET_CURRENT_TAB" as const,
        sidepanelTab
    }),
    setToggleTimer: (toggleTimer: TimerType | null) => ({
        type: "SIDEPANEL_SET_TOGGLE_TIMER" as const,
        toggleTimer
    })
}

export type SidepanelActionTypes = ReturnType<InferValueTypes<typeof sidepanelActions>>
