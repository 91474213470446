import React from 'react'
import { Button } from 'react-bootstrap';
import axios from "axios";

const EditorParsing: React.FC = () => {
    const request = () => {
        axios.get("/editor/parsing")
            .catch((error) => {
                console.log(error);
            })
        ;
    }

    return (
        <div>
            <Button onClick={request}>
                Парсинг
            </Button>
        </div>
    )
}

export default EditorParsing
