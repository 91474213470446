import { InfographicsDataType } from './../../../types/infographics.d';
import { SocCountType } from './../../../types/vk.d';
import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes } from './../../../types/common';
import axios from "axios";
import { VkUserType } from "../../../types/vk";

export const vkInterestActions = {
    setVkUsersLoaded: (loaded: boolean) => ({
        type: "VK_INTERESTS_SET_LOADED" as const,
        loaded
    }),

    setVkUsers: (users: Array<VkUserType>) => ({
        type: "VK_INTERESTS_SET_INTERESTS" as const,
        users
    }),

    setVkUsersLoading: (loading: boolean) => ({
        type: "VK_INTERESTS_SET_LOADING" as const,
        loading
    }),

    setVkUsersCount: (count: number) => ({
        type: "VK_INTERESTS_SET_INTERESTS_COUNT" as const,
        count
    }),

    setVkUsersPage: (page: number) => ({
        type: "VK_INTERESTS_SET_PAGE" as const,
        page
    }),

    setVkUsersPerPage: (usersPerPage: number) => ({
        type: "VK_INTERESTS_SET_INTERESTS_PER_PAGE" as const,
        usersPerPage
    }),

    setCountries: (countries: Array<string>) => ({
        type: "VK_INTERESTS_SET_COUNTRIES" as const,
        countries
    }),

    setCities: (cities: Array<string>) => ({
        type: "VK_INTERESTS_SET_CITIES" as const,
        cities
    }),

    setRegions: (regions: Array<string>) => ({
        type: "VK_INTERESTS_SET_REGIONS" as const,
        regions
    }),

    setRegion: (region: string) => ({
        type: "VK_INTERESTS_SET_REGION" as const,
        region
    }),

    setCountry: (country: string) => ({
        type: "VK_INTERESTS_SET_COUNTRY" as const,
        country
    }),

    setCity: (city: string) => ({
        type: "VK_INTERESTS_SET_CITY" as const,
        city
    }),

    setSubculture: (subculture: string) => ({
        type: "VK_INTERESTS_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "VK_INTERESTS_SET_TAG" as const,
        tag
    }),

    setMinSubscribers: (minSubscribers: number) => ({
        type: "VK_INTERESTS_SET_MIN_SUBSCRIBERS" as const,
        minSubscribers
    }),

    setMaxSubscribers: (maxSubscribers: number) => ({
        type: "VK_INTERESTS_SET_MAX_SUBSCRIBERS" as const,
        maxSubscribers
    }),

    setKeyword: (keyword: string) => ({
        type: "VK_INTERESTS_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "VK_INTERESTS_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "VK_INTERESTS_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "VK_INTERESTS_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "VK_INTERESTS_SET_TAGS" as const,
        tags
    }),
    //TODO: refactor soccount (backend)
    setSocCount: (socCount: SocCountType) => ({
        type: "VK_INTERESTS_SET_SOC_COUNT" as const,
        socCount
    })
}

export const fetchVkUsers = (): ThunkActionType => (dispatch, getState) => {


    const filters = getState().vkInterestsReducer.filters;

    axios.post<Array<VkUserType>>('/api/vk/interests/get-by-filters', JSON.stringify(filters)).then(({ data }) => {
        dispatch(vkInterestActions.setVkUsers(data))
        dispatch(vkInterestActions.setVkUsersLoaded(true))
        dispatch(vkInterestActions.setVkUsersLoading(false))
    })
};

export const fetchVkUsersCount = (): ThunkActionType => (dispatch, getState) => {


    const filters = getState().vkInterestsReducer.filters;

    axios.post<number>("/api/vk/interests/get-pages-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(vkInterestActions.setVkUsersCount(data))
    })
}

export const fetchCities = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-cities")
        .then(({ data }) => {
            dispatch(vkInterestActions.setCities(data))
        })
}

export const fetchCountries = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-countries")
        .then(({ data }) => {
            dispatch(vkInterestActions.setCountries(data))
        })
}

export const fetchRegions = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-regions")
        .then(({ data }) => {
            dispatch(vkInterestActions.setRegions(data))
        })
}


export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkInterestsReducer.filters;
    axios.request<File>({ url: "/api/vk/interests/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob" })
        .then(response => {

            dispatch(vkInterestActions.setExcel(response.data))
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(vkInterestActions.setSubcultures(data))
    })
};

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/interests/get-tags").then(({ data }) => {
        dispatch(vkInterestActions.setTags(data))
    })
}

export const fetchSocCount = (): ThunkActionType => (dispatch, getState) => {
    axios.get<InfographicsDataType>("/api/social-statistics?social=vk&entity=user&last=true").then(({ data }) => {
        dispatch(vkInterestActions.setSocCount({ subculturesStatistics: data[0].subculturesStatistics, date: new Date(data[0].date).toLocaleDateString("ru-RU").slice(0, 10) }))
    })
}

export type VkInterestActionTypes = ReturnType<InferValueTypes<typeof vkInterestActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, VkInterestActionTypes>