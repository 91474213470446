import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import InstInfographics from '../InstInfographics/InstInfographics';
import InstPublications from '../InstPublications.js/InstPublications';
import InstUsers from '../InstUsers.js/InstUsers';

const InstTab: React.FC = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/publications`}>
                <InstPublications />
            </Route>
            <Route path={`${match.path}/users`}>
                <InstUsers />
            </Route>
            <Route path={`${match.path}/infographics`}>
                <InstInfographics />
            </Route>
            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/publications`} />
            </Route>
        </Switch>
    )

}

export default InstTab
