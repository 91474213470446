import { useCallback, useEffect } from 'react'
import TelegramFilters from '../TelegramFilters/TelegramFilters'
import TelegramNavbar from '../TelegramNavbar'
import { useDispatch, useSelector } from 'react-redux';
import { telegramPostActions, fetchExcel, fetchSubcultures, fetchTags, fetchTelegramPosts, fetchTelegramPostsCount } from '../../../redux/telegram/posts/actions';
import Pagination from '../../Pagination/Pagination';
import TelegramPostsItem from "./TelegramPostsItem";
import { RootStateType } from '../../../redux/reducers';
import _ from 'lodash';

const { clearFilters, setKeyword, setSubculture, setTag, setTelegramPostsLoading, setTelegramPostsPage, setTelegramPostsPerPage } = telegramPostActions

function TelegramPosts() {
    const dispatch = useDispatch();
    const { loaded, loading, posts, filters, count, excel, subcultures, tags } = useSelector((state: RootStateType) => state.telegramPostsReducer);
    const { subculture, tag, keyword } = filters;
    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setTelegramPostsPage(1))
        dispatch(fetchTelegramPosts());
        dispatch(fetchTelegramPostsCount());
        dispatch(fetchExcel());
    }, 500), [])

    const handlerApplyFilters = (): void => {
        dispatch(setTelegramPostsPage(1))
        dispatch(fetchTelegramPosts());
        dispatch(fetchTelegramPostsCount());
        dispatch(fetchExcel());
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
    }

    const onChangePage = (page: number): void => {
        dispatch(setTelegramPostsPage(page));
        dispatch(fetchTelegramPosts());
    }

    const handlerChangePostsPerPage = (postsPerPage: number): void => {
        dispatch(setTelegramPostsPerPage(postsPerPage));
        dispatch(setTelegramPostsPage(1));
        dispatch(fetchTelegramPosts());
        dispatch(fetchTelegramPostsCount());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(fetchTelegramPosts());
            dispatch(fetchTelegramPostsCount());
            dispatch(setTelegramPostsLoading(true));
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
        }
    });



    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <TelegramNavbar />
                    <TelegramFilters tag={tag} keyword={keyword} setSubculture={(subculture) => dispatch(setSubculture(subculture))}
                        setTag={(tag) => dispatch(setTag(tag))} subculture={subculture} setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters}
                        clearFilters={handlerClearFilters} excel={excel} subcultures={subcultures} tags={tags} />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content active" data-name="publications">
                            <table className="tbl">
                                <thead>
                                    <tr className="tbl__row_header">
                                        <th className="tbl__header_cell">Аватар</th>
                                        <th className="tbl__header_cell" hide-order="1">Превью</th>
                                        <th className="tbl__header_cell">Канал</th>
                                        <th className="tbl__header_cell" hide-order="2">Кол-во подпичиков</th>
                                        <th className="tbl__header_cell" hide-order="2">Кол-во просмотров</th>
                                        <th className="tbl__header_cell" hide-order="3">Теги</th>
                                        <th className="tbl__header_cell" hide-order="3">Субкультура</th>
                                        <th className="tbl__header_cell" hide-order="3">Проверено администратором</th>
                                        <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {posts.map((item, index) => <TelegramPostsItem data={item} key={index} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination count={count} activePage={filters.page} handlerChangeItemsPerPage={handlerChangePostsPerPage} itemsPerPage={filters.postsPerPage} onChangePage={onChangePage} />
                </div>

            </div>
        </div>

    )
}

export default TelegramPosts
