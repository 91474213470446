import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import axios from "axios";
import { InferValueTypes } from "../../../types/common";
import { VkGroupType } from "../../../types/vk";

export const vkGroupActions = {

    setVkGroupsLoaded: (loaded: boolean) => ({
        type: "VK_GROUPS_SET_LOADED" as const,
        loaded
    }),

    setVkGroups: (groups: Array<VkGroupType>) => ({
        type: "VK_GROUPS_SET_GROUPS" as const,
        groups
    }),

    setVkGroupsLoading: (loading: boolean) => ({
        type: "VK_GROUPS_SET_LOADING" as const,
        loading
    }),

    setVkGroupsCount: (count: number) => ({
        type: "VK_GROUPS_SET_GROUPS_COUNT" as const,
        count
    }),

    setVkGroupsPage: (page: number) => ({
        type: "VK_GROUPS_SET_PAGE" as const,
        page
    }),

    setVkGroupsPerPage: (groupsPerPage: number) => ({
        type: "VK_GROUPS_SET_GROUPS_PER_PAGE" as const,
        groupsPerPage
    }),

    setCountries: (countries: Array<string>) => ({
        type: "VK_GROUPS_SET_COUNTRIES" as const,
        countries
    }),

    setCities: (cities: Array<string>) => ({
        type: "VK_GROUPS_SET_CITIES" as const,
        cities
    }),

    setRegions: (regions: Array<string>) => ({
        type: "VK_GROUPS_SET_REGIONS" as const,
        regions
    }),

    setRegion: (region: string) => ({
        type: "VK_GROUPS_SET_REGION" as const,
        region
    }),

    setCountry: (country: string) => ({
        type: "VK_GROUPS_SET_COUNTRY" as const,
        country
    }),

    setCity: (city: string) => ({
        type: "VK_GROUPS_SET_CITY" as const,
        city
    }),

    setSubculture: (subculture: string) => ({
        type: "VK_GROUPS_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "VK_GROUPS_SET_TAG" as const,
        tag
    }),

    setMinSubscribers: (minSubscribers: number) => ({
        type: "VK_GROUPS_SET_MIN_SUBSCRIBERS" as const,
        minSubscribers
    }),

    setMaxSubscribers: (maxSubscribers: number) => ({
        type: "VK_GROUPS_SET_MAX_SUBSCRIBERS" as const,
        maxSubscribers
    }),

    setKeyword: (keyword: string) => ({
        type: "VK_GROUPS_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "VK_GROUPS_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "VK_GROUPS_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "VK_GROUPS_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "VK_GROUPS_SET_TAGS" as const,
        tags
    })
}

export const fetchVkGroups = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkGroupsReducer.filters;

    axios.post<Array<VkGroupType>>('/api/vk/groups/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(vkGroupActions.setVkGroups(data))
        dispatch(vkGroupActions.setVkGroupsLoaded(true))
        dispatch(vkGroupActions.setVkGroupsLoading(false))
    })
};

export const fetchVkGroupsCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkGroupsReducer.filters;

    axios.post<number>("/api/vk/groups/get-pages-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(vkGroupActions.setVkGroupsCount(data))
    })
};

export const fetchCities = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-cities")
        .then(({ data }) => {
            dispatch(vkGroupActions.setCities(data))
        })
}

export const fetchCountries = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-countries")
        .then(({ data }) => {
            dispatch(vkGroupActions.setCountries(data))
        })
}

export const fetchRegions = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-regions")
        .then(({ data }) => {
            dispatch(vkGroupActions.setRegions(data))
        })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().vkGroupsReducer.filters;
    axios.request<File>({ url: "/api/vk/groups/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob" })
        .then(response => {
            if (response) {
                dispatch(vkGroupActions.setExcel(response.data))
            }
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(vkGroupActions.setSubcultures(data))
    })
};

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/vk/groups/get-tags").then(({ data }) => {
        dispatch(vkGroupActions.setTags(data))
    })
}

export type VkGroupActionTypes = ReturnType<InferValueTypes<typeof vkGroupActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, VkGroupActionTypes>