import React from "react";
import { applyMiddleware, compose, createStore } from "redux";
import { rootReducer } from "./redux/reducers";
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import AppContainer from "./AppContainer";
import { BrowserRouter } from "react-router-dom";

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));
// @ts-ignore
window.store = store

const App = () => {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <AppContainer />
            </Provider>
        </BrowserRouter>
    );
}
export default App;
