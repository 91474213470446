import {LoginActionTypes} from "./actions";

const defaultState = {
    email: "",
    password: "",
    loading: false
}

type LoginStateType = typeof defaultState

export const loginReducer = (state = defaultState, action: LoginActionTypes): LoginStateType => {
    switch (action.type) {
        case "LOGIN_SET_EMAIL":
            return {
                ...state,
                email: action.email
            }
        case "LOGIN_SET_PASSWORD":
            return {
                ...state,
                password: action.password
            }
        case "LOGIN_SET_LOADING":
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
}