import React from "react"
import {
    Switch,
    Route,
    useRouteMatch, Redirect
} from "react-router-dom";
import VkGroups from "../VkGroups/VkGroups";
import VkAdmins from "../VkAdmins/VkAdmins";
import VkSubscribers from "../VkSubscribers/VkSubscribers";
import VkInterests from "../VkInterests/VkInterests";
import VkUsers from "../VkUsers/VkUsers";
import VkInfographics from "../VkInfographics/VkInfographics";

const VkTab: React.FC = () => {
    let match = useRouteMatch();
    

    return (
        <Switch>
            <Route path={`${match.path}/communities`}>
                <VkGroups />
            </Route>
            <Route path={`${match.path}/administrators`}>
                <VkAdmins />
            </Route>
            <Route path={`${match.path}/subscribers`}>
                <VkSubscribers />
            </Route>
            <Route path={`${match.path}/interests`}>
                <VkInterests />
            </Route>
            <Route path={`${match.path}/users`}>
                <VkUsers />
            </Route>
            <Route path={`${match.path}/infographics`}>
                <VkInfographics />
            </Route>
            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/communities`} />
            </Route>
        </Switch>
    )
}

export default VkTab;