import { InferValueTypes } from "../../../types/common"

export const recoveryActions = {
    setEmail: (email: string) => ({
        type: "RECOVERY_SET_EMAIL" as const,
        email
    }),

    setStatus: (status: boolean) => ({
        type: "RECOVERY_SET_STATUS" as const,
        status
    })
}

export type RecoveryActionTypes = ReturnType<InferValueTypes<typeof recoveryActions>>