import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EditorUsersTable from "./EditorUsersTable/EditorUsersTable";
import EditorUser from "./EditorUser/EditorUser";
import EditorParsing from "./EditorParsing";


const EditorPanel: React.FC = () => {
    let match = useRouteMatch();

    return (
        <Switch>

            <Route exact path={`${match.path}`}>
                <EditorUsersTable />
            </Route>
            <Route exact path={`${match.path}/:id`}>
                <EditorUser />
            </Route>
            <Route exact path={`${match.path}/parser`}>
                    <EditorParsing />
                </Route> 
        </Switch>
    )
}

export default EditorPanel;