import { UserFormType } from './../../types/users.d';
import { RootStateType } from './../reducers';
import { ThunkAction } from 'redux-thunk';
import { InferValueTypes } from './../../types/common';
import { UserType } from './../../types/users';
import axios from "axios";

export const editorActions = {

    setUsers: (users: Array<UserType>) => ({
        type: "EDITOR_USERS_SET_USERS" as const,
        users
    }),

    setUsersLoaded: (loaded: boolean) => ({
        type: "EDITOR_USERS_SET_LOADED" as const,
        loaded
    }),

    setUsersLoading: (loading: boolean) => ({
        type: "EDITOR_USERS_SET_LOADING" as const,
        loading
    }),

    setUserLoaded: (loaded: boolean) => ({
        type: "EDITOR_USER_SET_LOADED" as const,
        loaded
    }),

    setUserInfo: (data: UserType & UserFormType) => ({
        type: "EDITOR_USER_SET_INFO" as const,
        data
    }),

    setUserFirstName: (firstName: string) => ({
        type: "EDITOR_USER_SET_FIRST_NAME" as const,
        firstName
    }),

    setUserLastName: (lastName: string) => ({
        type: "EDITOR_USER_SET_LAST_NAME" as const,
        lastName
    }),

    setUserPatronymic: (patronymic: string) => ({
        type: "EDITOR_USER_SET_PATRONYMIC" as const,
        patronymic
    }),

    setUserDistrict: (district: string) => ({
        type: "EDITOR_USER_SET_DISTRICT" as const,
        district
    }),

    setUserCity: (city: string) => ({
        type: "EDITOR_USER_SET_CITY" as const,
        city
    }),

    setUserWorkpalce: (workplace: string) => ({
        type: "EDITOR_USER_SET_WORKPLACE" as const,
        workplace
    }),

    setUserPosition: (position: string) => ({
        type: "EDITOR_USER_SET_POSITION" as const,
        position
    }),

    setUserEmail: (email: string) => ({
        type: "EDITOR_USER_SET_EMAIL" as const,
        email
    }),

    setUserPassword: (password: string) => ({
        type: "EDITOR_USER_SET_PASSWORD" as const,
        password
    }),

    setUserRepeatPassword: (repeatPassword: string) => ({
        type: "EDITOR_USER_SET_REPEAT_PASSWORD" as const,
        repeatPassword
    }),

    setChecked: (checked: boolean, index: number) => ({
        type: "EDITOR_USER_SET_CHECKED" as const,
        checked,
        index
    }),

    setImageFile: (file: File) => ({
        type: "EDITOR_USER_SET_IMAGE_FILE" as const,
        file
    }),

    setUserRole: (role: string, index: number) => ({
        type: "EDITOR_USERS_SET_ROLE" as const,
        role,
        index
    }),

    setUserChecked: (checked: boolean, index: number) => ({
        type: "EDITOR_USERS_SET_CHECKED" as const,
        checked,
        index
    }),

    setUsersCount: (count: number) => ({
        type: "EDITOR_USERS_SET_USERS_COUNT" as const,
        count
    }),

    setUsersPerPage: (usersPerPage: number) => ({
        type: "EDITOR_USERS_SET_USERS_PER_PAGE" as const,
        usersPerPage
    }),

    setActivePage: (activePage: number) => ({
        type: "EDITOR_USERS_SET_ACTIVE_PAGE" as const,
        activePage
    }),

    setRoleFilter: (role: string) => ({
        type: "EDITOR_USERS_SET_ROLE_FILTER" as const,
        role
    }),

    setStatusFilter: (status: string) => ({
        type: "EDITOR_USERS_SET_STATUS_FILTER" as const,
        status
    }),

    setKeywordFilter: (keyword: string) => ({
        type: "EDITOR_USERS_SET_KEY_WORD_FILTER" as const,
        keyword
    })
}

export const changeUserRole = (index: number): ThunkActionType => (dispatch, getState) => {
    const user = getState().editorReducer.users.users[index];

    axios.post("/editor/change-user-role", user).then(() => {
        dispatch(editorActions.setUsersLoading(true))
        dispatch(editorActions.setUsersLoaded(true))
    })
};

export const setCheckedUserDB = (index: number): ThunkActionType => (dispatch, getState) => {
    const email = getState().editorReducer.users.users[index].email;

    axios.post<number>('/editor/set-checked-new-user', JSON.stringify({ email })).then(({ data }) => {
        if (data === 200) {
            alert("Успешно сохранено!")
        } else {
            alert("Ошибка!")
        }

        dispatch(fetchUsers())
    }).catch(({ body }) => {
        alert("Ошибка!")
    })

};

export const fetchUsers = (keywordOnly = false): ThunkActionType => (dispatch, getState) => {
    const filters = getState().editorReducer.users.filters;

    axios.post<Array<UserType>>('/editor/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(editorActions.setUsers(data))
        dispatch(editorActions.setUsersLoaded(true))
        dispatch(editorActions.setUsersLoading(false))
    })
};

export const updateUser = (): ThunkActionType => (dispatch, getState) => {
    const userState = getState().editorReducer.user.userInfo;

    const user = {
        firstName: userState.firstName,
        lastName: userState.lastName,
        patronymic: userState.patronymic,
        district: userState.district,
        city: userState.city,
        workplace: userState.workplace,
        position: userState.position,
        checked: userState.checkedInput,
        email: userState.email,
        password: userState.password,
        id: userState.id
    }

    axios.post<number>('/editor/update-user', JSON.stringify(user))
        .then(({ data }) => {
            if (data === 200) {
                alert("Успешно сохранено!")
            } else {
                alert("Ошибка!")
            }
        })
        .then(async () => {
            if (getState().editorReducer.user.userInfo.imageFile) {
                const data = {
                    imageFile: getState().editorReducer.user.userInfo.imageFile,
                    email: getState().editorReducer.user.userInfo.email
                }

                if (!data.imageFile) {
                    return
                }

                const imgData = new FormData()
                imgData.append("imageFile", data.imageFile)
                imgData.append("email", data.email)

                await axios.post('/api/account/upload_image', imgData).catch(err => {
                    console.log(err)
                })
            }
        })
        .then(() => {

            dispatch(fetchUsers())
            dispatch(fetchUserInfo(user.id))

        })
        .catch(({ body }) => {
            alert("Ошибка!")
        })
};

export const fetchUserInfo = (id: number): ThunkActionType => (dispatch, getState) => {
    axios.post<UserType>("/editor/get-user-info", JSON.stringify({ id })).then(({ data }) => {
        const newData = {
            ...data,
            firstNameTitle: data.firstName,
            lastNameTitle: data.lastName,
            patronymicTitle: data.patronymic,
            repeatPassword: "",
            password: "",
            checkedInput: data.checked,
            imageFile: null
        };

        dispatch(editorActions.setUserInfo(newData))
    })
};

export const deleteUser = (userId: number): ThunkActionType => async dispatch => {
    await axios.post("/editor/delete-user", JSON.stringify({ userId }))

    dispatch(fetchUsers())
    dispatch(fetchUsersCount())
}

export const fetchUsersCount = (keywordOnly = false): ThunkActionType => (dispatch, getState) => {
    const filters = getState().editorReducer.users.filters;

    axios.post<number>("/editor/get-users-count", JSON.stringify(filters)).then(({ data: count }) => {
        dispatch(editorActions.setUsersCount(count))
    })
}

export type EditorActionTypes = ReturnType<InferValueTypes<typeof editorActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, EditorActionTypes>