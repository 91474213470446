import React, { useState } from 'react'
import { deleteSubculture } from '../../../redux/subcultures/actions';
import { ThunkDispatchType } from '../../../types/common';
import { SocialTypes } from '../../../types/socials';
import { SubcultureType } from '../../../types/subcultures';

type PropsType = {
    count: {
        [key in SocialTypes]: number
    }
    data: SubcultureType
    role: string
    dispatch: ThunkDispatchType
}

const SubItem: React.FC<PropsType> = ({ data, count, dispatch, role }) => {
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)


    const { subcultureName, subcultureDescription } = data;

    return (
        <tr className="tbl__row">
            <td className="tbl__cell">
                {subcultureName}
            </td>
            <td className={`tbl__cell ${subcultureDescription ? "" : "empty-cell"}`} hide-order="1">
                <div className="lineclamp-3">
                    {subcultureDescription}
                </div>
            </td>
            <td className="tbl__cell" hide-order="2">
                <div className="number-format">{count && count["telegram"]}</div>
            </td>
            <td className="tbl__cell" hide-order="2">
                <div className="number-format">{count && count["vk"]}</div>
            </td>
            <td className="tbl__cell" hide-order="2">
                <div className="number-format">{count && count["instagram"]}</div>
            </td>
            <td className="tbl__cell" hide-order="2">
                <div className="number-format">{count && count["tiktok"]}</div>
            </td>
            {role === "ROLE_ADMIN" &&
                <td className="tbl__cell" hide-order="1">
                    <button onClick={() => dispatch(deleteSubculture(data))} className="btn btn-danger">Удалить</button>
                </td>
            }
            <td className="tbl__cell showmore-mob" onClick={() => setShowMoreActive(!isShowMoreActive)}>
                <div className="tbl__showmore disactive"></div>
            </td>
        </tr>
    )
}

export default SubItem
