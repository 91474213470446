import React from "react";
import {
    NavLink,
    Link,
    useHistory
} from "react-router-dom";


const VkNavBar: React.FC = () => {
    const navTabs = [["communities", "Сообщества"], ["administrators", "Администраторы"],
    ["users", "Пользователи"], ["subscribers", "Подписчики"], ["interests", "Интересы"], ["infographics", "Инфографика"]];
    const history = useHistory();

    const onChangeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        history.push(`/vk/${e.target.value}`)
    }

    return (
        <>
            <ul className="tabbed-panel__tabs d-none d-xl-flex">
                {navTabs.map((item, index) => <NavLink to={`/vk/${item[0]}`} key={`${item}_${index}`} className={`tabbed-panel__tab`} data-target={item[0]}>{item[1]}</NavLink>)}
            </ul>
            <select className="tabbed-panel__mob_tabs d-inline-block d-xl-none" onChange={onChangeSelectHandler} value={window.location.href.split("/")[window.location.href.split("/").length - 1]}>
                {navTabs.map((item, index) => <option key={`${item}_${index}`} value={item[0]}>{item[1]}</option>)}
            </select>
        </>
    )
}

export default VkNavBar;