import { InstagramUserType } from './../../types/instagram';
import { InferValueTypes } from '../../types/common';
import { RootStateType } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import axios from "axios";

export const instagramActions = {
    setInstUsersLoaded: (loaded: boolean) => ({
        type: "INST_USERS_SET_LOADED" as const,
        loaded
    }),

    setInstUsers: (users: Array<InstagramUserType>) => ({
        type: "INST_USERS_SET_USERS" as const,
        users
    }),

    setInstUsersLoading: (loading: boolean) => ({
        type: "INST_USERS_SET_LOADING" as const,
        loading
    }),

    setInstUsersCount: (count: number) => ({
        type: "INST_USERS_SET_USERS_COUNT" as const,
        count
    }),

    setInstUsersPage: (page: number) => ({
        type: "INST_USERS_SET_PAGE" as const,
        page
    }),

    setInstUsersPerPage: (usersPerPage: number) => ({
        type: "INST_USERS_SET_USERS_PER_PAGE" as const,
        usersPerPage
    }),

    setSubculture: (subculture: string) => ({
        type: "INST_USERS_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "INST_USERS_SET_TAG" as const,
        tag
    }),

    setKeyword: (keyword: string) => ({
        type: "INST_USERS_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "INST_USERS_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "INST_USERS_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "INST_USERS_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "INST_USERS_SET_TAGS" as const,
        tags
    })
}

export const fetchInstUsers = (keywordOnly = false): ThunkActionType => (dispatch, getState) => {
    const filters = getState().instUsersReducer.filters
    // const { keyword } = stateFilters

    // const filters = keywordOnly ? { keyword } : stateFilters;

    axios.post<Array<InstagramUserType>>('/api/insta/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(instagramActions.setInstUsers(data));
        dispatch(instagramActions.setInstUsersLoaded(true));
        dispatch(instagramActions.setInstUsersLoading(false));
    })
};

export const fetchInstUsersCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().instUsersReducer.filters;
    axios.post<number>("/api/insta/get-pages-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(instagramActions.setInstUsersCount(data));
    })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().instUsersReducer.filters;
    axios.request<File>({ url: "/api/insta/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob" })
        .then(response => {
            dispatch(instagramActions.setExcel(response.data));
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(instagramActions.setSubcultures(data));
    })
};

export const fetchTags = (): ThunkActionType => (dispatch) => {
    axios.get<Array<string>>("/api/insta/get-tags").then(({ data }) => {
        dispatch(instagramActions.setTags(data));
    })
}

type ThunkActionType = ThunkAction<void, RootStateType, unknown, InstagramActionTypes>
export type InstagramActionTypes = ReturnType<InferValueTypes<typeof instagramActions>>