import { SocialCountsType } from './../../types/socials.d';
import { SubcultureType } from './../../types/subcultures';
import {
    SubcultureActionTypes
} from "./actions";

const defaultState = {
    subcultures: {
        subcultures: [] as Array<SubcultureType>,
        //TODO: refactor count property
        count: {} as SocialCountsType,
        loaded: false,
        loading: false,
    },
    filters: {
        page: 1,
        subculture: "-",
        tag: "-",
        keyword: "",
        subculturesPerPage: 8,
    },
    newSubculture: {
        about: "",
        subculture: "",
    },
    count: 0,
    excel: null as File | null,
    filtersSubcultures: [] as Array<string>,
    filtersTags: [] as Array<string>
}

type SubculturesStateType = typeof defaultState

export const subculturesReducer = (state = defaultState, action: SubcultureActionTypes): SubculturesStateType => {
    switch (action.type) {
        case "SUBCULTURES_SET_SUBCULTURES":
            return {
                ...state,
                subcultures: {
                    ...state.subcultures,
                    subcultures: action.subcultures
                }
            }
        case "SUBCULTURES_SET_LOADED":
            return {
                ...state,
                subcultures: {
                    ...state.subcultures,
                    loaded: action.loaded
                }
            }
        case "SUBCULTURES_SET_LOADING":
            return {
                ...state,
                subcultures: {
                    ...state.subcultures,
                    loading: action.loading
                }
            }
        case "SUBCULTURES_SET_SUBCULTURES_COUNT": {
            return {
                ...state,
                count: action.count
            }
        }
        case "SUBCULTURES_SET_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: action.page
                }
            }
        }
        case "SUBCULTURES_SET_SUBCULTURES_PER_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculturesPerPage: action.adminsPerPage
                }
            }
        }
        case "SUBCULTURES_SET_FILTERS_TAG": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    tag: action.tag
                }
            }
        }
        case "SUBCULTURES_SET_FILTERS_SUBCULTURE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: action.subculture
                }
            }
        }
        case "SUBCULTURES_SET_KEYWORD": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    keyword: action.keyword
                }
            }
        }
        case "SUBCULTURES_CLEAR_FILTERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: "-",
                    tag: "-",
                    keyword: ""
                }
            }
        }
        case "SUBCULTURES_SET_EXCEL": {
            return {
                ...state,
                excel: action.blob
            }
        }
        case "SUBCULTURES_SET_FILTERS_SUBCULTURES": {
            return {
                ...state,
                filtersSubcultures: action.subcultures
            }
        }
        case "SUBCULTURES_SET_FILTERS_TAGS": {
            return {
                ...state,
                filtersTags: action.tags
            }
        }
        case "SUBCULTURES_SET_COUNT": {
            return {
                ...state,
                subcultures: {
                    ...state.subcultures,
                    count: action.count
                }
            }
        }
        case "SUBCULTURES_NEW_SUBCULTURE_CLEAR_SUBCULTURE_FORM": {
            return {
                ...state,
                newSubculture: {
                    subculture: "",
                    about: ""
                }
            }
        }
        case "SUBCULTURES_SET_NEW_SUBCULTURE_ABOUT": {
            return {
                ...state,
                newSubculture: {
                    ...state.newSubculture,
                    about: action.about
                }
            }
        }
        case "SUBCULTURES_SET_NEW_SUBCULTURE_NAME": {
            return {
                ...state,
                newSubculture: {
                    ...state.newSubculture,
                    subculture: action.subcultureName
                }
            }
        }
        default:
            return state;
    }
}