import { RootStateType } from './../reducers';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios'
import { InferValueTypes } from '../../types/common';
import { ImageType } from '../../types/images';

export const imageActions = {
    setImagesLoaded: (loaded: boolean) => ({
        type: "IMAGES_SET_LOADED" as const,
        loaded
    }),

    setImages: (images: Array<ImageType>) => ({
        type: "IMAGES_SET_IMAGES" as const,
        images
    }),

    setImagesLoading: (loading: boolean) => ({
        type: "IMAGES_SET_LOADING" as const,
        loading
    }),

    setImagesCount: (count: number) => ({
        type: "IMAGES_SET_IMAGES_COUNT" as const,
        count
    }),

    setImagesPage: (page: number) => ({
        type: "IMAGES_SET_PAGE" as const,
        page
    }),

    setImagesPerPage: (imagesPerPage: number) => ({
        type: "IMAGES_SET_IMAGES_PER_PAGE" as const,
        imagesPerPage
    }),

    setSubculture: (subculture: string) => ({
        type: "IMAGES_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "IMAGES_SET_TAG" as const,
        tag
    }),

    setKeyword: (keyword: string) => ({
        type: "IMAGES_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "IMAGES_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "IMAGES_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "IMAGES_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "IMAGES_SET_TAGS" as const,
        tags
    })
}

export const fetchImages = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().imagesReducer.filters;
    console.log(filters)

    axios.post<Array<ImageType>>('/api/images/get', filters).then(({ data }) => {
        dispatch(imageActions.setImages(data))
        dispatch(imageActions.setImagesLoaded(true))
        dispatch(imageActions.setImagesLoading(false))
    })
};

export const fetchImagesCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().imagesReducer.filters;

    axios.post<number>("/api/images/get-count", filters).then(({ data }) => {
        dispatch(imageActions.setImagesCount(data))
    })
};

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().imagesReducer.filters;
    axios.post<File>("/api/images/create-excel",JSON.stringify(filters))
        .then(response => {
            
            dispatch(imageActions.setExcel(response.data))
        })
};

export const fetchSubcultures = (): ThunkActionType => (dispatch) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(imageActions.setSubcultures(data))
    })
};

export const fetchTags = (): ThunkActionType => (dispatch) => {
    axios.get<Array<string>>("/api/images/get-tags").then(({ data }) => {
        dispatch(imageActions.setTags(data))
    })
}

export type ImageActionTypes = ReturnType<InferValueTypes<typeof imageActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, ImageActionTypes>