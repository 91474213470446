import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useRouteMatch } from "react-router";
import { UserType } from '../../../types/users';
import { convertDate } from '../../../utils/date';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

type PropsType = {
    data: UserType
    index: number
    checkboxParentValue: boolean
    accountRole: string
    deleteUser: (userId: number) => void
    onChangeRole: (role: string, index: number) => void
    onCheck: (index: number) => void
}

const EditorUsersTableItem: React.FC<PropsType> = ({ data, onChangeRole, onCheck, index, checkboxParentValue, accountRole, deleteUser }) => {
    const { firstName, lastName, patronymic, city, workplace, position, email, roles, checked, district, imgUrl, id, lastVisit } = data;
    const r = Math.floor(Math.random() * 100000);
    const match = useRouteMatch();
    const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
    const [isShowMoreActive, setShowMoreActive] = useState<boolean>(false)
    const [isSelectActive, setSelectActive] = useState<boolean>(false)

    const date = new Date(Number(lastVisit));

    const hours = convertDate(String(date.getHours()));
    const minutes = convertDate(String(date.getMinutes()));
    const seconds = convertDate(String(date.getSeconds()));
    const day = convertDate(String(date.getDate()));
    const month = convertDate(String(date.getMonth() + 1));
    const year = String(date.getFullYear());

    return (
        <>
            <tr className={classNames("tbl__row", { active: isShowMoreActive })}>
                <td className="tbl__cell">
                    <div className="form__checkbox_wrap">
                        <input checked={checkboxParentValue ? true : checkboxValue ? true : false} type="checkbox" className="form__checkbox_input grey-checkbox no-margin" />
                        <label onClick={() => setCheckboxValue(!checkboxValue)} className="form__checkbox_input_mark" htmlFor={`c${uniqueId()}`}></label>
                    </div>
                </td>
                <td className="tbl__cell">
                    <img className="user-list__avatar" src={imgUrl ? "/img/account/" + imgUrl : "https://icon-library.com/images/no-user-image-icon/no-user-image-icon-27.jpg"} alt="" />
                </td>
                <td className="tbl__cell user-list__fio_cell">{lastName} {firstName} {patronymic}</td>
                <td className={`tbl__cell ${district ? "" : "empty-cell"}`} hide-order="2">{district}</td>
                <td className={`tbl__cell ${city ? "" : "empty-cell"}`} hide-order="2">{city}</td>
                <td className={`tbl__cell ${workplace ? "" : "empty-cell"}`} hide-order="1">{workplace}</td>
                <td className={`tbl__cell ${position ? "" : "empty-cell"}`} hide-order="1">{position}</td>
                <td className="tbl__cell" hide-order="4">{email}</td>
                <td className="tbl__cell" hide-order="3">
                    <div className="user-list__editable-value role-choice">
                        <select value={roles} onChange={(e) => {
                            onChangeRole(e.target.value, index)
                        }} name="" id="" className="editable-value__select" disabled={!isSelectActive}>
                            <option value="ROLE_USER" style={{ display: accountRole === "ROLE_ADMIN" || roles !== "ROLE_ADMIN" ? 'inherit' : 'none' }}>Пользователь</option>
                            <option value="ROLE_EDITOR" style={{ display: accountRole === "ROLE_ADMIN" || roles !== "ROLE_ADMIN" ? 'inherit' : 'none' }}>Модератор</option>
                            <option value="ROLE_ADMIN" style={{ display: accountRole === "ROLE_ADMIN" ? 'inherit' : 'none' }}>Администратор</option>
                        </select>
                        {!isSelectActive &&
                            <a href="javascript:;" className="editable-value__edit-icon" onClick={() => setSelectActive(true)} ></a>}
                    </div>
                </td>
                <td className="tbl__cell" hide-order="3">
                    <div className={checked ? "text_success" : "text_danger"}>{checked ? "Одобрен" : "Не одобрен"}</div>
                </td>
                {

                    <td className="tbl__cell" hide-order="3">
                        <div className="user-list__editable-value role-choice">
                            {accountRole === "ROLE_ADMIN" || ((roles === "ROLE_EDITOR" || roles == "ROLE_USER") && accountRole === "ROLE_EDITOR") ? <button onClick={deleteUser.bind(this, id)}>Удалить пользователя</button> : null}
                        </div>
                    </td>

                }
                <td className="tbl__cell">
                    <div className={classNames("tbl__showmore", { disactive: !isShowMoreActive }, { active: isShowMoreActive })} onClick={() => setShowMoreActive(!isShowMoreActive)}></div>
                </td>
            </tr>
            <tr className="tbl__row-hidden">
                <td className="tbl__cell" colSpan={99}>
                    <div className="tbl__row-hidden_content">
                        {Number(lastVisit) ?
                            <div className="user-list__lastvisit"><span className="mr-1 mr-xs-3">Посещал систему:</span> <span className="mr-1 mr-xs-3">{hours}:{minutes}:{seconds}</span>&nbsp;<span>{day}.{month}.{year}</span></div>
                            :
                            null
                        }
                        <Link to={`${match.url}/${id}`}>Редактирование данных</Link>
                        {!data.checked ? <a onClick={() => onCheck(index)} href="javascript:;" className="button button_green button-check">Одобрить</a> : null}
                        <a href="javascript:;" className="button button_green">Обратиться к администратору</a>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default EditorUsersTableItem;
