import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import TelegramPosts from '../TelegramPosts/TelegramPosts';
import TelegramChannels from '../TelegramChannels/TelegramChannels';
import TelegramInfoprahics from '../TelegramInfoprahics/TelegramInfoprahics';

const TelegramTab: React.FC = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/posts`}>
                <TelegramPosts/>
            </Route>
            <Route path={`${match.path}/channels`}>
                <TelegramChannels/>
            </Route>
            <Route path={`${match.path}/infographics`}>
                <TelegramInfoprahics />
            </Route>
            <Route path={`${match.path}`}>
                <Redirect to={`${match.path}/posts`} />
            </Route>
        </Switch>
    )

}

export default TelegramTab
