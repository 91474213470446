import _ from 'lodash';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, fetchUsersCount, editorActions } from '../../../redux/editor/actions';
import { RootStateType } from '../../../redux/reducers';

const { setActivePage, setKeywordFilter, setRoleFilter, setStatusFilter } = editorActions

const EditorUsersTableFilters: React.FC = () => {
    const dispatch = useDispatch();
    const { filters } = useSelector((state: RootStateType) => state.editorReducer.users);
    const { status, role, keyword } = filters

    const applyFilters = () => {
        dispatch(setRoleFilter(role));
        dispatch(setStatusFilter(status));
        dispatch(setKeywordFilter(keyword));
        dispatch(setActivePage(1));
        dispatch(fetchUsers());
        dispatch(fetchUsersCount());
    }

    const debouncedFetchPage = _.debounce(() => {
        dispatch(setActivePage(1));
        dispatch(fetchUsers(true));
        dispatch(fetchUsersCount(true));
    }, 500)

    return (
        <div className="control-panel__header">
            <form className="form form_row" onSubmit={e => {
                e.preventDefault()
                return false;
            }}>
                <input type="text" value={keyword} onChange={e => {
                    dispatch(setKeywordFilter(e.target.value))
                    debouncedFetchPage()
                }} className="form__row_item form__row_input search-input flexgrow-3" placeholder="Поиск по ключевому слову или фразе" />
                <select value={role} onChange={e => dispatch(setRoleFilter(e.target.value))} name="" id="" className="form__row_item form__row_select grow-0">
                    <option value="-">Роль</option>
                    <option value="ROLE_ADMIN">Администратор</option>
                    <option value="ROLE_EDITOR">Модератор</option>
                    <option value="ROLE_USER">Пользователь</option>
                </select>
                <select value={status} onChange={e => dispatch(setStatusFilter(e.target.value))} name="" id="" className="form__row_item form__row_select grow-0">
                    <option value="-">Статус</option>
                    <option value="checked">Одобрен</option>
                    <option value="unchecked">Не одобрен</option>
                </select>
                <button type="submit" onClick={applyFilters} className="form__row_item button button_darkgreen grow-0">Применить</button>
            </form>
        </div >
    )
}

export default EditorUsersTableFilters
