import { DynamicInfographicsDataType, StaticInfographicsDataType } from './../../../types/infographics.d';
import { VkInfographicsActionTypes } from './actions';
const defaultState = {
    groups: {
        static: {} as StaticInfographicsDataType,
        dynamic: {
            datasets: {},
            labels: []
        } as DynamicInfographicsDataType
    },
    users: {
        static: {} as StaticInfographicsDataType,
        dynamic: {
            datasets: {},
            labels: []
        } as DynamicInfographicsDataType
    },
    admins: {
        static: {} as StaticInfographicsDataType,
        dynamic: {
            datasets: {},
            labels: []
        } as DynamicInfographicsDataType
    },
    loaded: false,
}

type VkInfographicsStateType = typeof defaultState

export const vkInfographicsReducer = (state = defaultState, action: VkInfographicsActionTypes): VkInfographicsStateType => {
    switch (action.type) {
        case "VK_INFOGRAPHICS_SET_STATIC_GROUPS":
            return {
                ...state,
                groups: {
                    ...state.groups,
                    static: action.staticGroupsInfographicsData
                }
            }
        case "VK_INFOGRAPHICS_SET_STATIC_USERS":
            return {
                ...state,
                users: {
                    ...state.users,
                    static: action.staticUsersInfographicsData
                }
            }
        case "VK_INFOGRAPHICS_SET_STATIC_ADMINS":
            return {
                ...state,
                admins: {
                    ...state.admins,
                    static: action.staticAdminsInfographicsData
                }
            }
        case "VK_INFOGRAPHICS_SET_DYNAMYC_GROUPS":
            return {
                ...state,
                groups: {
                    ...state.groups,
                    dynamic: action.dynamicGroupsInfographicsData
                }
            }
        case "VK_INFOGRAPHICS_SET_DYNAMYC_USERS":
            return {
                ...state,
                users: {
                    ...state.users,
                    dynamic: action.dynamicUsersInfographicsData
                }
            }
        case "VK_INFOGRAPHICS_SET_DYNAMIC_ADMINS":
            return {
                ...state,
                admins: {
                    ...state.admins,
                    dynamic: action.dynamicAdminsInfographicsData
                }
            }
        case "VK_INFOGRAPHICS_SET_LOADED":
            return {
                ...state,
                loaded: action.loaded

            }

        default:
            return state
    }
}