import { SocCountType } from './../../../types/vk.d';
import { VkGroupType } from './../../../types/vk';
import {
    VkSubscriberActionTypes
} from "./actions";

const defaultState = {
    filters: {
        page: 1,
        subculture: "-",
        tag: "-",
        country: "-",
        region: "-",
        city: "-",
        membersCountMin: 0,
        membersCountMax: 500000,
        name: "",
        groupsPerPage: 8
    },
    loaded: false,
    loading: false,
    groups: [] as Array<VkGroupType>,
    countries: [] as Array<string>,
    cities: [] as Array<string>,
    regions: [] as Array<string>,
    count: 0,
    excel: null as File | null,
    subcultures: [] as Array<string>,
    tags: [] as Array<string>,
    socCount: {
        subculturesStatistics: {},
        date: ""
    } as SocCountType
};

type VkSubscribersStateType = typeof defaultState

export const vkSubscribersReducer = (state = defaultState, action: VkSubscriberActionTypes): VkSubscribersStateType => {
    switch (action.type) {
        case "VK_SUBSCRIBERS_SET_LOADED": {
            return {
                ...state,
                loaded: action.loaded
            }
        }
        case "VK_SUBSCRIBERS_SET_SUBSCRIBERS": {
            return {
                ...state,
                groups: action.groups
            }
        }
        case "VK_SUBSCRIBERS_SET_LOADING": {
            return {
                ...state,
                loading: action.loading
            }
        }
        case "VK_SUBSCRIBERS_SET_SUBSCRIBERS_COUNT": {
            return {
                ...state,
                count: action.count
            }
        }
        case "VK_SUBSCRIBERS_SET_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: action.page
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_SUBSCRIBERS_PER_PAGE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    groupsPerPage: action.groupsPerPage
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_COUNTRIES": {
            return {
                ...state,
                countries: action.countries
            }
        }
        case "VK_SUBSCRIBERS_SET_CITIES": {
            return {
                ...state,
                cities: action.cities
            }
        }
        case "VK_SUBSCRIBERS_SET_REGIONS": {
            return {
                ...state,
                regions: action.regions
            }
        }
        case "VK_SUBSCRIBERS_SET_CITY": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    city: action.city
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_COUNTRY": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    country: action.country
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_REGION": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    region: action.region
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_TAG": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    tag: action.tag
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_SUBCULTURE": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: action.subculture
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_MAX_SUBSCRIBERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    membersCountMax: action.maxSubscribers
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_MIN_SUBSCRIBERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    membersCountMin: action.minSubscribers
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_KEYWORD": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    name: action.keyword
                }
            }
        }
        case "VK_SUBSCRIBERS_CLEAR_FILTERS": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    subculture: "-",
                    tag: "-",
                    country: "-",
                    region: "-",
                    city: "-",
                    membersCountMin: 0,
                    membersCountMax: 500000,
                    name: ""
                }
            }
        }
        case "VK_SUBSCRIBERS_SET_EXCEL": {
            return {
                ...state,
                excel: action.blob
            }
        }
        case "VK_SUBSCRIBERS_SET_SUBCULTURES": {
            return {
                ...state,
                subcultures: action.subcultures
            }
        }

        case "VK_SUBSCRIBERS_SET_TAGS": {
            return {
                ...state,
                tags: action.tags
            }
        }
        case "VK_SUBSCRIBERS_SET_SOC_COUNT": {
            return {
                ...state,
                socCount: action.socCount
            }
        }
        default:
            return state;
    }
};