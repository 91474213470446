import React, { useCallback, useEffect } from 'react'
import InstFilters from '../InstFilters'
import InstNavBar from '../InstNavBar'
import Pagination from "../../Pagination/Pagination";
import { instagramActions, fetchExcel, fetchInstUsers, fetchInstUsersCount, fetchSubcultures, fetchTags } from '../../../redux/instagram/actions';
import InstUsersItem from './InstUsersItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux/reducers';
import _ from 'lodash';

const { clearFilters, setInstUsersLoading, setInstUsersPage, setInstUsersPerPage, setKeyword, setSubculture, setTag } = instagramActions

const InstUsers: React.FC = () => {
    const dispatch = useDispatch();
    const { loaded, loading, users, filters, count, excel, subcultures, tags } = useSelector((state: RootStateType) => state.instUsersReducer);
    const { subculture, tag, keyword } = filters;

    const debouncedFetchPage = useCallback(_.debounce(() => {
        dispatch(setInstUsersPage(1));
        dispatch(fetchInstUsers(true));
        dispatch(fetchInstUsersCount());
        dispatch(fetchExcel());
    }, 500), []);

    const handlerApplyFilters = useCallback((): void => {
        dispatch(setTag(tag));
        dispatch(setSubculture(subculture));
        dispatch(setInstUsersPage(1))
        dispatch(fetchInstUsers());
        dispatch(fetchInstUsersCount());
        dispatch(fetchExcel());
    }, [tag, subculture])

    const handlerClearFilters = useCallback((): void => {
        dispatch(clearFilters());
    }, [])

    const onChangePage = useCallback((page: number): void => {
        dispatch(setInstUsersPage(page));
        dispatch(fetchInstUsers());
    }, [])

    const handlerChangeUsersPerPage = useCallback((usersPerPage: number): void => {
        dispatch(setInstUsersPerPage(usersPerPage));
        dispatch(setInstUsersPage(1));
        dispatch(fetchInstUsers());
        dispatch(fetchInstUsersCount());
    }, [])

    const handlerChangeKeyword = useCallback((keyword: string): void => {
        dispatch(setKeyword(keyword));
        debouncedFetchPage()
    }, [])

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(fetchInstUsers());
            dispatch(fetchInstUsersCount());
            dispatch(setInstUsersLoading(true));
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
        }
    });

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel tabbed-panel mt-5">
                    <InstNavBar />
                    <InstFilters tag={tag} keyword={keyword} setSubculture={(subculture) => dispatch(setSubculture(subculture))} setTag={(tag) => dispatch(setTag(tag))}
                        subculture={subculture} setKeyword={handlerChangeKeyword} applyFilters={handlerApplyFilters} clearFilters={handlerClearFilters} excel={excel} subcultures={subcultures} tags={tags} />
                    <div className="control_panel__body">
                        <div className="tabbed-panel__content" data-name="users">
                            <table className="tbl vk-community-tbl">
                                <tr className="tbl__row_header">
                                    <th className="tbl__header_cell" colSpan={2}>Название аккаунта</th>
                                    <th className="tbl__header_cell" hide-order="1">Статус аккаунта</th>
                                    <th className="tbl__header_cell" hide-order="2">Тег</th>
                                    <th className="tbl__header_cell" hide-order="3">Субкультура</th>
                                    <th className="tbl__header_cell showmore-column showmore-mob"></th>
                                </tr>
                                {users.map((item, index) => <InstUsersItem key={index} data={item} />)}
                            </table>
                        </div>
                    </div>
                </div>

                <Pagination onChangePage={onChangePage} handlerChangeItemsPerPage={handlerChangeUsersPerPage} activePage={filters.page} itemsPerPage={filters.usersPerPage} count={count} />
            </div>
        </div>
    )
}

export default InstUsers
