import { UserFormType } from './../../types/users.d';
import { UserType } from './../../types/users';
import {
    EditorActionTypes
} from "./actions";

const defaultState = {
    users: {
        users: [] as Array<UserType>,
        loaded: false,
        loading: false,
        count: 0,
        filters: {
            usersPerPage: 8,
            activePage: 1,
            status: "-",
            role: "-",
            keyword: ""
        }
    },
    user: {
        userInfo: {} as UserType & UserFormType,
        loaded: false
    }
}

type EditorStateType = typeof defaultState

export const editorReducer = (state = defaultState, action: EditorActionTypes): EditorStateType => {
    switch (action.type) {
        case "EDITOR_USERS_SET_USERS":
            return {
                ...state,
                users: {
                    ...state.users,
                    users: action.users
                }
            }
        case "EDITOR_USERS_SET_LOADED":
            return {
                ...state,
                users: {
                    ...state.users,
                    loaded: action.loaded
                }
            }
        case "EDITOR_USERS_SET_LOADING":
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: action.loading
                }
            }
        case "EDITOR_USER_SET_LOADED":
            return {
                ...state,
                user: {
                    ...state.user,
                    loaded: action.loaded
                }
            }
        case "EDITOR_USER_SET_INFO":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: action.data
                }
            }
        case "EDITOR_USER_SET_FIRST_NAME":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        firstName: action.firstName
                    }
                }
            }
        case "EDITOR_USER_SET_LAST_NAME":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        lastName: action.lastName
                    }
                }
            }
        case "EDITOR_USER_SET_PATRONYMIC":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        patronymic: action.patronymic
                    }
                }
            }
        case "EDITOR_USER_SET_DISTRICT":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        district: action.district
                    }
                }
            }
        case "EDITOR_USER_SET_CITY":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        city: action.city
                    }
                }
            }
        case "EDITOR_USER_SET_WORKPLACE":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        workplace: action.workplace
                    }
                }
            }
        case "EDITOR_USER_SET_POSITION":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        position: action.position
                    }
                }
            }
        case "EDITOR_USER_SET_EMAIL":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        email: action.email
                    }
                }
            }
        case "EDITOR_USER_SET_PASSWORD":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        password: action.password
                    }
                }
            }
        case "EDITOR_USER_SET_REPEAT_PASSWORD":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        repeatPassword: action.repeatPassword
                    }
                }
            }
        case "EDITOR_USER_SET_CHECKED":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        checkedInput: action.checked
                    }
                }
            }
        case "EDITOR_USER_SET_IMAGE_FILE":
            return {
                ...state,
                user: {
                    ...state.user,
                    userInfo: {
                        ...state.user.userInfo,
                        imageFile: action.file
                    }
                }
            }
        case "EDITOR_USERS_SET_ROLE": {
            const users = [...state.users.users];
            const changedUser = { ...users[action.index] };
            changedUser.roles = action.role;
            users[action.index] = changedUser;
            return {
                ...state,
                users: {
                    ...state.users,
                    users
                }
            }
        }
        case "EDITOR_USERS_SET_CHECKED": {
            const users = [...state.users.users];
            const changedUser = { ...users[action.index] };
            changedUser.checked = action.checked;
            users[action.index] = changedUser;
            return {
                ...state,
                users: {
                    ...state.users,
                    users
                }
            }
        }
        case "EDITOR_USERS_SET_USERS_COUNT": {
            return {
                ...state,
                users: {
                    ...state.users,
                    count: action.count
                }
            }
        }
        case "EDITOR_USERS_SET_USERS_PER_PAGE": {
            return {
                ...state,
                users: {
                    ...state.users,
                    filters: {
                        ...state.users.filters,
                        usersPerPage: action.usersPerPage
                    }
                }
            }
        }
        case "EDITOR_USERS_SET_ACTIVE_PAGE": {
            return {
                ...state,
                users: {
                    ...state.users,
                    filters: {
                        ...state.users.filters,
                        activePage: action.activePage
                    }
                }
            }
        }
        case "EDITOR_USERS_SET_ROLE_FILTER": {
            return {
                ...state,
                users: {
                    ...state.users,
                    filters: {
                        ...state.users.filters,
                        role: action.role
                    }
                }
            }
        }
        case "EDITOR_USERS_SET_STATUS_FILTER": {
            return {
                ...state,
                users: {
                    ...state.users,
                    filters: {
                        ...state.users.filters,
                        status: action.status
                    }
                }
            }
        }
        case "EDITOR_USERS_SET_KEY_WORD_FILTER": {
            return {
                ...state,
                users: {
                    ...state.users,
                    filters: {
                        ...state.users.filters,
                        keyword: action.keyword
                    }
                }
            }
        }
        default:
            return state;
    }
}