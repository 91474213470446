import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { TelegramPostType } from './../../../types/telegram';
import axios from "axios";
import { InferValueTypes } from '../../../types/common';

export const telegramPostActions = {
    setTelegramPostsLoaded: (loaded: boolean) => ({
        type: "TELEGRAM_POSTS_SET_LOADED" as const,
        loaded
    }),
    setTelegramPosts: (posts: Array<TelegramPostType>) => ({
        type: "TELEGRAM_POSTS_SET_POSTS" as const,
        posts
    }),
    setTelegramPostsLoading: (loading: boolean) => ({
        type: "TELEGRAM_POSTS_SET_LOADING" as const,
        loading
    }),
    setTelegramPostsCount: (count: number) => ({
        type: "TELEGRAM_POSTS_SET_POSTS_COUNT" as const,
        count
    }),
    setTelegramPostsPage: (page: number) => ({
        type: "TELEGRAM_POSTS_SET_PAGE" as const,
        page
    }),
    setTelegramPostsPerPage: (postsPerPage: number) => ({
        type: "TELEGRAM_POSTS_SET_POSTS_PER_PAGE" as const,
        postsPerPage
    }),
    setSubculture: (subculture: string) => ({
        type: "TELEGRAM_POSTS_SET_SUBCULTURE" as const,
        subculture
    }),
    setTag: (tag: string) => ({
        type: "TELEGRAM_POSTS_SET_TAG" as const,
        tag
    }),
    setKeyword: (keyword: string) => ({
        type: "TELEGRAM_POSTS_SET_KEYWORD" as const,
        keyword
    }),
    clearFilters: () => ({
        type: "TELEGRAM_POSTS_CLEAR_FILTERS" as const
    }),
    setExcel: (blob: File) => ({
        type: "TELEGRAM_POSTS_SET_EXCEL" as const,
        blob
    }),
    setSubcultures: (subcultures: Array<string>) => ({
        type: "TELEGRAM_POSTS_SET_SUBCULTURES" as const,
        subcultures
    }),
    setTags: (tags: Array<string>) => ({
        type: "TELEGRAM_POSTS_SET_TAGS" as const,
        tags
    })
}

export const fetchTelegramPosts = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().telegramPostsReducer.filters;

    axios.post<Array<TelegramPostType>>('/api/telegram/posts/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(telegramPostActions.setTelegramPosts(data))
        dispatch(telegramPostActions.setTelegramPostsLoaded(true))
        dispatch(telegramPostActions.setTelegramPostsLoading(false))
    })
};

export const fetchTelegramPostsCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().telegramPostsReducer.filters;

    axios.post<number>("/api/telegram/posts/get-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(telegramPostActions.setTelegramPostsCount(data))
    })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().telegramPostsReducer.filters;
    axios.request<File>({url: "/api/telegram/posts/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob"})
        .then(response => {
            
            dispatch(telegramPostActions.setExcel(response.data))
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(telegramPostActions.setSubcultures(data))
    })
};

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/telegram/posts/get-tags").then(({ data }) => {
        dispatch(telegramPostActions.setTags(data))
    })
}

export type TelegramPostActionTypes = ReturnType<InferValueTypes<typeof telegramPostActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, TelegramPostActionTypes>