import { tiktokInfographicsReducer } from './tiktok/infographics/reducer';
import { telegramInfographicsReducer } from './telegram/infographics/reducer';
import { instagramInfographicsReducer } from './instagram/infographics/reducer';
import { vkInfographicsReducer } from './vk/infographics/reducer';
import { combineReducers } from "redux";
import { vkGroupsReducer } from "./vk/groups/reducer";
import { vkAdminsReducer } from "./vk/admins/reducer";
import { vkUsersReducer } from "./vk/members/reducer";
import { instUsersReducer } from "./instagram/reducer";
import { accountReducer } from "./app/account/reducer";
import { loginReducer } from "./app/login/reducer";
import { registrationReducer } from "./app/registration/reducer";
import { imagesReducer } from "./images/reducer";
import { editorReducer } from "./editor/reducer";
import { recoveryReducer } from "./app/recovery/reducer";
import { telegramChannelsReducer } from "./telegram/channels/reducer";
import { telegramPostsReducer } from "./telegram/posts/reducer";
import { tikTokPostsReducer } from "./tiktok/posts/reducer";
import { tikTokUsersReducer } from "./tiktok/users/reducer";
import { tagsReducer } from "./tags/reducer";
import { subculturesReducer } from "./subcultures/reducer";
import { vkSubscribersReducer } from "./vk/subscribers/reducer";
import { vkInterestsReducer } from "./vk/interests/reducer";
import { sidepanelReducer } from "./sidepanel/reducer";

export const rootReducer = combineReducers({
    vkGroupsReducer,
    vkAdminsReducer,
    vkUsersReducer,
    instUsersReducer,
    accountReducer,
    loginReducer,
    registrationReducer,
    imagesReducer,
    editorReducer,
    recoveryReducer,
    telegramChannelsReducer,
    telegramPostsReducer,
    tikTokPostsReducer,
    tikTokUsersReducer,
    tagsReducer,
    subculturesReducer,
    vkSubscribersReducer,
    vkInterestsReducer,
    sidepanelReducer,
    vkInfographicsReducer,
    instagramInfographicsReducer,
    telegramInfographicsReducer,
    tiktokInfographicsReducer
})

export type RootStateType = ReturnType<typeof rootReducer>