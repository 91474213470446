import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { imageActions, fetchExcel, fetchImages, fetchImagesCount, fetchSubcultures, fetchTags } from '../../../redux/images/actions';
import Pagination from '../../Pagination/Pagination'
import ImagesFilters from '../ImagesFilters'
import ImagesItem from './ImagesItem';
import { RootStateType } from '../../../redux/reducers';
import { TimerType } from '../../../types/common';
import _ from 'lodash';

const { clearFilters, setImagesLoading, setImagesPage, setImagesPerPage, setKeyword, setSubculture, setTag } = imageActions

function Images() {
    const dispatch = useDispatch();
    const { loaded, loading, images, filters, count, excel, tags, subcultures } = useSelector((state: RootStateType) => state.imagesReducer);

    const { subculture: subcultureInit, tag: tagInit, name: keyword } = filters;

    const [subculture, setSubculture] = useState(subcultureInit);
    const [tag, setTagState] = useState(tagInit);

    useEffect(() => {
        if (!(loaded || loading)) {
            dispatch(setImagesLoading(true));
            dispatch(fetchImages());
            dispatch(fetchImagesCount());
            dispatch(fetchExcel());
            dispatch(fetchSubcultures());
            dispatch(fetchTags());
        }
    });

    const handlerApplyFilters = () => {
        dispatch(setTag(tag));
        dispatch(setSubculture(subculture));
        dispatch(setImagesPage(1))
        dispatch(fetchImages());
        dispatch(fetchImagesCount());
        dispatch(fetchExcel());
    }

    const handlerChangeKeyword = (keyword: string): void => {
        dispatch(setKeyword(keyword));
        _.debounce(() => {
            dispatch(setImagesPage(1));
            dispatch(fetchImages());
            dispatch(fetchImagesCount());
            dispatch(fetchExcel());
        }, 500)()
    }

    const handlerClearFilters = (): void => {
        dispatch(clearFilters());
        setSubculture("-");
        setTagState("-");
    }

    const onChangePage = (page: number): void => {
        dispatch(setImagesPage(page));
        dispatch(fetchImages());
    }

    const handlerChangeImagesPerPage = (imagesPerPage: number) => {
        dispatch(setImagesPerPage(imagesPerPage));
        dispatch(setImagesPage(1));
        dispatch(fetchImages());
        dispatch(fetchImagesCount());
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="control-panel mt-5">
                    <ImagesFilters keyword={keyword}
                        setKeyword={handlerChangeKeyword} tag={tag}
                        setTag={(tag) => dispatch(setTagState(tag))} subculture={subculture}
                        setSubculture={(subculture) => dispatch(setSubculture(subculture))} applyFilters={handlerApplyFilters}
                        clearFilters={handlerClearFilters} excel={excel} subcultures={subcultures} tags={tags} />
                    <div className="control_panel__body">
                        <table className="tbl youtube-video-tbl">
                            <tr className="tbl__row_header">
                                <th className="tbl__header_cell">Превью</th>
                                <th className="tbl__header_cell">Описание</th>
                                <th className="tbl__header_cell" hide-order="1">Тег</th>
                                <th className="tbl__header_cell" hide-order="1">Субкультура</th>
                                <th className="tbl__header_cell showmore-column showmore-mob"></th>
                            </tr>
                            {images.map((item, index) => <ImagesItem key={index} data={item} />)}
                        </table>
                    </div>
                </div>
                <Pagination handlerChangeItemsPerPage={handlerChangeImagesPerPage} onChangePage={onChangePage} activePage={filters.page} count={count} itemsPerPage={filters.imagesPerPage} />
            </div>
        </div>

    )
}

export default Images
