import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../redux/app/login/actions';
import { recoveryActions } from '../../redux/app/recovery/actions';
import $ from "jquery";
import { RootStateType } from '../../redux/reducers';
import { sidepanelActions } from '../../redux/sidepanel/actions';

const { setIsSidepanelActive, setSidepanelCurrentTab } = sidepanelActions

const { setEmail, setStatus } = recoveryActions

const Recovery: React.FC = () => {
    const dispatch = useDispatch();
    const { status, email } = useSelector((state: RootStateType) => state.recoveryReducer);

    return (
        <div className="sidepanel__items_item">
            <h3 className="sidepanel_header">Восстановление пароля</h3>
            {status ?
                <div className="message message_success message_fwidth">
                    Письмо было отправлено на {email}.<br />
                    Пожалуйста, проверьте вашу почту и выполните действия, указанные в инструкции.
                </div>
                :
                <form action="" className="form form_forgotpass">
                    <p className="form__text mb-4">Укажите свой email, под которым вы зарегистрированы и мы отправим инструкцию по восстановлению</p>
                    <div className="form__block mb-5">
                        <div className="form__block_label">E-mail</div>
                        <input value={email} onChange={e => dispatch(setEmail(e.target.value))} type="email" className="form__block_input" placeholder="Введите адрес электронной почты" />
                    </div>
                    <div className="form__block mb-3">
                        <button onClick={() => {
                            if (!validEmail(email)) {
                                dispatch(setStatus(true));
                                dispatch(changePassword(email));
                            }
                        }} className="button button_darkgreen">Сброс пароля</button>
                    </div>
                    <div className="form__block">
                        <a href="javascript:;" className="link link_back sidepanel-toggle" data-target="login-form" onClick={e => {
                            dispatch(setIsSidepanelActive(false))
                            setTimeout(() => dispatch(setSidepanelCurrentTab(null)), 500)
                        }}>Вернуться к авторизации</a>
                    </div>
                </form>
            }
        </div>
    )
}

const validEmail = (email: string): boolean => {
    if (!email.length) {
        return true;
    }

    var regEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    var validEmail = regEx.test(email);

    if (validEmail) {
        return false;
    }

    return true;
}

export default Recovery;