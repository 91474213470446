import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import {
    Link,
    NavLink,
} from "react-router-dom";
import $ from "jquery";
import { useSelector } from "react-redux";
import { RootStateType } from "../../redux/reducers";
import { useToggleSidepanel } from "../../hooks/useToggleSidepanel";


const AppNavBar: React.FC = () => {
    const toggleSidepanel = useToggleSidepanel()
    const authorized = useSelector((state: RootStateType) => state.accountReducer.authorized)

    return (

        <header className="header side-padding-main">
            <div className="header__left">
                {authorized ?
                    <a href="javascript:;" className="header__menu-btn sidepanel-toggle d-block" onClick={() => toggleSidepanel(true, "menu")}></a>
                    :
                    null
                }
                <Link to="/" className="header__logo header_logo_main">
                    Destruct analysis
                </Link>
            </div>
            {authorized ?
                null
                :
                <div className="d-none d-md-block header__right">
                    <a href="#" className="button button_darkgreen sidepanel-toggle" onClick={() => toggleSidepanel(true, "login")}>Войти</a>
                    <Link to="/registration" className="button button_transparent">Регистрация</Link>
                </div>
            }
        </header>

    )
}

export default AppNavBar;