import { RootStateType } from './../../reducers';
import { ThunkAction } from 'redux-thunk';
import { TiktokPostType } from './../../../types/tiktok';
import axios from "axios";
import { InferValueTypes } from '../../../types/common';

export const tiktokPostActions = {

    setTikTokPostsLoaded: (loaded: boolean) => ({
        type: "TIKTOK_POSTS_SET_LOADED" as const,
        loaded
    }),

    setTikTokPosts: (posts: Array<TiktokPostType>) => ({
        type: "TIKTOK_POSTS_SET_POSTS" as const,
        posts
    }),

    setTikTokPostsLoading: (loading: boolean) => ({
        type: "TIKTOK_POSTS_SET_LOADING" as const,
        loading
    }),

    setTikTokPostsCount: (count: number) => ({
        type: "TIKTOK_POSTS_SET_POSTS_COUNT" as const,
        count
    }),

    setTikTokPostsPage: (page: number) => ({
        type: "TIKTOK_POSTS_SET_PAGE" as const,
        page
    }),

    setTikTokPostsPerPage: (postsPerPage: number) => ({
        type: "TIKTOK_POSTS_SET_POSTS_PER_PAGE" as const,
        postsPerPage
    }),

    setSubculture: (subculture: string) => ({
        type: "TIKTOK_POSTS_SET_SUBCULTURE" as const,
        subculture
    }),

    setTag: (tag: string) => ({
        type: "TIKTOK_POSTS_SET_TAG" as const,
        tag
    }),

    setKeyword: (keyword: string) => ({
        type: "TIKTOK_POSTS_SET_KEYWORD" as const,
        keyword
    }),

    clearFilters: () => ({
        type: "TIKTOK_POSTS_CLEAR_FILTERS" as const
    }),

    setExcel: (blob: File) => ({
        type: "TIKTOK_POSTS_SET_EXCEL" as const,
        blob
    }),

    setSubcultures: (subcultures: Array<string>) => ({
        type: "TIKTOK_POSTS_SET_SUBCULTURES" as const,
        subcultures
    }),

    setTags: (tags: Array<string>) => ({
        type: "TIKTOK_POSTS_SET_TAGS" as const,
        tags
    }),
}

export const fetchTikTokPosts = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tikTokPostsReducer.filters;

    axios.post<Array<TiktokPostType>>('/api/tiktok/posts/get-page', JSON.stringify(filters)).then(({ data }) => {
        dispatch(tiktokPostActions.setTikTokPosts(data));
        dispatch(tiktokPostActions.setTikTokPostsLoaded(true));
        dispatch(tiktokPostActions.setTikTokPostsLoading(false));
    })
};

export const fetchTikTokPostsCount = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tikTokPostsReducer.filters;

    axios.post<number>("/api/tiktok/posts/get-count", JSON.stringify(filters)).then(({ data }) => {
        dispatch(tiktokPostActions.setTikTokPostsCount(data));
    })
}

export const fetchExcel = (): ThunkActionType => (dispatch, getState) => {
    const filters = getState().tikTokPostsReducer.filters;
    axios.request<File>({url: "/api/tiktok/posts/create-excel", method: "post", data: JSON.stringify(filters), responseType: "blob"})
        .then(response => {
            dispatch(tiktokPostActions.setExcel(response.data));
        })
}

export const fetchSubcultures = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/subculture-tag/get-all-subcultures").then(({ data }) => {
        dispatch(tiktokPostActions.setSubcultures(data));
    })
};

export const fetchTags = (): ThunkActionType => (dispatch, getState) => {
    axios.get<Array<string>>("/api/tiktok/posts/get-tags").then(({ data }) => {
        dispatch(tiktokPostActions.setTags(data));
    })
}

export type TiktokPostActionTypes = ReturnType<InferValueTypes<typeof tiktokPostActions>>
type ThunkActionType = ThunkAction<void, RootStateType, unknown, TiktokPostActionTypes>